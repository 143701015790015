import PropTypes from 'prop-types';
import React from 'react';
import projectStyles from 'components/Projects/style.module.css';
import WrapperBlock from '../WrapperBlock';

const ProjectFormInfo = ({ title, children, isForm, rightComponent }) => {
  return (
    <div>
      <WrapperBlock title={title} isForm={isForm} rightComponent={rightComponent}>
        {children}
      </WrapperBlock>
    </div>
  );
};

export default ProjectFormInfo;

ProjectFormInfo.propTypes = {
  children: PropTypes.node,
  isForm: PropTypes.bool,
  rightComponent: PropTypes.element,
  title: PropTypes.string,
};

ProjectFormInfo.defaultProps = {
  children: null,
  isForm: false,
  rightComponent: null,
  title: '',
};
