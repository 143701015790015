import React from 'react';
import Text from 'components/shared/Text';
import styles from '../../../style.module.css';

const PaymentsInformationSection = () => {
  return (
    <div className={styles.rowContainerMargin}>
      <Text color="#000000" size="s14">
        If you are in possession of any insurance checks please contact us for assistance
        in processing, rotted decking and/or planks will be automatically replaced, as it
        is required by Building Code.
      </Text>
      <br />
      <Text color="#000000" size="s13" weight="wb">
        Shingle replacements;
      </Text>{' '}
      <Text color="#000000" size="s14">
        RoofClaim will replace one (1) sheet of decking OR three (3) pieces of
        planking at no charge. Any additional decking will be replaced, and the homeowner
        shall be charged $125.00 per sheet; additional planking shall be charged at the
        rate of $50.00 per piece.
      </Text>
      <br />
      <Text color="#000000" size="s13" weight="wb">
        Tile & Metal replacements;
      </Text>{' '}
      <Text color="#000000" size="s14">
        RoofClaim will replace five (5) sheet of decking OR fifteen (15) pieces of
        planking at no charge. Any additional decking will be replaced, and the homeowner
        shall be charged $125.00 per sheet; additional planking shall be charged at the
        rate of $50.00 per piece.
      </Text>
      <br />
      <br />
      <Text color="#000000" size="s13" weight="wb" tag="p">
        The amount is due on the day of installation.
      </Text>
      <Text color="#000000" size="s14">
        Field reps will pick up insurance checks that have been fully endorsed. If the
        mortgage company has been added as a payee then the rep will FedEx, in a pre-paid
        envelope, all checks that contain mortgage companies that are not local.
      </Text>
    </div>
  );
};

PaymentsInformationSection.propTypes = {};

PaymentsInformationSection.defaultProps = {};

export default PaymentsInformationSection;
