import React from 'react';
import PropTypes from 'prop-types';
import Text from 'components/shared/Text';
import Note from 'components/shared/NoteText';
import { formatDate } from 'utils/dateFormatters';
import Attachment from 'containers/DownloadAttachmentContainer';
import styles from './style.module.css';

function DroneReportStatus({
  roofInspectionDate,
  handleOpenDocuments,
  inspectionDocuments,
}) {
  if (inspectionDocuments?.length > 0)
    return (
      <div>
        <div className={styles.wrapper}>
          <div className={styles.textWrapper}>
            <Text color="#686868" size="s14" tag="p">
              Roof Inspection Date
            </Text>{' '}
            <Text color="#000" size="s13" weight="wb">
              {roofInspectionDate
                ? formatDate(roofInspectionDate)
                : 'Date is not yet set'}
            </Text>
          </div>
          <div className={styles.droneReportWrapper}>
            <Attachment {...inspectionDocuments[0]} />
          </div>
        </div>

        <Note isNoteDescription smallSize>
          The inspection documents are available in your{' '}
          <span onClick={handleOpenDocuments} className={styles.textLink}>
            Documents
          </span>{' '}
          section.
        </Note>
      </div>
    );

  return (
    <div>
      <div className={styles.wrapper}>
        <Text color="#000" size="s14" tag="p">
          Roof inspection date is scheduled for:{' '}
          <Text color="#000" size="s13" weight="wb">
            {roofInspectionDate ? formatDate(roofInspectionDate) : 'Date is not yet set'}
          </Text>
        </Text>
      </div>

      <Note isNoteDescription smallSize>
        Your presence is not required for drone inspection. If your roof is tarped, it will need to be removed for a thorough damage assessment.
      </Note>
    </div>
  );
}

DroneReportStatus.propTypes = {
  roofInspectionDate: PropTypes.string,
  handleOpenDocuments: PropTypes.func.isRequired,
  inspectionDocuments: PropTypes.array,
};

DroneReportStatus.defaultProps = {
  roofInspectionDate: '',
  inspectionDocuments: [],
};

export default DroneReportStatus;
