import React from 'react';
import Text from 'components/shared/Text';
import styles from '../../style.module.css';

const WelcomeMessage = () => {
  return (
    <div className={[styles.projectFormContent, styles.bannerWrapper].join(' ')}>
      <Text color="#000" weight="wb" size="s19" tag="p" lineHeight="h26">
        Welcome!
      </Text>
    </div>
  );
};

export default WelcomeMessage;
