import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import Field from 'components/shared/Field';
import Checkbox from 'components/shared/CheckBox';
import Button from 'components/shared/Button';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import Link from 'components/shared/Link';
import {
  FormContent,
  FormColumn as Col,
  FormRow as Row,
  FormFooter,
} from 'components/shared/Form';
import { states } from 'enums';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import InputMask from 'react-input-mask';
import {
  GET_ADDRESS_DETAILS,
  GET_ADDRESS_PREDICTION,
  GET_ADDRESS_BY_COORDS,
} from 'graphql/queries/googleMaps';
import Text from 'components/shared/Text';
import MobileContext from 'context/MobileContext';
import { NavLink } from 'react-router-dom';
import styles from '../style.module.css';
import { authErrors } from '../../../enums/errors/authErrors';
import useGeoposition from '../../../services/hooks/useGeoposition';
import { getLatLngStringFromCoords } from '../../../utils/getLatLngStringFromCoords';

const topValueErrors = {
  firstName: {
    top: '230',
    weight: 1,
  },
  lastName: {
    top: '310',
    weight: 1,
  },
  phoneNumber: {
    top: '390',
    weight: 2,
  },
  mobilePhone: {
    top: '470',
    weight: 3,
  },
  email: {
    top: '560',
    weight: 4,
  },
  propertyAddress: {
    top: '640',
    weight: 5,
  },
  addressPlaceID: {
    top: '640',
    weight: 5,
  },
  city: {
    top: '720',
    weight: 6,
  },
  zipCode: {
    top: '800',
    weight: 7,
  },
  state: {
    top: '880',
    weight: 8,
  },
  agreesWithTerms: {
    top: '960',
    weight: 8,
  },
};

const FirstStepSignUp = ({
  isLoading,
  handleSubmit = () => { },
  values,
  errors,
  touched,
  handleChange,
  setFieldValue,
  userExistError,
  addressExistError,
  handleChangeAddress,
  handleChangeEmail,
  validateForm,
}) => {
  const { coords } = useGeoposition();

  const { data: addressByCoords, loading: addressAutoFillLoading } = useQuery(
    GET_ADDRESS_BY_COORDS,
    {
      variables: { latlng: getLatLngStringFromCoords(coords) },
      skip: !coords || !getLatLngStringFromCoords(coords),
    }
  );

  const [
    _getPropertyAddressPrediction,
    { data: propertyAddressPredictionData },
  ] = useLazyQuery(GET_ADDRESS_PREDICTION);

  const getPropertyAddressPrediction = useCallback(
    debounce(
      ({ variables: { input: value } }) =>
        _getPropertyAddressPrediction({ variables: { input: value } }),
      500
    ),
    []
  );

  const setAddressFormValues = (placeID, name) => {
    setFieldValue('addressPlaceID', placeID ?? null);
    setFieldValue('propertyAddress', name ?? null);
  };

  function fillFormFieldsByKeys(data) {
    if (data)
      Object.keys(data).map((el) => {
        if (data[el] && el !== '__typename') setFieldValue(el, data[el]);
        return el;
      });
  }

  const [getAddressDetails, { data: addressDetailsData }] = useLazyQuery(
    GET_ADDRESS_DETAILS,
    {
      onCompleted: () => {
        const data = addressDetailsData?.getAddressDetails;
        fillFormFieldsByKeys(data);
      },
    }
  );

  useEffect(() => {
    const data = addressByCoords?.getAddressByCoords;
    if (coords && data) {
      const { city, state, zipCode, formattedAddress, placeID, street } = data;
      fillFormFieldsByKeys({ city, state, zipCode: String(zipCode), street });
      setAddressFormValues(placeID, formattedAddress);
    }
  }, [coords, addressByCoords?.getAddressByCoords]);

  useEffect(() => {
    validateForm();
  }, [values.addressPlaceID]);

  useEffect(() => {
    if (!isEmpty(errors) && !isEmpty(touched)) {
      const errorsTop = sortBy(
        Object.keys(errors).map((key) => topValueErrors[key]),
        'weight'
      );
      window.scrollTo({ top: errorsTop[0].top, behavior: 'smooth' });
    }
  }, [errors, touched]);

  const isMobile = useContext(MobileContext);

  async function getOnChange(e) {
    const name = e.target.value;
    const placeID = propertyAddressPredictionData.getAddressPrediction?.predictions.find(
      (prediction) => prediction.description === name
    )?.place_id;
    setAddressFormValues(placeID, name);
    if (placeID) await getAddressDetails({ variables: { placeID } });
    validateForm();
    handleChangeAddress();
  }

  const beforeMaskedValueChange = (newState) => {
    // eslint-disable-next-line prefer-const
    let { value, selection } = newState;
    if (value.endsWith('-')) value = value.slice(0, -1);
    return {
      value,
      selection,
    };
  };

  function getOnInputChange(value, action) {
    if (action.action !== 'input-blur' && action.action !== 'menu-close') {
      if (values.propertyAddress)
        getPropertyAddressPrediction({ variables: { input: value } });
      setAddressFormValues(null, value);
    }
    handleChangeAddress();
  }

  return (
    <form>
      {isMobile && (
        <div className={styles.authFormHeaderMobile}>
          <Text color="#00000D" weight="wb" size="s21">
            Sign Up
          </Text>
          <br />
          <br />
          <Text color="#00000D" weight="wm" size="s16">
            Already have an account?{' '}
            <Link href="/sign-in" bold size="medium" color="secondary">
              Sign In
            </Link>
          </Text>
        </div>
      )}
      <FormContent>
        <Row>
          <Col>
            <Field
              label="First Name"
              type="text"
              inputMode="text"
              autoComplete="off"
              name="firstName"
              value={values.firstName}
              isError={touched.firstName && Boolean(errors.firstName)}
              onChange={handleChange}
            />
          </Col>
          <Col>
            <Field
              label="Last Name"
              type="text"
              inputMode="text"
              autoComplete="off"
              name="lastName"
              value={values.lastName}
              isError={touched.lastName && Boolean(errors.lastName)}
              onChange={handleChange}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <InputMask
              mask="+1 (999) 999-9999"
              value={values.phoneNumber}
              onChange={(e) => {
                setFieldValue(
                  'phoneNumber',
                  e.target.value.replace('+', '').replace(/\D/g, '')
                );
              }}
            >
              {() => (
                <Field
                  label="Phone Number"
                  type="tel"
                  inputMode="tel"
                  autoComplete="off"
                  name="phoneNumber"
                  isError={touched.phoneNumber && Boolean(errors.phoneNumber)}
                />
              )}
            </InputMask>
          </Col>
          <Col>
            <InputMask
              mask="+1 (999) 999-9999"
              value={values.mobilePhone}
              onChange={(e) => {
                setFieldValue(
                  'mobilePhone',
                  e.target.value.replace('+', '').replace(/\D/g, '')
                );
              }}
            >
              {() => (
                <Field
                  label="Mobile Phone"
                  type="tel"
                  inputMode="tel"
                  autoComplete="off"
                  name="mobilePhone"
                  isError={touched.mobilePhone && Boolean(errors.mobilePhone)}
                />
              )}
            </InputMask>
          </Col>
        </Row>

        <Col>
          <Field
            label="Email Address"
            type="email"
            inputMode="email"
            autoComplete="off"
            name="email"
            value={values.email}
            isError={
              userExistError ||
              (values.email && Boolean(errors.email)) ||
              (touched.email && Boolean(errors.email))
            }
            onChange={handleChangeEmail}
          />
          {userExistError ? (
            <Text size="s12" lineHeight="h14" color="#CF4B4B">
              This email is already in use.
              <br />
              Already a member?
              <NavLink to="/sign-in">
                <Text size="s12" lineHeight="h14" weight="wb" color="#CF4B4B">
                  {' '}
                  Login
                </Text>
              </NavLink>
            </Text>
          ) : null}
        </Col>

        <Col>
          <Field
            autoComplete="off"
            label="Property Address"
            name="propertyAddress"
            type="select"
            isDisabled={isLoading || addressAutoFillLoading}
            options={
              propertyAddressPredictionData?.getAddressPrediction?.predictions.map(
                (el) => ({
                  label: el.description,
                  value: el.description,
                })
              ) || []
            }
            onChange={getOnChange}
            onInputChange={getOnInputChange}
            icon={() => <div />}
            value={values.propertyAddress ?? ''}
            inputValue={values.propertyAddress ?? ''}
            isError={
              addressExistError ||
              (touched.propertyAddress && Boolean(errors.propertyAddress)) ||
              (touched.addressPlaceID && Boolean(errors.addressPlaceID))
            }
          />
          {addressExistError ? (
            <Text size="s12" lineHeight="h14" color="#CF4B4B">
              {authErrors.notUniqueAddress}
            </Text>
          ) : null}
          {values.propertyAddress && Boolean(errors.addressPlaceID) ? (
            <Text size="s12" lineHeight="h14" color="#CF4B4B">
              Invalid address
            </Text>
          ) : null}
        </Col>

        {/* Mobile Columns */}
        {isMobile ? (
          <>
            <Row type="secondary">
              <Col>
                <Field
                  autoComplete="off"
                  label="City"
                  inputMode="search"
                  name="city"
                  isDisabled={isLoading || addressAutoFillLoading}
                  value={values.city}
                  isError={touched.city && Boolean(errors.city)}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <InputMask
                  mask={values?.zipCode?.length >= 5 ? '99999-9999' : '99999'}
                  maskChar={null}
                  beforeMaskedValueChange={beforeMaskedValueChange}
                  onChange={(e) => {
                    setFieldValue('zipCode', e.target.value.replace(/\D/g, ''));
                  }}
                  value={values.zipCode}
                >
                  {() => (
                    <Field
                      autoComplete="off"
                      label="Zip Code"
                      type="text"
                      isDisabled={isLoading || addressAutoFillLoading}
                      inputMode="numeric"
                      name="zipCode"
                      isError={touched.zipCode && Boolean(errors.zipCode)}
                    />
                  )}
                </InputMask>
              </Col>
              <Col>
                <Field
                  autoComplete="off"
                  label="State"
                  type="select"
                  inputMode="search"
                  name="state"
                  isDisabled={isLoading || addressAutoFillLoading}
                  value={values.state}
                  isError={touched.state && Boolean(errors.state)}
                  onChange={handleChange}
                  options={states.map((el) => ({
                    label: el.name,
                    value: el.abbreviation,
                  }))}
                />
              </Col>
            </Row>
          </>
        ) : (
            // Desktop Columns
            <Row type="secondary">
              <Col>
                <Field
                  autoComplete="off"
                  label="City"
                  type="search"
                  inputMode="search"
                  name="city"
                  isDisabled={isLoading || addressAutoFillLoading}
                  value={values.city}
                  isError={touched.city && Boolean(errors.city)}
                  onChange={handleChange}
                />
              </Col>
              <Col>
                <Field
                  autoComplete="off"
                  label="State"
                  type="select"
                  isDisabled={isLoading || addressAutoFillLoading}
                  inputMode="search"
                  name="state"
                  value={values.state}
                  isError={touched.state && Boolean(errors.state)}
                  onChange={handleChange}
                  options={states.map((el) => ({ label: el.name, value: el.abbreviation }))}
                />
              </Col>
              <Col>
                <InputMask
                  mask={values?.zipCode?.length >= 5 ? '99999-9999' : '99999'}
                  maskChar={null}
                  beforeMaskedValueChange={beforeMaskedValueChange}
                  onChange={(e) => {
                    setFieldValue('zipCode', e.target.value.replace(/\D/g, ''));
                  }}
                  value={values.zipCode}
                >
                  {() => (
                    <Field
                      autoComplete="off"
                      label="Zip Code"
                      isDisabled={isLoading || addressAutoFillLoading}
                      type="text"
                      inputMode="numeric"
                      name="zipCode"
                      isError={touched.zipCode && Boolean(errors.zipCode)}
                    />
                  )}
                </InputMask>
              </Col>
            </Row>
          )}

        <Col>
          <Text size="s13" color="#837F7D" lineHeight="l16">
            Thanks for signing-up with Roofclaim!
            <br /> Based on your sign-up, we would love to keep you posted with product
            news and tips for getting the most of our network. You can opt out at any
            time.
            <br />
            See our{' '}
            <Link
              style={{ color: '#837F7D' }}
              href="https://www.roofclaim.com/privacy-policy/"
              bold
              size="s13"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </Link>{' '}
            for more information.
          </Text>
        </Col>
        <Col>
          <Checkbox
            name="unsubscribed"
            value={values.unsubscribed}
            text={<>I am interested, please send me marketing emails.</>}
            onChange={() => {
              handleChange({
                target: { name: 'unsubscribed', value: !values.unsubscribed },
              });
            }}
          />
        </Col>

        <Col>
          <Checkbox
            name="agreesWithTerms"
            value={values.agreesWithTerms}
            text={
              <>
                I agree with the{' '}
                <Link
                  href="https://www.roofclaim.com/terms-and-conditions/"
                  bold
                  isUnderlined
                  size="s13"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms and Conditions
                </Link>
              </>
            }
            isError={touched.agreesWithTerms && Boolean(errors.agreesWithTerms)}
            required
            onChange={() => {
              handleChange({
                target: { name: 'agreesWithTerms', value: !values.agreesWithTerms },
              });
            }}
          />
        </Col>
      </FormContent>

      <FormFooter>
        {isMobile ? (
          <div className={styles.formFooter}>
            <Button size="large" handleClick={handleSubmit} disabled={isLoading}>
              Create Account
            </Button>
          </div>
        ) : (
            <Button size="large" handleClick={handleSubmit} disabled={isLoading}>
              Create Account
            </Button>
          )}
      </FormFooter>
    </form>
  );
};

FirstStepSignUp.propTypes = {
  addressExistError: PropTypes.bool,
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
  touched: PropTypes.object.isRequired,
  userExistError: PropTypes.bool,
  validateForm: PropTypes.func.isRequired,
  handleChangeAddress: PropTypes.func.isRequired,
  handleChangeEmail: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export default FirstStepSignUp;

FirstStepSignUp.defaultProps = {
  addressExistError: false,
  isLoading: false,
  userExistError: false,
};
