import React from 'react';
import PropTypes from 'prop-types';
import { DroneReportPage } from 'pages';
import { useQuery } from '@apollo/react-hooks';
import { GET_DRONE_REPORT } from 'graphql/cache';
import { Redirect } from 'react-router-dom';
import withLoadProjects from 'services/HOCs/withLoadProjects';
import Spinner from 'components/shared/Spinner';
import LayoutWithSidebar from 'layouts/LayoutWithSidebar';

const DroneReportContainer = (props) => {
  const { match } = props;

  const { loading, data, refetch } = useQuery(GET_DRONE_REPORT, {
    variables: {
      id: match.params.id,
    },
  });

  if (loading)
    return (
      <LayoutWithSidebar>
        <Spinner />
      </LayoutWithSidebar>
    );

  if (!data?.droneReport) return <Redirect to="/my-projects" />;

  return (
    <DroneReportPage droneReport={data?.droneReport} {...props} handleRefetch={refetch} />
  );
};

DroneReportContainer.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withLoadProjects(DroneReportContainer, { isCurrentProject: true });
