import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import FileIcon from 'assets/icons/file.svg';
import Icon from 'components/shared/IconSVG';
import Text from '../Text';
import styles from './style.module.css';

const Link = ({ href, id, children }) => {
  const linkRef = useRef();

  const handleClick = () => {
    linkRef.current.click();
  };

  return (
    <div>
      <a
        id={id}
        href={href}
        ref={linkRef}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.link}
      >
        PDF
      </a>
      <div className={styles.fileWrapper} onClick={handleClick}>
        <div className={styles.linkWrapper}>
          <Icon src={FileIcon} height="24px" width="20px" className={styles.fileIcon} />
        </div>
        <div className={styles.linkContent}>
          <Text color="#000" weight="wb" size="s13" tag="p">
            {children.length > 25 ? `${children.slice(0, 25)}...` : children}
          </Text>
          <span className={styles.greyText}>PDF</span>
        </div>
      </div>
    </div>
  );
};

Link.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};

export default Link;
