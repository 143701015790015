import gql from 'graphql-tag';

export const UPLOAD_FILES = gql`
  mutation UPLOAD_FILES(
    $files: [UploadedFile!]!
    $parentId: String!
    $description: String
  ) {
    uploadFiles(files: $files, parentId: $parentId, description: $description) {
      contentDocumentId
      name
      description
      contentType
    }
  }
`;

export const DELETE_RELATED_FILE = gql`
  mutation DELETE_RELATED_FILE($fileId: String!) {
    deleteRelatedFile(fileId: $fileId) {
      message
    }
  }
`;
