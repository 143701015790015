import { string, object, ref, boolean } from 'yup';
import { emailRegex } from './emailRegex';

export const signUpFirstStepValidationSchema = object().shape(
  {
    firstName: string().required().trim(),
    lastName: string().required().trim(),
    phoneNumber: string()
      .matches(/^\d{11,11}$/, {
        message: 'Please enter valid phone number',
        excludeEmptyString: true,
      })
      .when(['mobilePhone'], {
        is: (mobilePhone) => !mobilePhone,
        then: string().required(),
      }),
    mobilePhone: string()
      .matches(/^\d{11,11}$/, {
        message: 'Please enter valid phone number',
        excludeEmptyString: true,
      })
      .when(['phoneNumber'], {
        is: (phoneNumber) => !phoneNumber,
        then: string().required(),
      }),
    email: string().required().matches(emailRegex).trim(),
    city: string().required(),
    state: string().required(),
    latitude: string(),
    longitude: string(),
    country: string(),
    street: string(),
    propertyAddress: string().required(),
    addressPlaceID: string().required(),
    zipCode: string()
      .required()
      .matches(/(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/, {
        message: 'Please enter valid zipcode',
        excludeEmptyString: true,
      }),
    agreesWithTerms: boolean().oneOf([true], 'Must Accept Terms of Service'),
    unsubscribed: boolean(),
  },
  [['mobilePhone', 'phoneNumber']]
);

export const createPasswordValidationSchema = object().shape({
  password: string().required('Password is required').matches(new RegExp('(?=.{6,})')),
  repeatedPassword: string()
    .required()
    .oneOf([ref('password'), null], 'Passwords must match'),
});

export const emailValidationSchema = object().shape({
  email: string().required().matches(emailRegex).trim(),
});

export const signUpValidationSchema = signUpFirstStepValidationSchema.concat(
  createPasswordValidationSchema
);
