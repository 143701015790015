import { string, object, ref } from 'yup';
import { emailRegex } from './emailRegex';

const userValidation = object().shape(
  {
    firstName: string().required().trim(),
    lastName: string().required().trim(),
    email: string()
      .max(80, 'email should be a maximum of 80 characters long')
      .required('email is required')
      .trim()
      .matches(emailRegex),
    phoneNumber: string()
      .matches(/^\d{10,10}$/, {
        message: 'Please enter valid phone number',
        excludeEmptyString: true,
      })
      .when(['mobilePhone'], {
        is: (mobilePhone) => !mobilePhone,
        then: string().required(),
      }),
    mobilePhone: string()
      .matches(/^\d{10,10}$/, {
        message: 'Please enter valid mobile phone number',
        excludeEmptyString: true,
      })
      .when(['phoneNumber'], {
        is: (phoneNumber) => !phoneNumber,
        then: string().required(),
      }),
    alternateEmail: string()
      .matches(emailRegex)
      .max(80, 'email should be a maximum of 80 characters long')
      .trim()
      // eslint-disable-next-line func-names
      .test('not equal', 'Emails must be different!', function (v) {
        // Don't use arrow function
        const _ref = ref('email');
        return v !== this.resolve(_ref);
      }),
    gateCode: string().trim(),
  },
  [['mobilePhone', 'phoneNumber']]
);

export default userValidation;
