import gql from 'graphql-tag';

export const GET_USER_NOTIFICATIONS = gql`
    query notifications {
        notifications {
            id
            status
            targetUser
            text
            createdAt
        }
    }
`;

export const GET_USER_NOTIFICATIONS_SETTINGS = gql`
    query notificationsSettings {
        notificationsSettings {
            title
            types {
                name
                field
                value {
                    email
                    push
                }
            }
        }
    }
`
