import React from 'react';
import PropTypes from 'prop-types';
import MainContent from 'components/HomePage';
import LayoutWithSidebar from 'layouts/LayoutWithSidebar';
import withLoadProjects from 'services/HOCs/withLoadProjects';

const HomePage = ({ data, history }) => {
  let projects = [];
  if (data.roofProjects) {
    projects = [data?.roofProjects || [], data?.droneReports || []].flat();
  }

  return (
    <LayoutWithSidebar>
      <MainContent projects={projects} history={history} />
    </LayoutWithSidebar>
  );
};

HomePage.propTypes = {
  data: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

HomePage.defaultProps = {};
export default withLoadProjects(HomePage);
