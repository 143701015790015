import gql from 'graphql-tag';

export const GET_ROOF_COMPANIES = gql`
  query getRoofCompanies {
    getRoofCompanies {
      insuranceCompanyName
      insuranceCompanyEmail
      policyNumber
      mortgageCompanyName
      loanNumber
      otherInsurance
    }
  }
`;
