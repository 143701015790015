import React, { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import MobileContext from 'context/MobileContext';
import Text from 'components/shared/Text';
import { withBracketsNumber } from 'utils/phoneNumberFormatter';
import styles from './styles.module.css';

const contactTitles = [
  { title: 'Full Name', key: 'fullName', value: '' },
  { title: 'Email Address', key: 'emailAddress', value: '' },
  { title: 'Alternative Email Address', key: 'alternativeEmailAddress', value: '' },
  { title: 'Phone Number', key: 'phoneNumber', value: '' },
  { title: 'Mobile Number', key: 'mobileNumber', value: '' },
  { title: 'Property Address', key: 'propertyAddress', value: '' },
  { title: 'Gate Code', key: 'gateCode', value: '' },
];

const contactsTitles = [
  { title: 'Full Name', key: 'fullName', value: '' },
  { title: 'Email Address', key: 'emailAddress', value: '' },
  { title: 'Alternative Email Address', key: 'alternativeEmailAddress', value: '' },
  { title: 'Phone Number', key: 'phoneNumber', value: '' },
  { title: 'Mobile Number', key: 'mobileNumber', value: '' },
];

const Row = ({ title, value }) => {
  const isMobile = useContext(MobileContext);
  return (
    <div className={styles.rowContainer}>
      <Text tag="h2" size="s14" weight="wr" lineHeight="h13" color="#686868">
        {title}
      </Text>
      <Text
        tag="h2"
        size={isMobile ? 's13' : 's14'}
        weight={isMobile ? 'wb' : 'wr'}
        lineHeight="h13"
        color="000000"
        className={styles.value}
      >
        {value}
      </Text>
    </div>
  );
};

Row.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
};

Row.defaultProps = {
  value: '',
};

const ViewMode = ({ user }) => {
  const { contact, contacts } = useMemo(() => {
    let contactResult = contactTitles;
    const contactsResult = [];

    if (user) {
      const contactData = {
        fullName: `${user.firstName || ''} ${user.lastName || ''}`,
        emailAddress: `${user.email || ''}` || '',
        alternativeEmailAddress: user.alternateEmail || '',
        phoneNumber: withBracketsNumber(user.phone),
        mobileNumber: withBracketsNumber(user.mobilePhone),
        propertyAddress: `${user.account.name || ''}`,
        gateCode: user.account?.gateCode || '',
      };

      contactResult = contactTitles.map(({ title, key, value }) => ({
        title,
        key,
        value: contactData[key],
      }));

      const contactsData = user.account?.contacts
        ? user.account.contacts.map((c) => {
            return {
              fullName: `${c?.firstName || ''} ${c?.lastName || ''}`,
              emailAddress: c.email || '',
              alternativeEmailAddress: c.alternateEmail || '',
              phoneNumber: withBracketsNumber(c.phone),
              mobileNumber: withBracketsNumber(c.mobilePhone),
              pgId: c.pgId,
            };
          })
        : [];

      contactsData.forEach((data) => {
        const c = contactsTitles.map(({ title, key }) => ({
          title,
          key: `${key}-${data.pgId}`,
          value: data[key],
        }));

        contactsResult.push(c);
      });

      return { contact: contactResult, contacts: contactsResult };
    }

    return { contact: contactResult, contacts: contactsResult };
  }, [user]);

  return (
    <div>
      {contact.map(({ title, key, value }) => (
        <Row title={title} key={key} value={value} />
      ))}

      {contacts.map((titles, index) => (
        <div key={index}>
          <div className={styles.line} />

          {titles.map(({ title, key, value }) => (
            <Row title={title} key={key} value={value} />
          ))}
        </div>
      ))}
    </div>
  );
};

ViewMode.propTypes = {
  user: PropTypes.object.isRequired,
};

ViewMode.defaultProps = {};

export default ViewMode;
