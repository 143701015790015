import gql from 'graphql-tag';

export const GET_DRONE = gql`
  query getDrone {
    droneReports {
      pgId
      status
      price
      dronePackage
      scheduledDate
      sfId
      paid
    }
  }
`;
