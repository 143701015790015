import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';
import StatusTabsComponentMobile from 'components/shared/StatusTabs/Mobile';
import moment from 'moment';
import LayoutWithSidebar from 'layouts/LayoutWithSidebar';
import projectStyles from 'components/DroneReport/styles.module.css';
import Text from 'components/shared/Text';
import Icon from 'components/shared/IconSVG';
import { chevronIcon } from 'assets/icons';
import StatusTabs from 'components/shared/StatusTabs/Desktop';
import JobDetailsStep from 'components/Warranty/JobDetailsStep';
import RepairPlanningStep from 'components/Warranty/RepairPlanningStep';
import { ReadBeforeRepair } from 'components/Warranty/RepairPlanningForm';
import MobileContext from 'context/MobileContext';
import AccountDetails from 'components/Warranty/ContactForm';
import { MY_PROJECTS } from 'graphql/queries/projects';
import Spinner from 'components/shared/Spinner';
import { CREATE_WARRANTY_MUTATION } from 'graphql/mutations/warranty';
import { GET_WARRANTIES } from 'graphql/queries/warranty';
import withLoadWarranties from 'services/HOCs/withLoadWarranties';
import sortByPrimaryContacts from 'utils/sortByPrimaryContacts';
import { formatDate } from 'utils/dateFormatters';
import styles from './style.module.css';
import WithError from '../../services/HOCs/withError';
import { GET_CURRENT_USER } from '../../graphql/cache';

const getAvailableWarrantyProjects = (projects) =>
  projects?.roofProjects?.reduce((acc, project) => {
    if (moment(project?.dateOfInstall).diff(moment(), 'days') < 1)
      acc.push({
        label: `Roof project (created on ${formatDate(project.createdDate)})`,
        value: `${project.pgId},${project.sfId}`,
      });
    return acc;
  }, []);

const CreateWarrantyPage = ({ caseDetails, caseStatus, tabIndex }) => {
  const history = useHistory();
  const { data: userData } = useQuery(GET_CURRENT_USER);

  const [currentTabIndex, setCurrentTabIndex] = useState(tabIndex >= 0 ? tabIndex : 0);

  const [
    createWarranty,
    { data: createdWarrantyData, error: creationError, loading: loadingCreateWarranty },
  ] = useMutation(CREATE_WARRANTY_MUTATION, {
    update: (cache, { data }) => {
      const { getWarranties } = cache.readQuery({ query: GET_WARRANTIES });
      if (data?.createWarranty) getWarranties.push(data?.createWarranty);
      cache.writeQuery({ query: GET_WARRANTIES, data: { getWarranties } });
      setCurrentTabIndex(2);
    },
  });
  const isMobile = useContext(MobileContext);

  useEffect(() => {
    setCurrentTabIndex(tabIndex);
  }, [tabIndex]);

  const { data: projectData, loading, projectsLoadingError } = useQuery(MY_PROJECTS);

  const tabsList = [
    {
      title: 'Information',
      children: (
        <div className={styles.stageWarrantyWrapper}>
          <AccountDetails
            contacts={sortByPrimaryContacts(userData)}
            handleGoNext={() => setCurrentTabIndex(1)}
            handleCancel={() => history.push('/warranty')}
            isMobile={isMobile}
          />
        </div>
      ),
    },
    {
      title: 'Case Details',
      children: (
        <div className={styles.stageWarrantyWrapper}>
          <JobDetailsStep
            caseDetails={caseDetails}
            availableProjects={getAvailableWarrantyProjects(projectData)}
            createWarranty={createWarranty}
            handleGoNext={() => setCurrentTabIndex(2)}
            handleCancel={() => setCurrentTabIndex(0)}
            isRequesting={loadingCreateWarranty}
            isMobile={isMobile}
          />
        </div>
      ),
    },
    {
      title: 'Case Status',
      children: (
        <div className={styles.stageWarrantyWrapper}>
          <RepairPlanningStep
            caseStatus={caseStatus ?? createdWarrantyData?.createWarranty}
            handleCancel={() => setCurrentTabIndex(1)}
          >
            {isMobile ? <ReadBeforeRepair /> : null}
          </RepairPlanningStep>
        </div>
      ),
    },
  ];
  const handleClose = () => {
    history.push('/warranty');
  };

  return (
    <WithError isShow={!!(creationError || projectsLoadingError)}>
      <LayoutWithSidebar>
        <>
          <div className={styles.droneReportTitle}>
            <div className={styles.droneReportTitleStep} onClick={handleClose}>
              <Text
                color="#837F7D"
                weight="wr"
                size="s16"
                tag="p"
                lineHeight="h16"
                className={styles.title}
              >
                Warranty
              </Text>
            </div>

            <Icon src={chevronIcon} height="14px" className={projectStyles.chevronIcon} />
            <Text color="#000" weight="wb" size="s14" tag="p" lineHeight="h18">
              New Case
            </Text>
          </div>
          <div className={styles.projectWrapper}>
            {loading ? (
              <Spinner />
            ) : isMobile ? (
              <StatusTabsComponentMobile
                tabList={tabsList}
                currentTabIndex={currentTabIndex}
              />
            ) : (
              <StatusTabs tabList={tabsList} currentTabIndex={currentTabIndex} />
            )}
          </div>
        </>
      </LayoutWithSidebar>
    </WithError>
  );
};

export default withLoadWarranties(CreateWarrantyPage);

CreateWarrantyPage.propTypes = {
  caseDetails: PropTypes.object,
  caseStatus: PropTypes.object,
  tabIndex: PropTypes.number,
};

CreateWarrantyPage.defaultProps = {
  caseDetails: null,
  caseStatus: null,
  tabIndex: 0,
};
