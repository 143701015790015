import React, { useEffect, useState } from 'react';
import ReactGa from 'react-ga';
import { Switch, useHistory, withRouter } from 'react-router-dom';
import PrivateRoute from 'services/HOCs/privateRoute';
import TabletContext from 'context/TabletContext';
import ScrollToTop from 'services/HOCs/scrollToTop';
import MobileContext from 'context/MobileContext';
import { useApolloClient, useLazyQuery, useQuery } from '@apollo/react-hooks';
import { assignEnvToWindow } from 'utils/assignEnvToWindow';
import {
  AccountActivationPage,
  ChangedEmailPage,
  ConfirmEmailPage,
  ContactUsPage,
  DroneReportPage,
  FAQsPage,
  ForgotPasswordPage as ForgotPassword,
  HomePage,
  LogoutPage,
  MyAccountPage,
  MyProjectPage,
  MyReferralsPage,
  ResetPasswordPage as ResetPassword,
  SignInPage,
  SignUpPage,
  WarrantyPage,
} from 'pages';
import {
  CreateRoofProjectPage,
  CurrentDroneReport,
  CurrentRoofProject,
} from 'containers';
import CreateWarrantyPage from 'pages/CreateWarrantyPage';
import authUtils from 'utils/auth';
import AuthContext from 'context/AuthContext';
import { GET_USER } from 'graphql/queries/auth';
import { isOnline } from 'utils/socket';
import { GET_HOMEOWNER_CONFIG } from 'graphql/queries/appConfigHomeowner';
import AppConfigContext from 'context/AppConfigContext';
import moment from 'moment';
import WarrantyAvailabilityContext from 'context/WarrantyAvailabilityContext';
import Platforms from 'platform-detect/os.mjs';
import ViewWarrantyPage from './pages/ViewWarrantyPage';
import { MY_PROJECTS } from './graphql/queries/projects';
import { GET_STORE_URLS } from './graphql/queries/appConfigHomeowner';

function App() {
  const history = useHistory();
  const client = useApolloClient();
  const [isTablet, setIsTablet] = useState(window.innerWidth <= 991);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [loading, setLoading] = useState(true);
  const [getAppConfig, { data: dataConfig }] = useLazyQuery(GET_HOMEOWNER_CONFIG);
  const { data: storeURLSData } = useQuery(GET_STORE_URLS);
  const [isWarrantyAvailable, setIsWarrantyAvailable] = useState(false);
  const [loadingWarrantyAvailability, setLoadingWarrantyAvailability] = useState(true);

  const [getProjects, { data: projects }] = useLazyQuery(MY_PROJECTS, {
    onCompleted: () => {
      setIsWarrantyAvailable(
        // If date of install =< today
        projects?.roofProjects?.findIndex(
          (project) =>
            moment(project?.dateOfInstall, 'YYYY-MM-DD')
              .startOf('day')
              .diff(moment().startOf('day'), 'days') <= 0
        ) !== -1
      );
      setLoadingWarrantyAvailability(false);
    },
    onError: () => {
      setIsWarrantyAvailable(false);
      setLoadingWarrantyAvailability(false);
    },
  });

  const [getUser, { data }] = useLazyQuery(GET_USER, {
    onCompleted: () => {
      if (data?.getUser) {
        client.writeData({
          data: { user: data.getUser },
        });
        getAppConfig();
        isOnline(data?.getUser?.pgId);
      }
    },
    onError: () => {
      history.push('/logout');
      setLoading(false);
    },
  });

  const handleResize = () => {
    setIsTablet(window.innerWidth <= 991);
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    const trackingId = "UA-32872144-9"; // Live site's tracking ID
    // const trackingId = "UA-32872144-11"; // Staging site's tracking ID
    ReactGa.initialize(trackingId);

    const token = authUtils.getToken();
    const setUser = async () => {
      // const id = authUtils.decodeToken(token)?.contact?.pgId;
      const { contact, state, zipCode } = authUtils.decodeToken(token);
      const id = contact?.pgId;

      ReactGa.set({
        UserState: state,
        UserZipCode: zipCode
      });

      ReactGa.event({
        category: "Login",
        action: "User logged In",
        label: "Login"
      });

      await getUser({
        variables: { id },
      });
    };

    if (token) {
      setIsAuthorized(true);
      setUser();
      getProjects();
    }
    setLoading(false);
  }, [getUser]);

  assignEnvToWindow(data?.getUser);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  useEffect(() => {
    ReactGa.pageview(window.location.pathname);
    ReactGa.ga('send', 'screenview', {
      'appName': 'myAppName',
      'screenName': 'Home'
    });
    // Masure time dureation  'https://developers.google.com/analytics/devguides/collection/analyticsjs/user-timings'
    // const timeSincePageLoad = Math.round(performance.now());
    // ReactGa.ga('send', 'timing', 'JS Dependencies', 'load', timeSincePageLoad);
  }, [history.location.pathname]);

  /* useEffect(() => {
    const { android, ios } = Platforms;

    if (storeURLSData?.storeURLS && document.referrer) {
      const { urlGooglePlay, urlAppStore } = storeURLSData.storeURLS;
      if (ios) {
        window.location.replace(urlAppStore);
      }
      if (android) {
        window.location.replace(urlGooglePlay);
      }
    }
  }, [storeURLSData]); */

  return (
    <>
      <AuthContext.Provider value={isAuthorized}>
        <AppConfigContext.Provider value={dataConfig?.appConfigHomeowner}>
          <WarrantyAvailabilityContext.Provider value={isWarrantyAvailable}>
            <TabletContext.Provider value={isTablet}>
              <MobileContext.Provider value={isMobile}>
                <ScrollToTop>
                  <Switch>
                    {loading ? (
                      <></>
                    ) : (
                      <>
                        <PrivateRoute
                          allow={isAuthorized}
                          path="/"
                          exact
                          component={HomePage}
                          redirectTo="/sign-in"
                        />
                        <PrivateRoute
                          allow={isAuthorized}
                          path="/homepage"
                          exact
                          component={HomePage}
                        />
                        <PrivateRoute
                          allow={isAuthorized}
                          path="/my-account"
                          exact
                          component={MyAccountPage}
                        />
                        <PrivateRoute
                          allow={isAuthorized}
                          path="/my-projects"
                          exact
                          component={MyProjectPage}
                        />
                        <PrivateRoute
                          allow={false}
                          path="/my-referrals"
                          exact
                          component={MyReferralsPage}
                        />
                        <PrivateRoute
                          allow={isAuthorized}
                          path="/my-projects/order-drone"
                          exact
                          component={DroneReportPage}
                        />
                        <PrivateRoute
                          allow={isAuthorized}
                          path="/my-projects/roof-project"
                          exact
                          component={CreateRoofProjectPage}
                        />

                        <PrivateRoute
                          allow={isAuthorized}
                          path="/my-projects/roofs/:id"
                          exact
                          component={CurrentRoofProject}
                        />
                        <PrivateRoute
                          allow={isAuthorized}
                          path="/my-projects/drones/:id"
                          exact
                          component={CurrentDroneReport}
                        />
                        <PrivateRoute
                          allow={
                            isAuthorized &&
                            (isWarrantyAvailable || loadingWarrantyAvailability)
                          }
                          path="/warranty"
                          exact
                          component={WarrantyPage}
                        />
                        <PrivateRoute
                          allow={
                            isAuthorized &&
                            (isWarrantyAvailable || loadingWarrantyAvailability)
                          }
                          path="/warranty/new-claim"
                          exact
                          component={CreateWarrantyPage}
                        />
                        <PrivateRoute
                          allow={
                            isAuthorized &&
                            (isWarrantyAvailable || loadingWarrantyAvailability)
                          }
                          path="/warranty/view/:id"
                          component={ViewWarrantyPage}
                        />
                        <PrivateRoute
                          allow={isAuthorized}
                          path="/contact-us"
                          exact
                          component={ContactUsPage}
                        />
                        <PrivateRoute
                          allow={isAuthorized}
                          path="/faq"
                          exact
                          component={FAQsPage}
                        />
                        <PrivateRoute
                          allow={isAuthorized}
                          path="/earn-referral-rewards"
                          exact
                          component={HomePage}
                        />
                        <PrivateRoute
                          allow={isAuthorized}
                          path="/logout"
                          exact
                          component={LogoutPage}
                        />
                        <PrivateRoute
                          allow={!isAuthorized}
                          path="/sign-in"
                          exact
                          component={SignInPage}
                        />
                        <PrivateRoute
                          allow={!isAuthorized}
                          path="/forgot-password"
                          exact
                          component={ForgotPassword}
                        />
                        <PrivateRoute
                          allow={!isAuthorized}
                          path="/sign-up"
                          exact
                          component={SignUpPage}
                        />
                        <PrivateRoute
                          allow={!isAuthorized}
                          path="/confirm"
                          exact
                          component={AccountActivationPage}
                        />
                        <PrivateRoute
                          allow={!isAuthorized}
                          path="/changeEmail"
                          exact
                          component={ChangedEmailPage}
                        />
                        <PrivateRoute
                          allow={!isAuthorized}
                          path="/confirmChangingEmailFromNewEmail"
                          exact
                          component={ConfirmEmailPage}
                        />
                        <PrivateRoute
                          allow={!isAuthorized || isAuthorized}
                          path="/confirmChangingEmailFromOldEmail"
                          exact
                          component={ConfirmEmailPage}
                        />
                        <PrivateRoute
                          allow={!isAuthorized}
                          path="/create-password"
                          exact
                          component={ResetPassword}
                        />
                      </>
                    )}
                  </Switch>
                </ScrollToTop>
              </MobileContext.Provider>
            </TabletContext.Provider>
          </WarrantyAvailabilityContext.Provider>
        </AppConfigContext.Provider>
      </AuthContext.Provider>


    </>
  );
}

App.propTypes = {};

export default withRouter(App);
