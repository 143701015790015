import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import IconSVG from 'components/shared/IconSVG';
import { warrantyIcon } from 'assets/icons';
import Text from 'components/shared/Text';
import statusesColors from 'components/shared/warrantyStatusesColors.module.css';
import MobileContext from 'context/MobileContext';
import warrantyStatuses from 'enums/warrantyStatuses';
import { formatDateTime } from 'utils/dateFormatters';
import styles from './style.module.css';

const WarrantyTile = ({
  warranty: { repairDateTime, status, subject, caseNumber },
  handleClick,
}) => {
  const isMobile = useContext(MobileContext);

  const statusColorClassName =
    statusesColors[
      Object.keys(warrantyStatuses).find((key) => warrantyStatuses[key] === status)
    ];

  const _renderStatus = () => (
    <div className={styles.status}>
      <span className={[styles.indicator, statusColorClassName]?.join(' ')} />
      <Text size="s14" lineHeight="h19" color="#000000">
        {status}
      </Text>
    </div>
  );

  return (
    <div className={styles.tileWrapper} onClick={handleClick}>
      <div className={styles.leftPart}>
        <IconSVG src={warrantyIcon} className={styles.icon} width="40px" />
      </div>
      <div className={styles.main}>
        <div className={styles.justified}>
          <Text size="s16" lineHeight="h20" weight="wb" color="#000000">
            {`Warranty ${caseNumber ?? ''}`}
          </Text>
          {repairDateTime ? (
            <Text size="s14" lineHeight="h15" weight="wb" color="#000000">
              Date of repair : {formatDateTime(repairDateTime)}
            </Text>
          ) : null}
        </div>
        <div className={styles.justified}>
          {/*
          <Text size="s14" lineHeight="h19" color="#767676" weight="wb">Contractor: <Text
          size="s14" lineHeight="h19" color="#767676">{contractor.name}</Text>
          </Text>
          */}
          {!isMobile && _renderStatus()}
        </div>
        <Text size="s14" lineHeight="h19" color="#767676" weight="wb">
          Subject:{' '}
          <Text size="s14" lineHeight="h19" color="#767676">
            {subject}
          </Text>
        </Text>

        {isMobile && _renderStatus()}
      </div>
    </div>
  );
};

export default WarrantyTile;

WarrantyTile.propTypes = {
  // contractor: PropTypes.shape({
  //   email: PropTypes.string.isRequired,
  //   name: PropTypes.string.isRequired,
  //   phoneNumber: PropTypes.string.isRequired,
  // }).isRequired,

  handleClick: PropTypes.func.isRequired,
  warranty: PropTypes.shape({
    caseNumber: PropTypes.string,
    description: PropTypes.string.isRequired,
    repairDateTime: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    status: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
  }).isRequired,
};
