import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.css';
import Label from './label';

const FieldWrapper = ({ name, label, isError, errorMessage, isDisabled, children }) => {
  return (
    <div className={styles.field}>
      <Label name={name} isDisabled={isDisabled}>
        {label}
      </Label>
      {children}
      {isError && errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
    </div>
  );
};

FieldWrapper.propTypes = {
  isError: PropTypes.bool,
  label: PropTypes.string,
  errorMessage: PropTypes.string,
  name: PropTypes.string,
  isDisabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

FieldWrapper.defaultProps = {
  isError: false,
  label: '',
  name: '',
  isDisabled: false,
  errorMessage: '',
};

export default FieldWrapper;
