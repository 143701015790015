import React from 'react';
// import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import Icon from 'components/shared/IconSVG';
import { roofIcon } from 'assets/icons';
import Modal from 'components/shared/modals/SunnyModal';
import Text from 'components/shared/Text';
import Button from 'components/shared/Button';
import close from 'assets/icons/close.svg';
import styles from '../modal.module.css';

const StartRoofProjectModal = ({ handleClose, handleClick, isShow }) => {
  if (!isShow) return null;
  return (
    <Modal
      isSmallSize
      headerContent={
        <>
          <Icon
            src={close}
            height="20px"
            width="20px"
            className={styles.closeIcon}
            handleClick={handleClose}
          />
          <Icon
            src={roofIcon}
            height="auto"
            width="60px"
            className={styles.questionIcon}
          />
          <Text color="#000" size="s24" weight="wb">
            Start a New Roof Project
          </Text>
        </>
      }
      mainContent={
        <div className={styles.modalMainContent}>
          <Text size="s16" color="#000000" weight="wr" lineHeight="h20">
            You know you need a roof replacement. You have come to the right place.
          </Text>
          <br />
          <div className={styles.modalMainContentLeft}>
            <Text size="s15" color="#000000" weight="wr" lineHeight="h22">
              Step 1: Confirm property details
              <br />
              Step 2: Provide your insurance information
              <br />
              Step 3: E-Sign contract
              <br />
              Step 4: Damage assessment by drone
              <br />
              Step 5: Report will be reviewed with you and your insurance adjuster
            </Text>
          </div>
          <Button handleClick={handleClick} size="medium" className={styles.formButton}>
            Get Started
          </Button>
        </div>
      }
    />
  );
};

StartRoofProjectModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  isShow: PropTypes.bool,
};

StartRoofProjectModal.defaultProps = {
  isShow: false,
};

export default StartRoofProjectModal;
