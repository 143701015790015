import gql from 'graphql-tag';
import { Projects } from 'graphql/fragments';

export const CREATE_ROOF_PROJECT = gql`
  mutation CREATE_ROOF_PROJECT(
    $data: OpportunityDataInput!
    $draftOpportunityId: String
  ) {
    createRoofProject(data: $data, draftOpportunityId: $draftOpportunityId) {
      ...FullRoofProject
    }
  }
  ${Projects.fragments.FullRoofProject}
`;

export const CONFIRM_SECOND_STAGE = gql`
  mutation CONFIRM_SECOND_STAGE(
    $data: OpportunityPlaningDataInput!
    $projectId: String!
    $isSubmitted: Boolean
  ) {
    sendOpportunityPlaning(
      data: $data
      projectId: $projectId
      isSubmitted: $isSubmitted
    ) {
      ...FullRoofProject
    }
  }
  ${Projects.fragments.FullRoofProject}
`;

export const CONFIRM_INSTALL_PREP_STAGE = gql`
  mutation CONFIRM_INSTALL_PREP_STAGE(
    $satelliteDish: String!
    $projectId: String!
    $solarPanelsRemovalDate: String
  ) {
    confirmInstallPrepStage(
      satelliteDish: $satelliteDish
      solarPanelsRemovalDate: $solarPanelsRemovalDate
      projectId: $projectId
    ) {
      message
    }
  }
`;

export const SAVE_AS_DRAFT_ROOF_PROJECT = gql`
  mutation SAVE_AS_DRAFT_ROOF_PROJECT(
    $data: DraftOpportunityDataInput!
    $projectId: String
  ) {
    saveDraftRoofProject(data: $data, projectId: $projectId) {
      ...InsuranceClaimStage
    }
  }
  ${Projects.fragments.InsuranceClaimStage}
`;

export const REMOVE_DRAFTED_ROOF_PROJECT = gql`
  mutation REMOVE_DRAFTED_ROOF_PROJECT($projectId: String!) {
    removeDraftedRoofProject(projectId: $projectId) {
      message
    }
  }
`;

export const HOA_APPROVAL_ACKNOWLEDGMENT = gql`
  mutation HOA_APPROVAL_ACKNOWLEDGMENT($projectId: String!) {
    hoaApprovalAcknowledgment(projectId: $projectId) {
      message
    }
  }
`;

export const GIVE_FEEDBACK_CONTRACTOR = gql`
  mutation FEEDBACK_CONTRACTOR(
    $reviewText: String
    $reviewRating: String
    $projectId: String!
    $isConfirm: Boolean!
  ) {
    giveFeedbackContractor(
      reviewText: $reviewText
      reviewRating: $reviewRating
      projectId: $projectId
      isConfirm: $isConfirm
    ) {
      message
    }
  }
`;

export const UPLOAD_LOSS_SHEET_FILES = gql`
  mutation UPLOAD_FILES(
    $files: [UploadedFile!]!
    $parentId: String!
    $description: String
  ) {
    uploadFiles(files: $files, parentId: $parentId, description: $description) {
      contentDocumentId
      name
      description
      contentType
    }
    uploadLossSheetDocs(parentId: $parentId) {
      pgId
    }
  }
`;
export const UPLOAD_HOA_FILES = gql`
  mutation UPLOAD_FILES(
    $files: [UploadedFile!]!
    $parentId: String!
    $description: String
  ) {
    uploadFiles(files: $files, parentId: $parentId, description: $description) {
      contentDocumentId
      name
      description
      contentType
    }
    setOpportunityFlag(parentId: $parentId) {
      message
    }
  }
`;
