import gql from 'graphql-tag';

export const READ_NOTIFICATIONS = gql`
    mutation READ_NOTIFICATIONS($notificationsIds: [String!]) {
        readNotifications(notificationsIds: $notificationsIds) {
            id
            status
            targetUser
            text
            createdAt
        }
    }
`;

export const UPDATE_NOTIFICATIONS_SETTINGS = gql`
    mutation UPDATE_NOTIFICATIONS_SETTINGS($settings: [NotificationsSettingsInput]) {
        updateNotificationsSettings(settings: $settings) {
            message
        }
    }
`
