import React from 'react';
import PropTypes from 'prop-types';
import CaseDoc from 'components/Projects/Documents/MyDocuments/CaseDoc';
import useLoadAttachments from 'services/hooks/loadAttachments';

const CaseDocContainer = ({ pgId }) => {
  const { data, loading } = useLoadAttachments({
    opportunityId: pgId,
    documentCategory: 'Case',
  });
  return <CaseDoc files={data?.downloadFilesName} load={loading} />;
};

CaseDocContainer.propTypes = {
  pgId: PropTypes.string.isRequired,
};

export default CaseDocContainer;
