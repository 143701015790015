import React, { useState } from 'react';
import ProjectTypes from 'enums/projectTypes';
import ReferralCard from 'components/shared/ReferralCard';
import Text from 'components/shared/Text';
import { formatDate } from 'utils/dateFormatters';
import { roofProjectStages } from 'enums/roofProjectStatus';
import RoofProjectCard from 'components/shared/ProjectCard/RoofProjectCard'
import styles from './Tabs.css';



const Tabs = (props) => {
    const [toggleState, setToggleState] = useState(1);

    const toggleTab = (index) => {
        setToggleState(index);
    };

    return (
        <div className="container submit_cont">
            <div className="bloc-tabs">
                <button
                    className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(1)}>
                    Submitted Referrals
                </button>

                <button
                    className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(2)}>
                    Completed Referrals
                </button>
            </div>

            <div className="content-tabs">
                <div
                    className={toggleState === 1 ? "content  active-content" : "content"}>
                    <h2>Submitted Referrals</h2>
                    <hr />
                    <br />
                    <div className="testCard">
                        <RoofProjectCard
                            stage="Insurance Claim"
                            status="Ongoing"
                            isDisableOpen="test"
                            dateOfInstall="test"
                            createdDate="8/10/2021"
                            isAvailableDelete="test"
                            handleClick="test"
                            handleDelete="false"
                        />
                        <br />
                        <RoofProjectCard className="testCard"
                            stage="Install Prep"
                            status="Ongoing"
                            isDisableOpen="test"
                            dateOfInstall="test"
                            createdDate="8/10/2021"
                            isAvailableDelete="test"
                            handleClick="test"
                            handleDelete="test"
                        />
                        <br />
                        <RoofProjectCard className="testCard"
                            stage="Project Planning"
                            status="Ongoing"
                            isDisableOpen="test"
                            dateOfInstall="test"
                            createdDate="8/10/2021"
                            isAvailableDelete="test"
                            handleClick="test"
                            handleDelete="test"
                        />
                    </div>

                </div>

                <div
                    className={toggleState === 2 ? "content  active-content" : "content"}>
                    {props.roofProjects === 0 ? (
                        <div className={styles.tabContentEmpty}>
                            <Text color="#000" size="s16">
                                You don't have any referrals
                            </Text>
                        </div>
                    ) : (
                        <fieldset disabled={props.loading}>
                            {props.roofProjects.map((project) => {
                                if (project.stage.status == "Completed") {
                                    return (
                                        <div className={styles.cardWrapper} key={project.pgId}>
                                            <ReferralCard
                                                nameOfReferred={project.nameOfReferred}
                                                stage={project.stage?.stageName}
                                                address={project.address}
                                                status={project.stage?.status}
                                                isDisableOpen={project.stage?.isDisableOpen}
                                                createdDate={formatDate(project.createdDate)}
                                                isAvailableDelete={project.status === roofProjectStages.Draft.status}
                                                handleClick={() =>
                                                    props.handleOpenProject(project.pgId, ProjectTypes.Opportunity)
                                                }
                                                handleDelete={() => props.handleDelete(project.pgId)}
                                            />
                                        </div>
                                    );
                                }
                            })}
                        </fieldset>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Tabs;