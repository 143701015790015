import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import MobileContext from 'context/MobileContext';
import Title from 'components/shared/TitlePage';
import styles from './style.module.css';

const EmptyPageLayout = ({
  title,
  children,
  rightComponent,
  topMobileRightComponent,
  className,
}) => {
  const isMobile = useContext(MobileContext);
  return (
    <>
      {isMobile && (
        <div className={!topMobileRightComponent ? styles.emptyPageContentHeaderNav : ''}>
          {rightComponent}
        </div>
      )}
      <div className={styles.emptyPageContent}>
        <div className={styles.emptyPageContentHeader}>
          <Title>{title}</Title>
          {rightComponent && !isMobile && (
            <div className={styles.emptyPageContentHeaderNav}>{rightComponent}</div>
          )}
        </div>
        <div className={[styles.emptyPageContentBody, className].join(' ')}>
          {children}
        </div>
      </div>
    </>
  );
};

EmptyPageLayout.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  rightComponent: PropTypes.node,
  topMobileRightComponent: PropTypes.bool,
  className: PropTypes.string,
};

EmptyPageLayout.defaultProps = {
  rightComponent: null,
  topMobileRightComponent: false,
  className: '',
};

export default EmptyPageLayout;
