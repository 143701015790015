import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Spinner from 'components/shared/Spinner';
import { updateContactListUser } from 'graphql/cache/user';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_CONTACTS_MUTATION } from 'graphql/mutations/auth';
import { myAccountValidationSchema } from 'validation';
import Button from 'components/shared/Button';
import auth from 'utils/auth';
import api from 'services/api';
import WithError from 'services/HOCs/withError';
import { cleanNumber } from 'utils/phoneNumberFormatter';
import UserForm from './UserForm';
import ContactsForm from './ContactsForm';
import styles from './styles.module.css';

const EditMode = ({ user, handleCancel }) => {
  const [updateContacts, { loading, error }] = useMutation(UPDATE_CONTACTS_MUTATION);
  const contacts = user?.account?.contacts || [];

  const initialValues = {
    user: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      email: user?.email?.normalize('NFD').replace(/[\u0300-\u036f]/g, '') || '',
      oldEmail: user?.email?.normalize('NFD').replace(/[\u0300-\u036f]/g, '') || '',
      phoneNumber: user?.phone ? cleanNumber(user.phone) : '',
      alternateEmail:
        user?.alternateEmail?.normalize('NFD').replace(/[\u0300-\u036f]/g, '') || '',
      mobilePhone: user?.mobilePhone ? cleanNumber(user.mobilePhone) : '',
      propertyAddress: user?.account?.name
        .split(',')[0]
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, ''),
      gateCode: user?.account.gateCode || '',
      city: user?.account?.billingCity,
      state: user?.account?.billingState,
      zipCode: user?.account?.billingPostalCode,
    },
    contacts: contacts.map((c) => ({
      firstName: c.firstName || '',
      lastName: c.lastName || '',
      email: c.email?.normalize('NFD').replace(/[\u0300-\u036f]/g, '') || '',
      oldEmail: c.email?.normalize('NFD').replace(/[\u0300-\u036f]/g, '') || '',
      phoneNumber: c?.phone ? cleanNumber(c.phone) : '',
      alternateEmail:
        c?.alternateEmail?.normalize('NFD').replace(/[\u0300-\u036f]/g, '') || '',
      mobilePhone: c?.mobilePhone ? cleanNumber(c.mobilePhone) : '',
      key: c.pgId,
      pgId: c.pgId,
    })),
  };

  const onSubmit = async (values) => {
    const updateUserData = {
      firstName: values.user.firstName,
      lastName: values.user.lastName,
      newEmail: values.user.email,
      oldEmail: values.user.oldEmail,
      phone: values.user.phoneNumber,
      alternateEmail: values.user.alternateEmail,
      mobilePhone: values.user.mobilePhone,
      gateCode: values.user.gateCode,
    };

    const updateContactsData = values.contacts.map((contact) => ({
      firstName: contact.firstName,
      lastName: contact.lastName,
      email: contact.email,
      phone: contact.phoneNumber,
      alternateEmail: contact.alternateEmail,
      mobilePhone: contact.mobilePhone,
      pgId: contact.pgId,
    }));

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    await updateContacts({
      variables: {
        updateUserData,
        updateContactsData,
      },

      update: (cache, { data: { updateContacts: newContacts, updateUser } }) => {
        updateContactListUser(updateUser, newContacts);

        if (updateUser?.token) {
          auth.setToken(updateUser?.token);
          api.setAuthTokenToHeader(updateUser?.token);
          handleCancel();
        }
        if (!updateUser?.token) {
          auth.removeToken();
          window.location.replace('/changeEmail');
        }
      },
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={myAccountValidationSchema}
      onSubmit={onSubmit}
    >
      {({
        handleChange,
        handleSubmit,
        setTouched,
        setFieldTouched,
        errors,
        touched,
        values,
        ...formikProps
      }) => {
        const _handleAddContact = () => {
          const value = [
            ...values.contacts,
            {
              firstName: '',
              lastName: '',
              phoneNumber: '',
              email: '',
              oldEmail: '',
              alternateEmail: '',
              mobilePhone: '',
              key: `${values.contacts.length + 1}`,
              pgId: '',
            },
          ];
          handleChange({ target: { name: 'contacts', value } });
        };

        const handleDeleteContact = (key, index) => {
          const value = values.contacts.filter((contact) => contact.key !== key);
          handleChange({ target: { name: 'contacts', value } });

          if (touched.contacts) {
            const newContacts = touched.contacts ? [...touched.contacts] : [];
            newContacts.splice(index, 1);
            setTouched({ ...touched, contacts: newContacts });
          }
        };

        const handleUserChange = ({ target }) => {
          if (target.name === 'email') {
            setFieldTouched(`user.email`, true);

            values.contacts.forEach((contact, i) => {
              setFieldTouched(`contacts[${i}].email`, true);
            });
          } else {
            setFieldTouched(`user.${target.name}`, true);
          }

          handleChange({ target: { name: `user.${target.name}`, value: target.value } });
        };

        const handleContactChange = (index, { target }) => {
          if (target.name === 'email') {
            setFieldTouched(`user.email`, true);

            values.contacts.forEach((contact, i) => {
              setFieldTouched(`contacts[${i}].email`, true);
            });
          } else {
            setFieldTouched(`contacts[${index}].${target.name}`, true);
          }

          handleChange({
            target: { name: `contacts[${index}].${target.name}`, value: target.value },
          });
        };

        return (
          <>
            {loading ? (
              <Spinner />
            ) : (
              <WithError
                isShow={!!error}
                message={
                  error?.message.split('Error:')[1] || error?.message.split(':')[2]
                }
              >
                <form onSubmit={handleSubmit}>
                  <UserForm
                    {...formikProps}
                    values={{ ...values.user }}
                    errors={errors ? errors.user : {}}
                    touched={touched ? touched.user : {}}
                    handleChange={handleUserChange}
                  />

                  {values.contacts.map((contact, index) => (
                    <div key={contact.key}>
                      <div className={styles.line} />
                      <ContactsForm
                        {...formikProps}
                        values={contact}
                        handleAddContact={_handleAddContact}
                        handleChange={(event) => handleContactChange(index, event)}
                        handleDeleteContact={() =>
                          handleDeleteContact(contact.key, index)
                        }
                        errors={errors ? errors.contacts?.[index] : {}}
                        touched={touched ? touched.contacts?.[index] : {}}
                      />
                    </div>
                  ))}

                  <div className={styles.addButtonContainer}>
                    <Button color="white" size="medium" handleClick={_handleAddContact}>
                      + Add a contact
                    </Button>
                  </div>

                  <Button
                    type="submit"
                    size="small"
                    disabled={Object.keys(errors).length}
                    className={styles.buttonContainer}
                  >
                    Save Changes
                  </Button>
                  <Button
                    color="white"
                    size="small"
                    handleClick={handleCancel}
                    className={styles.buttonContainer}
                  >
                    Cancel
                  </Button>
                </form>
              </WithError>
            )}
          </>
        );
      }}
    </Formik>
  );
};

EditMode.propTypes = {
  user: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
};

EditMode.defaultProps = {};

export default EditMode;
