import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GET_WARRANTIES } from 'graphql/queries/warranty';
import { MY_PROJECTS } from 'graphql/queries/projects';
import WithError from 'services/HOCs/withError';
import LayoutWithSidebar from '../../layouts/LayoutWithSidebar';
import EmptyWarrantyPage from './Empty';
import WarrantyPageContent from './Content';
import Spinner from '../../components/shared/Spinner';

const WarrantyPage = () => {
  const { loading: projectsLoading, data: projectsData, error: projectsError } = useQuery(MY_PROJECTS);
  const { loading: warrantiesLoading, data: warrantiesData, error: warrantiesError } = useQuery(GET_WARRANTIES);

  const [showEmptyPage, setShowEmptyPage] = useState(true);
  const [emptyPageInfo, setEmptyPageInfo] = useState({
    text: '',
    buttonText: '',
    link: '/',
  });

  useEffect(() => {
    if (!projectsData?.roofProjects?.length) {
      setEmptyPageInfo({
        text:
          'You will be able to open a warranty case after, at least, one roof project is completed.',
        buttonText: 'Start a New Project',
        link: '/my-projects/roof-project',
      });
    } else if (!warrantiesData?.getWarranties?.length) {
      setEmptyPageInfo({
        text: 'You have no warranty cases yet',
        buttonText: 'New Warranty Case',
        link: '/warranty/new-claim',
      });
    } else setShowEmptyPage(false);
  }, [projectsData?.roofProjects, warrantiesData?.getWarranties]);

  if (projectsLoading || warrantiesLoading)
    return (
      <LayoutWithSidebar>
        <Spinner />
      </LayoutWithSidebar>
    );

  return (
    < WithError isShow={!!(warrantiesError || projectsError)
    }>
      <LayoutWithSidebar>
        {showEmptyPage ? (
          <EmptyWarrantyPage
            text={emptyPageInfo.text}
            buttonText={emptyPageInfo.buttonText}
            buttonLink={emptyPageInfo.link}
          />
        ) : (
            <WarrantyPageContent warranties={warrantiesData?.getWarranties} />
          )}
      </LayoutWithSidebar>
    </WithError >
  );
};

export default WarrantyPage;
