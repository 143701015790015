import gql from 'graphql-tag';

export const GET_USER = gql`
  query getUser($id: String) {
    getUser(id: $id) {
      pgId
      sfId
      accountPgId
      accountSfId
      firstName
      lastName
      name
      contractorStatus
      contactType
      phone
      mobilePhone
      email
      alternateEmail
      isFirstLogin
      account {
        pgId
        parentId
        name
        billingCity
        billingLatitude
        billingLongitude
        billingPostalCode
        billingState
        billingCountry
        billingGeoCodeAccuracy
        billingStreet
        type
        loanNumber
        gateCode
        address
        gateCode
        insuranceCompany {
          sfId
          name
          address
          loanNumber
        }
        mortgageCompany {
          sfId
          name
          address
          loanNumber
        }
        contacts {
          pgId
          sfId
          firstName
          lastName
          phone
          mobilePhone
          email
          alternateEmail
        }
      }
    }
  }
`;

export const CHECK_RESET_TOKEN = gql`
  query checkResetTokenExistance($token: String!) {
    checkResetTokenExistance(token: $token) {
      message
    }
  }
`;

export const CHECK_ACTIVATION_TOKEN = gql`
  query checkActivationTokenExistance($token: String!) {
    checkActivationTokenExistance(token: $token) {
      message
    }
  }
`;
