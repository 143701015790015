import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styles from './style.module.css';
import Text from '../../shared/Text';

const PasswordStrength = ({ strength }) => {
  const strengthTypes = {
    weak: {
      text: 'Weak!',
      color: '#f44336',
      progressValue: 1,
    },
    normal: {
      text: 'Normal',
      color: '#ff4e00',
      progressValue: 2,
    },
    strong: {
      text: 'Strong!',
      color: '#6C9961',
      progressValue: 3,
    },
  };

  const [strengthType, setData] = useState(strengthTypes.weak);

  useEffect(() => {
    switch (strength.score) {
      case 0:
        setData(strengthTypes.weak);
        break;
      case 1:
        setData(strengthTypes.weak);
        break;
      case 2:
        setData(strengthTypes.normal);
        break;
      case 3:
        setData(strengthTypes.normal);
        break;
      case 4:
        setData(strengthTypes.strong);
        break;
      default:
        setData(strengthTypes.weak);
        break;
    }
  }, [strength]);

  const progressItems = [];
  for (let i = 0; i < strengthType.progressValue; i++) {
    progressItems.push(
      <div
        key={i}
        className={styles.progress}
        style={{ backgroundColor: strengthType.color }}
      />
    );
  }

  return (
    <>
      <div className={styles.wrapper}>
        <label className={styles.label}>
          Password Strength:{' '}
          <Text size="s13" lineHeight="l14" weight="wb" color={strengthType.color}>
            {strengthType.text}
          </Text>
        </label>
        <div className={styles.progressWrapper}>{progressItems}</div>
      </div>
      {strength?.feedback?.warning ? (
        <Text size="s13" lineHeight="l14" weight="ws" color="837f7d">
          {strength.feedback.warning}
        </Text>
      ) : null}
    </>
  );
};

export default PasswordStrength;

PasswordStrength.propTypes = {
  strength: PropTypes.object.isRequired,
};
