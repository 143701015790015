import React from 'react';
import PropTypes from 'prop-types';
import RoofIcon from 'assets/icons/roof.svg';
import Text from '../Text';
import Icon from '../IconSVG';
import styles from './style.module.css';

const ReferralCard = ({
  status,
  createdDate,
  nameOfReferred,
  address,
  handleClick = () => { },
  handleDelete = () => { },
  isDisableOpen,
}) => {
  const iconSize = '40px';

  return (
    <div
      className={[
        styles.referralCardWrapper,
        isDisableOpen ? '' : styles.referralCardWrapperAvailable,
      ].join(' ')}
    >
      <div
        className={styles.referralCardBody}
        onClick={!isDisableOpen ? handleClick : () => { }}
      >
        <div className={styles.referralCardHeader}>
          <div className={styles.leftPart}>
            <Icon
              src={RoofIcon}
              height={iconSize}
              width={iconSize}
              className={styles.referralCardIcon}
            />
          </div>
          <div className={styles.referralCardLeftContent}>
            {nameOfReferred && (
              <div className={styles.referralCardTitle}>
                <Text color="#000" weight="wb" size="s16">
                  {nameOfReferred}
                </Text>
              </div>
            )}

            <div className={styles.centerContent}>
              <Text color="#767676" size="s14" weight="wm">
                Address: {address} | Date of referred: {createdDate}
              </Text>
            </div>

            <div className={[styles.status].join(' ')}>
              <span
                className={[
                  styles.indicator,
                  styles[`${status.toLowerCase()}Indicator`],
                ].join(' ')}
              />
              <Text color="#000" weight="wm" size="s14" tag="p">
                {status}
              </Text>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ReferralCard.propTypes = {
  status: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
  createdDate: PropTypes.string,
  nameOfReferred: PropTypes.string,
  address: PropTypes.string,
  handleDelete: PropTypes.func,
  isDisableOpen: PropTypes.bool,
};

ReferralCard.defaultProps = {
  handleClick: () => { },
  createdDate: '',
  nameOfReferred: '',
  address: '',
  handleDelete: () => { },
  isDisableOpen: false,
};

export default ReferralCard;
