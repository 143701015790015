import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import WrapperBlock from 'components/shared/WrapperBlock';
import Button from 'components/shared/Button';
import { Footer as FooterButtonDesck } from 'components/Projects/RoofProjects/Footer';
import { getDroneReportsFromCache } from 'graphql/cache/services';
import Frame from 'components/shared/Frame';
import AppConfigContext from 'context/AppConfigContext';
import ReactGa from 'react-ga';
import styles from './styles.module.css';


function PaymentStep({
  handleGoPreviousStep,
  dronePackage,
  isDisabled,
  handleGoNextStep,
  isAvailableNext,
  droneReport,
}) {
  const [currentServiceSfId, setCurrentServiceSfId] = useState(null);
  const [isOpenIframe, setIsOpenIframe] = useState(false);
  const config = useContext(AppConfigContext);

  useEffect(() => {
    setCurrentServiceSfId(
      getDroneReportsFromCache()?.filter(({ paid }) => !paid)[0]?.sfId
    );
  }, [getDroneReportsFromCache()]);

  const amount = dronePackage[1];
  const otherType = dronePackage[0];
  const ID = droneReport.sfId || currentServiceSfId;
  const type = 'Contact';
  const backUrl = window.location.href;

  const paymentLink = `${config?.paymentUrl}prcs=${amount}&rtyp=${type}&id=${ID}&bkurl=${backUrl}&srvct=${otherType}`;

  function handleEvent(e) {
    if (e.details === 'refresh') {
      window.location.reload();
    }
  }
  window.document.addEventListener('refreshEvent', handleEvent, false);

  function GaEventHandle() {
    ReactGa.event({
      category: "Drone Inspection",
      action: "Pressed the Pay Now button from the Payment tab",
      label: "Pay Now"
    });
  }

  return (
    <>
      <div className={[styles.wrapper, styles.wrapperPayment].join(' ')}>
        <WrapperBlock title="Payment" isForm>
          {!isOpenIframe ? (
            <fieldset disabled={isDisabled || !ID}>
              <Button
                size="small"
                handleClick={() => {
                  GaEventHandle();
                  setIsOpenIframe(true);
                }}
                className={styles.payButton}
              >
                Pay Now
              </Button>
            </fieldset>
          ) : null}
          {isOpenIframe ? <Frame link={paymentLink} /> : null}
        </WrapperBlock>
      </div>
      <FooterButtonDesck
        handleCancel={handleGoPreviousStep}
        isHideSubmit
        isHideNext={!isAvailableNext}
        handleNextStep={handleGoNextStep}
      />
    </>
  );
}

PaymentStep.propTypes = {
  handleGoPreviousStep: PropTypes.func,
  dronePackage: PropTypes.array.isRequired,
  isDisabled: PropTypes.bool,
  handleGoNextStep: PropTypes.func.isRequired,
  isAvailableNext: PropTypes.bool,
  droneReport: PropTypes.object.isRequired,
};

PaymentStep.defaultProps = {
  handleGoPreviousStep: () => { },
  isDisabled: false,
  isAvailableNext: false,
};

export default PaymentStep;
