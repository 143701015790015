import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.css';

const Frame = ({ link, type }) => {
  return (
    <iframe
      src={link}
      className={type ? styles.contractFrame : styles.paymentFrame}
      title="iframe"
      sandbox="allow-same-origin allow-top-navigation allow-scripts allow-forms allow-popups"
      frameBorder="no"
      scrolling="no"
    />
  );
};
Frame.propTypes = {
  link: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

Frame.defaultProps = {};
export default Frame;
