import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Footer from 'components/shared/Footer';
import Header from 'components/shared/Header';
import Sidebar from './Sidebar';
import styles from './style.module.css';

function LayoutWithSidebar({ children, loading }) {
  const [showSidebar, setShowSidebar] = useState(false);

  useEffect(() => {
    if (showSidebar) {
      document.body.style.overflowY = 'hidden';
    } else document.body.style.overflowY = 'auto';
  }, [showSidebar]);

  return (
    <div className={styles.templateWrapper}>
      <Header setShowSidebar={setShowSidebar} showSidebar={showSidebar} />
      <div className={styles.containerMenu}>
        <Sidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
        {showSidebar && (
          <div className={styles.popupBackground} onClick={() => setShowSidebar(false)} />
        )}
        <div className={[styles.containerMain].join(' ')}>{children}</div>
      </div>

      <Footer loading={loading} />
    </div>
  );
}

LayoutWithSidebar.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
};
LayoutWithSidebar.defaultProps = {
  children: null,
  loading: false,
};
export default LayoutWithSidebar;
