import { string, object, ref } from 'yup';
import { emailRegex } from './emailRegex';

const contactValidation = object().shape({
  firstName: string().required().trim(),
  lastName: string().required().trim(),
  phoneNumber: string()
    .matches(/^\d{10,10}$/, {
      message: 'Please enter valid phone number',
      excludeEmptyString: true,
    }).when(['mobilePhone'], {
      is: (mobilePhone) => !mobilePhone,
      then: string().required(),
    }),
  mobilePhone: string()
    .matches(/^\d{10,10}$/, {
      message: 'Please enter valid phone number',
      excludeEmptyString: true,
    }).when(['phoneNumber'], {
      is: (phoneNumber) => !phoneNumber,
      then: string().required(),
    }),
  email: string()
    .matches(emailRegex)
    .max(80, 'email should be a maximum of 80 characters long')
    .required('email is required')
    .trim(),
  alternateEmail: string()
    .matches(emailRegex)
    .max(80, 'email should be a maximum of 80 characters long')
    .trim()
    // eslint-disable-next-line func-names
    .test('not equal', 'Emails must be different!', function(v) {
      // Don't use arrow function
      const _ref = ref('email');
      return v !== this.resolve(_ref);
    }),
  pgId: string(),
  key: string(),
}, [['mobilePhone', 'phoneNumber']]);

export default contactValidation;
