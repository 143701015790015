import React, { useContext } from 'react';
import Link from 'components/shared/Link';
import PropTypes from 'prop-types';
import Text from 'components/shared/Text';
import { useHistory } from 'react-router-dom';
import Button from 'components/shared/Button';
import MobileContext from 'context/MobileContext';
import styles from './style.module.css';
import { rightColored } from '../../../assets/icons';
import Icon from '../../shared/IconSVG';

const ConfirmEmailComponent = ({ isCreatePassword, email }) => {
  const formText =
    <Text color="#292929" size="s14" lineHeight="h20" tag="p">
      Check the email {
      email ?
        <Text color="#000000" size="s14" lineHeight="h20" weight="wb">
          {email}
        </Text>
        : `account associated with your user name`
    } for instructions on {isCreatePassword ? 'creating' : 'resetting'} your password.
      <br/>
      Remember to look in your spam folder, where automated messages sometimes
      filter.
      <br/>
      If you still can't log in, contact your administrator.
    </Text>
  ;


  const isMobile = useContext(MobileContext);
  const history = useHistory();


  return (
    <form className={styles.wrapper}>
      <Text color="#00000D" weight="wb" size="s24" lineHeight="h22">
        Thank you!
      </Text>
      <div className={styles.wrapperBody}>
        <div className={styles.wrapperContent}>
          {formText}
        </div>
      </div>

      <div className={styles.wrapperContent}>
        {isMobile ? (
          <div>
            <Button size="large" handleClick={() => history.push('/sign-in')}>
              Back to login
            </Button>
            <br/>
            <br/>
            <div>
              <Link
                href={`mailto:${process.env.REACT_APP_ROOFCALIM_EMAIL}`}
                size="s14"
                bold
              >
                Contact Us
              </Link>
            </div>
          </div>
        ) : (
          <Link href="/sign-in" size="s16" bold>
            <Icon src={rightColored} width="15px" height="auto" className={styles.back}/>
            Back to login
          </Link>
        )}
      </div>
    </form>
  );
};

ConfirmEmailComponent.propTypes = {
  email: PropTypes.string,
  isCreatePassword: PropTypes.bool,
};

ConfirmEmailComponent.defaultProps = {
  email: '',
  isCreatePassword: false,
};

export default ConfirmEmailComponent;
