import { useState } from 'react';

const findSecondValue = (options = [], valueId) => {
  const option = options?.find(({ value }) => value === valueId);

  return option?.secondValue ?? null;
};

export default (options = [], currentValue) => {
  const value = currentValue ? findSecondValue(options, currentValue) : null;
  const [secondValue, setSecondValue] = useState(value);

  return {
    secondValue,
    handleChangeSecondValue: setSecondValue,
  };
};
