import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/shared/IconSVG';
import SunnyModal from 'components/shared/modals/SunnyModal';
import Text from 'components/shared/Text';
import Button from 'components/shared/Button';
import AppConfigContext from 'context/AppConfigContext';
import close from 'assets/icons/close.svg';
import styles from '../modal.module.css';

const WelcomeModal = ({ handleClose, handleClick, isShow }) => {
  const config = useContext(AppConfigContext);

  if (!isShow) return null;

  return (
    <SunnyModal
      isSmallSize
      headerContent={
        <>
          <Icon
            src={close}
            height="20px"
            width="20px"
            className={styles.closeIcon}
            handleClick={handleClose}
          />
          <Text size="s19" color="#000000" lineHeight="h20" weight="wb">
            Welcome to
          </Text>
          <br />
          <Text size="s32" color="#000000" lineHeight="h35" weight="wb">
            RoofClaim
          </Text>
        </>
      }
      mainContent={
        <div className={styles.modalMainContent}>
          <Text size="s16" color="#292929" lineHeight="h21" weight="wr">
            {config?.welcomeBannerText}
          </Text>
          <Button handleClick={handleClick} size="large" className={styles.formButton}>
            {config?.welcomeBannerButtonText}
          </Button>
        </div>
      }
    />
  );
};

WelcomeModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  isShow: PropTypes.bool,
};

WelcomeModal.defaultProps = {
  isShow: false,
};

export default WelcomeModal;
