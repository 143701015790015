import React from 'react';
import PropTypes from 'prop-types';
import Field from 'components/shared/Field';
import FieldWrapper from 'components/shared/Field/wrapper';
import { FormRow as Row, FormColumn as Col } from 'components/shared/Form';
import ProductSelect from 'components/shared/ProductSelect';
import { ReactComponent as SearchIcon } from 'assets/icons/Search.svg';
import { defaultIconProps } from 'components/shared/IconSVG';
import Button from 'components/shared/Button';
import useSecondValue from 'services/hooks/useSecondValueSelect';
import productStyles from './style.module.css';

function ProductSection({
  values = [],
  handleChange,
  errors = [],
  touched = [],
  options = {},
  isDisabled,
  isLoadingOptions,
  handleChangeRoofType,
  handleShowProductDetails,
}) {
  let filteredOptions = [];
  if (values.productType !== '3-Tab') {
    filteredOptions = options.productTypes.filter(({ value }) => value !== '3-Tab');
  }
  if (values.productType === '3-Tab') {
    filteredOptions = options.productTypes.filter(
      ({ value }) => value !== 'Architectural'
    );
  }

  const sortedOptions = options?.stoneMetalPanelTypes
    ?.map(({ value, label, secondValue }) => {
      return {
        value,
        label: label.slice(1),
        secondValue,
      };
    })
    ?.sort((a, b) => a.label.localeCompare(b.label));
  const { secondValue: materialImageUrl, handleChangeSecondValue } = useSecondValue(
    options.stoneMetalPanelTypes,
    values.stoneMetalPanelType?.value
  );

  return (
    <div>
      <Row>
        <Col>
          <FieldWrapper name="productType" label="Select your product type">
            <ProductSelect
              name="productType"
              isDisabled={isDisabled}
              options={filteredOptions}
              value={values.productType}
              onChange={handleChangeRoofType}
              isError={touched?.productType && Boolean(errors?.productType)}
            />
          </FieldWrapper>
        </Col>
        <Col>
          <Field
            name="stoneMetalPanelType"
            label="Please select a replacement product"
            type="select"
            isFullValue
            isSearchable
            isLoading={isLoadingOptions}
            isDisabled={isDisabled}
            options={sortedOptions}
            value={values.stoneMetalPanelType?.value}
            onChange={(event) => {
              handleChangeSecondValue(event.target.value.secondValue);
              handleChange(event);
            }}
            isError={touched?.stoneMetalPanelType && Boolean(errors?.stoneMetalPanelType)}
          />
        </Col>
      </Row>
      <Row>
        <div
          className={[productStyles.container, productStyles.containerCenter].join(' ')}
        >
          <Button
            color="white"
            textSize="small"
            height="big"
            handleClick={handleShowProductDetails}
            className={productStyles.button}
          >
            <SearchIcon
              style={{ width: '13px', height: '13px', marginRight: '10px' }}
              {...defaultIconProps}
            />
            See Product Details & Colors
          </Button>
        </div>

        {materialImageUrl && (
          <div
            className={productStyles.image}
            onClick={() => window.open(materialImageUrl, '_blank')}
            style={{ backgroundImage: `url(${materialImageUrl})` }}
          />
        )}
      </Row>
    </div>
  );
}

ProductSection.propTypes = {
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object,
  touched: PropTypes.object,
  options: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  handleChangeRoofType: PropTypes.func.isRequired,
  handleShowProductDetails: PropTypes.func.isRequired,
  isLoadingOptions: PropTypes.bool,
};

ProductSection.defaultProps = {
  errors: {},
  touched: {},
  isLoadingOptions: false,
};

export default ProductSection;
