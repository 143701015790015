import { object, array, addMethod, ref } from 'yup';
import groupBy from 'lodash/groupBy';
import userValidation from '../user.validation';
import contactValidation from '../contact.validation';

addMethod(array, 'uniqueProperty', function testUniqueProperty(
  message,
  mapper = (a) => a
) {
  return this.test('unique', message, function testUnique(list) {
    const values = list.map(mapper);

    const grouped = groupBy(values);
    const group = Object.values(grouped).find((g) => g.length > 1);

    if (group) {
      const index = values.findIndex((value) => value === group[0]);
      return this.createError({ path: `${this.path}.[${index}].email`, message });
    }

    return true;
  });
});

export default object().shape({
  user: userValidation.test(function testUserEmail(v) {
    const _ref = ref('contacts');
    const contacts = this.resolve(_ref);

    const index = contacts
      .map((contact) => contact.email)
      .findIndex((e) => e === v.email);

    if (index === -1) {
      return true;
    }

    return this.createError({
      path: `${this.path}.email`,
      message: 'emails must be different from main contact',
    });
  }),
  contacts: array(contactValidation)
    .uniqueProperty('emails in contacts must be different', (contact) => contact.email)
    .min(0)
    .test(function testContactsEmails(v) {
      const _ref = ref('user');
      const user = this.resolve(_ref);

      const index = v.map((contact) => contact.email).findIndex((e) => e === user.email);

      if (index === -1) {
        return true;
      }

      return this.createError({
        path: `${this.path}.[${index}].email`,
        message: 'emails must be different from main contact',
      });
    }),
});
