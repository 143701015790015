import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import closeIcon from 'assets/icons/close.svg';
import MobileContext from 'context/MobileContext';
import Icon from '../IconSVG';
import styles from './style.module.css';

function Banner({
  children,
  handleClose = () => {},
  isShow,
  isHideCloseButton,
  isOrange,
  isRead,
}) {
  const isMobile = useContext(MobileContext);
  const [isShowBanner, setIsShowBanner] = useState(isShow);
  const size = isMobile ? '20px' : '16px';

  const handleCloseBanner = () => {
    setIsShowBanner(false);
    handleClose();
  };

  if (!isShowBanner) return null;

  return (
    <section
      className={[
        styles.banner,
        isOrange ? styles.bannerOrange : styles.bannerWhite,
        isRead ? styles.bannerRed : styles.bannerDefault,
      ].join(' ')}
    >
      <div className={styles.bannerHeader}>
        {!isHideCloseButton && (
          <div onClick={handleCloseBanner}>
            <Icon
              src={closeIcon}
              width={size}
              height={size}
              className={styles.closeButton}
            />
          </div>
        )}
      </div>
      <main className={styles.bannerMainSection}>{children}</main>
    </section>
  );
}

Banner.propTypes = {
  children: PropTypes.node,
  handleClose: PropTypes.func,
  isShow: PropTypes.bool,
  isHideCloseButton: PropTypes.bool,
  isOrange: PropTypes.bool,
  isRead: PropTypes.bool,
};

Banner.defaultProps = {
  children: null,
  handleClose: () => {},
  isShow: false,
  isHideCloseButton: false,
  isOrange: false,
  isRead: false,
};

export default Banner;
