import React from 'react';
import PropTypes from 'prop-types';
import OpportunityDoc from 'components/Projects/Documents/MyDocuments/OpportunityDoc';
import useLoadAttachments from 'services/hooks/loadAttachments';

const OpportunityDocContainer = ({ sfId }) => {
  const { data, loading } = useLoadAttachments({
    opportunityId: sfId,
    documentCategory: 'Opportunity',
  });

  return <OpportunityDoc files={data?.downloadFilesName} load={loading} />;
};

OpportunityDocContainer.propTypes = {
  sfId: PropTypes.string.isRequired,
};

export default OpportunityDocContainer;
