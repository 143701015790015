import React from 'react';
import PropTypes from 'prop-types';
import Text from 'components/shared/Text';
import Icon from 'components/shared/IconSVG';
import { droneIcon, roofIcon } from 'assets/icons';
import Button from 'components/shared/Button';
import styles from './styles.module.css';

const OurService = ({ isHaveProject, handleCreateRoofProject, history }) => {
  const ourService = [
    {
      path: 'my-projects/roof-project',
      icon: roofIcon,
      classNameIcon: styles.roofIcon,
      name: 'Start a New Project',
      description: `We'll communicate with your insurance company throughout the roof replacement process.`,
      handleClick: handleCreateRoofProject,
      title: 'Get Started!',
    },
    {
      path: 'my-projects/order-drone',
      icon: droneIcon,
      classNameIcon: styles.roofIcon,
      name: 'Order a Drone Report',
      description: 'Our drone reports provide quick and accurate results.',
      handleClick: () => history.push('/my-projects/order-drone'),
      title: isHaveProject ? 'More services' : '',
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.itemContainer}>
        {ourService.map(
          ({ handleClick, icon, classNameIcon, name, description, path, title }) => (
            <div key={path}>
              <Text tag="h2" size="s17" weight="wb" lineHeight="h20" color="#000000">
                {title}
              </Text>
              <div
                className={
                  path === 'my-projects/roof-project'
                    ? styles.projectCenter
                    : styles.project
                }
              >
                <Icon src={icon} height="60px" width="60px" className={classNameIcon} />
                <Text
                  tag="p"
                  size="s16"
                  weight="wr"
                  lineHeight="h20"
                  color="#353535"
                  className={styles.description}
                >
                  {description}
                </Text>
                <Button size="large" handleClick={handleClick}>
                  {name}
                </Button>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

OurService.propTypes = {
  handleCreateRoofProject: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  isHaveProject: PropTypes.bool,
};

export default OurService;

OurService.defaultProps = {
  isHaveProject: false,
};
