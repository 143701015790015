import React from 'react';
import PropTypes from 'prop-types';
import Banner from 'components/shared/Banner';
import Text from 'components/shared/Text';
import styles from '../style.module.css';

const WrapperStep = ({ children, bannerMessage, isCurrentStage }) => {
  return (
    <div className={styles.projectFormWrapper}>
      {bannerMessage && isCurrentStage && (
        <div className={styles.projectFormBanner}>
          <Banner isShow isRead>
            <div>
              <Text color="#000" size="s16">
                {bannerMessage}
              </Text>
            </div>
          </Banner>
        </div>
      )}
      {children}
    </div>
  );
};

WrapperStep.propTypes = {
  children: PropTypes.node.isRequired,
  bannerMessage: PropTypes.string,
  isCurrentStage: PropTypes.bool.isRequired,
};

WrapperStep.defaultProps = {
  bannerMessage: '',
};

export default WrapperStep;
