import PropTypes from 'prop-types';
import React from 'react';
import { RemoveScroll } from 'react-remove-scroll';
import styles from './sunny.module.css';

const SunnyModal = ({ headerContent, mainContent, isSmallSize, isContract }) => {
  return (
    <RemoveScroll enabled allowPinchZoom>
      <div className={styles.modalBg}>
        <div
          className={[
            isContract ? styles.modalBoxContract : styles.modalBox,
            isSmallSize ? styles.modalBoxSmall : styles.modalBoxBig,
          ].join(' ')}
        >
          <div
            className={[
              styles.sunBox,
              isSmallSize ? styles.sunBoxSmall : styles.sunBoxBig,
            ].join(' ')}
          >
            {headerContent}
          </div>
          <div className={styles.mainContent}>{mainContent}</div>
        </div>
      </div>
    </RemoveScroll>
  );
};

export default SunnyModal;

SunnyModal.propTypes = {
  headerContent: PropTypes.node,
  mainContent: PropTypes.node,
  isSmallSize: PropTypes.bool,
  isContract: PropTypes.bool,
};

SunnyModal.defaultProps = {
  headerContent: null,
  mainContent: null,
  isSmallSize: false,
  isContract: false,
};
