import { Drone } from '../../fragments';

const droneReport = (launch, { id: droneId }, { cache, getCacheKey }) => {
  const id = getCacheKey({ __typename: 'DroneReport', sfId: droneId });
  const fragment = Drone.fragments.FullDrone;
  const report = cache.readFragment({ fragment, id });

  return report;
};

export default { resolvers: {}, Query: { droneReport } };
