import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.css';

const stylesSize = {
  small: styles.checkboxTextSmall,
  large: styles.checkboxTextLarge,
};

const Checkbox = ({
  text,
  textSize = 'small',
  isError,
  isContractorsStyle,
  isTextLeftSide,
  ...props
}) => {
  return (
    <div className={styles.container}>
      <label
        className={[styles.checkbox, isTextLeftSide ? styles.textLeftSide : ''].join(' ')}
      >
        <input type="checkbox" {...props} className={styles.input} />
        <span
          className={[
            styles.checkmark,
            isContractorsStyle ? styles.checkmarkContractors : '',
            isError ? styles.checkmarkInvalid : '',
          ].join(' ')}
        />
      </label>
      {text && (
        <span
          className={[
            styles.checkboxText,
            stylesSize[textSize],
            isTextLeftSide ? styles.textLeftSide : '',
          ].join(' ')}
        >
          {text}
        </span>
      )}
    </div>
  );
};

Checkbox.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  textSize: PropTypes.string,
  isError: PropTypes.bool,
  isTextLeftSide: PropTypes.bool,
  isContractorsStyle: PropTypes.bool,
};

Checkbox.defaultProps = {
  text: '',
  textSize: 'small',
  isError: false,
  isTextLeftSide: false,
  isContractorsStyle: false,
};

export default Checkbox;
