import gql from 'graphql-tag';

export const ORDER_DRONE_MUTATION = gql`
  mutation ORDER_DRONE_MUTATION($data: orderDroneData!) {
    orderDrone(data: $data) {
      pgId
      status
      price
      dronePackage
      scheduledDate
      sfId
      paid
    }
  }
`;
