import PropTypes from 'prop-types';
import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import EmptyPageLayout from '../../layouts/EmptyPage';
import Button from '../../components/shared/Button';
import WarrantyTile from '../../components/Warranty/WarrantyTile';
import styles from './empty.module.css';

const WarrantyPageContent = ({ warranties }) => {
  const history = useHistory();

  return (
    <EmptyPageLayout
      title="Warranty"
      rightComponent={
        <NavLink to="/warranty/new-claim">
          <Button className={styles.warrantyButton}>New Warranty Claim</Button>
        </NavLink>
      }
    >
      {warranties.map((warranty) => (
        <WarrantyTile
          key={warranty.pgId}
          handleClick={() =>
            history.push(`warranty/view/${warranty.pgId || warranty.sfId}`)
          }
          warranty={warranty}
        />
      ))}
    </EmptyPageLayout>
  );
};

export default WarrantyPageContent;

WarrantyPageContent.propTypes = {
  warranties: PropTypes.array,
};

WarrantyPageContent.defaultProps = {
  warranties: [],
};
