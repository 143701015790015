import React from 'react';
import PropTypes from 'prop-types';
import Input from 'components/shared/Input';
import PasswordInput from 'components/shared/Input/PasswordInput';
import Select from 'components/shared/Select';
import styles from './style.module.css';
import Label from './label';
import Switch from '../Switch';

const isPasswordType = (type) => type === 'password';
const isSelectType = (type) => type === 'select';
const isSwitchType = (type) => type === 'switch';

const Field = ({
  type,
  name,
  label,
  isError,
  errorMessage,
  isDisabled,
  withoutLabel,
  isMarked,
  ...props
}) => {
  const inputProps = {
    ...props,
    type,
    id: name,
    name,
    invalid: Boolean(isError),
  };

  const _renderInput = () => {
    if (isPasswordType(type))
      return <PasswordInput disabled={isDisabled} {...inputProps} />;
    if (isSelectType(type)) return <Select isDisabled={isDisabled} {...inputProps} />;
    if (isSwitchType(type)) return <Switch {...props} />;
    return <Input type={type} disabled={isDisabled} {...inputProps} />;
  };

  return (
    <div className={styles.field}>
      <Label name={name} isDisabled={isDisabled} withoutLabel={withoutLabel} isMarked={isMarked} >
        {label}
      </Label>
      {_renderInput()}
      {isError && errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
    </div>
  );
};

Field.propTypes = {
  type: PropTypes.string,
  isError: PropTypes.bool,
  label: PropTypes.string,
  errorMessage: PropTypes.string,
  name: PropTypes.string,
  isDisabled: PropTypes.bool,
  withoutLabel: PropTypes.bool,
  isMarked: PropTypes.bool,
};

Field.defaultProps = {
  type: 'text',
  isError: false,
  label: '',
  name: '',
  isDisabled: false,
  errorMessage: '',
  withoutLabel: false,
  isMarked: false,
};

export default Field;
