import React from 'react';
import PropTypes from 'prop-types';
import Field from 'components/shared/Field';
import { FormColumn as Col, FormRow as Row } from 'components/shared/Form';
import InputMask from 'react-input-mask';

const UserForm = ({
  values = {},
  handleChange,
  errors = {},
  touched = {},
  forWarrantyPage,
}) => {
  return (
    <div>
      <Row>
        <Col>
          <Field
            name="firstName"
            type="text"
            label="First Name"
            value={values.firstName}
            onChange={(e) => handleChange(e, 'firstName')}
            isError={touched?.firstName && Boolean(errors?.firstName)}
          />
        </Col>
        <Col>
          <Field
            name="lastName"
            type="text"
            label="Last Name"
            value={values.lastName}
            onChange={(e) => handleChange(e, 'lastName')}
            isError={touched?.lastName && Boolean(errors?.lastName)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            name="email"
            type="email"
            label="Email Address"
            value={values.email}
            isDisabled={forWarrantyPage}
            onChange={(e) => handleChange(e, 'email')}
            isError={touched?.email && Boolean(errors?.email)}
          />
        </Col>
        <Col>
          <InputMask
            mask="(999) 999-9999"
            value={values.phoneNumber}
            onChange={(e) =>
              handleChange(
                {
                  target: {
                    name: e.target.name,
                    value: e.target.value.replace(/\D/g, ''),
                  },
                },
                'phoneNumber'
              )
            }
          >
            {() => (
              <Field
                name="phoneNumber"
                type="tel"
                label="Phone Number"
                inputMode="tel"
                autoComplete="tel"
                isError={touched?.phoneNumber && Boolean(errors?.phoneNumber)}
              />
            )}
          </InputMask>
        </Col>
      </Row>

      <Row>
        <Col>
          <Field
            name="alternateEmail"
            type="text"
            label="Alternative Email Address (Optional)"
            value={values?.alternateEmail}
            onChange={(e) => handleChange(e, 'alternateEmail')}
            isError={touched?.alternateEmail && Boolean(errors?.alternateEmail)}
          />
        </Col>
        <Col>
          <InputMask
            mask="(999) 999-9999"
            value={values.mobilePhone}
            onChange={(e) =>
              handleChange({
                target: {
                  name: e.target.name,
                  value: e.target.value.replace(/\D/g, ''),
                },
              })
            }
          >
            {() => (
              <Field
                name="mobilePhone"
                type="tel"
                label="Mobile Number (Optional)"
                inputMode="tel"
                autoComplete="tel"
                isError={touched?.mobilePhone && Boolean(errors?.mobilePhone)}
              />
            )}
          </InputMask>
        </Col>
      </Row>

      <Row>
        <Col>
          <Field
            name="propertyAddress"
            type="text"
            label="Property Address"
            placeholder={values?.propertyAddress}
            isDisabled
          />
        </Col>
        <Col>
          <Field
            name="gateCode"
            type="text"
            label="Gate Code (Optional)"
            value={values?.gateCode}
            onChange={(e) => handleChange(e, 'gateCode')}
            isError={touched?.gateCode && Boolean(errors?.gateCode)}
          />
        </Col>
      </Row>
      <Row type="secondary">
        <Col>
          <Field
            name="city"
            type="text"
            label="City"
            placeholder={values.city}
            isDisabled
          />
        </Col>
        <Col>
          <Field
            name="state"
            type="text"
            label="State"
            placeholder={values.state}
            isDisabled
          />
        </Col>
        <Col>
          <Field
            name="zipCode"
            type="text"
            label="Zip Code"
            placeholder={values.zipCode}
            isDisabled
          />
        </Col>
      </Row>
    </div>
  );
};

UserForm.propTypes = {
  errors: PropTypes.object,
  forWarrantyPage: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  touched: PropTypes.object,
  values: PropTypes.object,
};

UserForm.defaultProps = {
  errors: {},
  forWarrantyPage: false,
  touched: {},
  values: {},
};

export default UserForm;
