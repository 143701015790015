import React from 'react';
import PropTypes from 'prop-types';
import useDownloadFile from 'services/hooks/downloadFile';
import DownloadPDFLink from 'components/shared/LinkPDF';

function Attachment({ name, contentType, contentDocumentId }) {
  const { handleDownload, isLoading, data } = useDownloadFile();
  return (
    <DownloadPDFLink
      isDownload
      loading={isLoading}
      type={contentType}
      href={
        data
          ? `data:${data?.downloadFilesData?.type};base64,${data?.downloadFilesData?.base64String}`
          : ''
      }
      fileName={data?.downloadFilesData?.name}
      handleQueryClick={() =>
        handleDownload({
          variables: { contentDocumentId },
        })
      }
    >
      {name}
    </DownloadPDFLink>
  );
}

Attachment.propTypes = {
  name: PropTypes.string.isRequired,
  contentType: PropTypes.string.isRequired,
  contentDocumentId: PropTypes.string.isRequired,
};

Attachment.defaultProps = {};

export default Attachment;
