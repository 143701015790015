import projectsResolvers from './projects';
import authResolvers from './auth';
import droneResolvers from './droneReport';
import warrantyResolvers from './warranty';

export default {
  ...projectsResolvers.Query,
  ...authResolvers.Query,
  ...droneResolvers.Query,
  ...warrantyResolvers.Query
};
