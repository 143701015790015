import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.css';

const FiledLabel = ({ isDisabled, name, children, withoutLabel, isMarked }) => {
  return (
    <label
      className={[
        styles.label,
        isMarked ? styles.markedInput : '',
        isDisabled
          ? styles.labelDisabled
          : withoutLabel
          ? styles.withoutLabel
          : styles.labelPrimary,
      ].join(' ')}
      htmlFor={name}
    >
      {children}
    </label>
  );
};

FiledLabel.propTypes = {
  isDisabled: PropTypes.bool,
  withoutLabel: PropTypes.bool,
  name: PropTypes.string.isRequired,
  children: PropTypes.string,
  isMarked: PropTypes.bool,
};

FiledLabel.defaultProps = {
  isDisabled: false,
  withoutLabel: false,
  children: '',
  isMarked: false,
};

export default FiledLabel;
