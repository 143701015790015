import React from 'react';
import PropTypes from 'prop-types';
import { RoofProjectPage } from 'pages';
import { useQuery } from '@apollo/react-hooks';
import { GET_CURRENT_USER } from 'graphql/cache';
import withLoadProjects from 'services/HOCs/withLoadProjects';
import { Redirect } from 'react-router-dom';
import Spinner from 'components/shared/Spinner';
import LayoutWithSidebar from 'layouts/LayoutWithSidebar';
import { roofProjectStatusEnum, roofProjectStages } from 'enums/roofProjectStatus';
import sortByPrimaryContacts from 'utils/sortByPrimaryContacts';

const RoofProjectContainer = (props) => {
  const { match, data } = props;

  const currentProject = data?.roofProjects?.find(({ pgId }) => pgId === match.params.id);

  const { data: getUser, loading: loadingUser } = useQuery(GET_CURRENT_USER);

  if (loadingUser)
    return (
      <LayoutWithSidebar>
        <Spinner />
      </LayoutWithSidebar>
    );

  if (!currentProject || roofProjectStages[currentProject?.stageName]?.isDisableOpen)
    return <Redirect to="/my-projects" />;

  const project = {
    ...currentProject,
    contacts: sortByPrimaryContacts(getUser),
    address: getUser?.user?.account.address,
  };

  return (
    <RoofProjectPage
      {...props}
      project={project}
      isNewProject={data?.roofProject?.stageName === roofProjectStatusEnum.draft}
    />
  );
};

RoofProjectContainer.propTypes = {
  match: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default withLoadProjects(RoofProjectContainer, { isCurrentProject: true });
