import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import { updateFieldRoofProject } from 'graphql/cache/project';
import { GIVE_FEEDBACK_CONTRACTOR } from 'graphql/mutations/projects';
import Modal from 'components/shared/modals/CompletedInstallationModal';

const InstallationPreparationStep = ({ isShow, handleClose, projectId }) => {
  const [giveFeedback, { loading: isLoading }] = useMutation(GIVE_FEEDBACK_CONTRACTOR);

  const handleGiveFeedback = ({ reviewText = null, reviewRating = null, isConfirm }) => {
    giveFeedback({
      variables: {
        reviewRating: isConfirm ? reviewRating : '',
        reviewText: isConfirm ? reviewText : '',
        projectId,
        isConfirm,
      },
      update: () => {
        updateFieldRoofProject({
          projectId,
          fieldName: 'isHOConfirmInstallationComplete',
          fieldValue: isConfirm,
        });

        handleClose();
      },
    });
  };

  return (
    <fieldset disabled={isLoading}>
      <Modal isShow={isShow} handleClick={handleGiveFeedback} />
    </fieldset>
  );
};

InstallationPreparationStep.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isShow: PropTypes.bool.isRequired,
  projectId: PropTypes.string.isRequired,
};

InstallationPreparationStep.defaultProps = {};

export default InstallationPreparationStep;
