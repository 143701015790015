import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.css';

const Option = ({ handleClick, label, isSelected, isDisabled, isError }) => {
  return (
    <div
      className={[
        styles.optionContainer,
        isSelected ? styles.optionContainerSelected : '',
        isDisabled ? styles.optionContainerDisable : '',
        isError ? styles.optionContainerInvalid : '',
      ].join(' ')}
      onClick={!isDisabled ? handleClick : () => {}}
    >
      {label}
    </div>
  );
};

Option.propTypes = {
  handleClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
};

const SelectType = ({ options, onChange, value, name, isDisabled, isError }) => {
  return (
    <div className={styles.selectTypeWrapper}>
      {options.map(({ value: optionValue, label }, index) => (
        <div className={styles.selectTypeOption} key={index}>
          <Option
            handleClick={() => onChange({ target: { name, value: optionValue } })}
            label={label}
            isSelected={optionValue === value}
            isDisabled={isDisabled}
            isError={isError}
          />
        </div>
      ))}
    </div>
  );
};

SelectType.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  isError: PropTypes.bool,
};

SelectType.defaultProps = {
  isDisabled: false,
  isError: false,
  value: '',
  options: [],
};

export default SelectType;
