import React from 'react';
import IconSVG from 'components/shared/IconSVG';
import { phoneIcon, chatIcon, emailIcon } from 'assets/icons';
import Button from 'components/shared/Button';
import { handleOpenChat } from 'utils/handleOpenChat';
import styles from './style.module.css';

const ContactUsButtons = () => {
  const handleCall = () => {
    const link = document.createElement('a');
    link.setAttribute('href', `tel:${process.env.REACT_APP_ROOFCALIM_PHONE}`);
    link.style.display = 'none';
    link.click();
  };
  const handleSendEmail = () => {
    const link = document.createElement('a');
    link.setAttribute('href', `mailto:${process.env.REACT_APP_ROOFCALIM_EMAIL}`);
    link.style.display = 'none';
    link.click();
  };

  return (
    <div className={styles.contactUsButtonWrapper}>
      <div className={styles.buttonWrapper}>
        <Button size="medium" handleClick={handleCall}>
          <div className={styles.buttonContent}>
            <IconSVG
              src={phoneIcon}
              className={styles.buttonIcon}
              width="17px"
              height="17px"
            />
            Call us
          </div>
        </Button>
      </div>

      <div className={styles.buttonWrapper}>
        <Button size="medium" handleClick={handleSendEmail}>
          <div className={styles.buttonContent}>
            <IconSVG
              src={emailIcon}
              className={styles.buttonIcon}
              width="17px"
              height="17px"
            />
            Send an email
          </div>
        </Button>
      </div>

      <div className={styles.buttonWrapper}>
        <Button size="medium" handleClick={handleOpenChat}>
          <div className={styles.buttonContent}>
            <IconSVG
              src={chatIcon}
              className={styles.buttonIcon}
              width="17px"
              height="17px"
            />
            Chat with us
          </div>
        </Button>
      </div>
    </div>
  );
};

ContactUsButtons.propTypes = {};

export default ContactUsButtons;
