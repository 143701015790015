import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import WrapperBlock from 'components/shared/WrapperBlock';
import LinkPDF from 'components/shared/LinkPDF';
import Text from 'components/shared/Text';
import Banner from 'components/shared/Banner';
import { Footer as Buttons } from 'components/Projects/RoofProjects/Footer';
import useModal from 'services/hooks/useIsShowComponent';
import Modal from 'containers/RoofProject/FeedbackContractorContainer';
import MobileContext from 'context/MobileContext';
import AppConfigContext from 'context/AppConfigContext';
import styles from '../../style.module.css';
import InstallationSection from './InstallationSection';
import SafetyInformationSection from './SafetySection';
import PaymentsInformationSection from './PaymentsSection';

const RoofInstallationPreparationStep = ({
  handleClose,
  stepData,
  isContactorMarkedComplete,
  isConfirmStage,
  delayMessage,
  projectId,
  handleNextStep,
  ...props
}) => {
  const { isShow, handleClick: handleClickModal } = useModal(isContactorMarkedComplete);
  const isMobile = useContext(MobileContext);
  const appConfig = useContext(AppConfigContext);

  const documentLink = appConfig?.readBeforeInstallLink;

  return (
    <>
      {delayMessage && (
        <div className={styles.projectFormBanner}>
          <Banner isShow isOrange={!!delayMessage}>
            <div>
              <Text color="#000" size="s14">
                {delayMessage}
              </Text>
            </div>
          </Banner>
        </div>
      )}

      <WrapperBlock
        title="Roof Installation"
        isForm
        rightComponent={
          documentLink &&
          !isMobile && <LinkPDF href={documentLink}>Read before install</LinkPDF>
        }
      >
        <InstallationSection {...stepData} isMobile={isMobile} />

        {documentLink && isMobile && (
          <LinkPDF href={documentLink}>Read before install</LinkPDF>
        )}
      </WrapperBlock>
      <WrapperBlock title="Safety Information" isForm>
        <SafetyInformationSection />
      </WrapperBlock>

      <WrapperBlock title="Payment Information" isForm>
        <PaymentsInformationSection {...props} />
      </WrapperBlock>
      <Buttons
        handleCancel={handleClose}
        isHideNext={!isConfirmStage}
        isHideSubmit
        handleNextStep={handleNextStep}
      />
      <Modal isShow={isShow} projectId={projectId} handleClose={handleClickModal} />
    </>
  );
};

RoofInstallationPreparationStep.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleNextStep: PropTypes.func.isRequired,
  documentLink: PropTypes.string,
  projectId: PropTypes.string.isRequired,
  stepData: PropTypes.object.isRequired,
  isContactorMarkedComplete: PropTypes.bool,
  isConfirmStage: PropTypes.bool,
  delayMessage: PropTypes.string,
};

RoofInstallationPreparationStep.defaultProps = {
  documentLink: '',
  isConfirmStage: '',
  isContactorMarkedComplete: false,
  delayMessage: '',
};

export default RoofInstallationPreparationStep;
