import React from 'react';
import PropTypes from 'prop-types';
import { RoofProjectPage } from 'pages';
import { useQuery } from '@apollo/react-hooks';
import { GET_CURRENT_USER } from 'graphql/cache';
import Spinner from 'components/shared/Spinner';
import sortByPrimaryContacts from 'utils/sortByPrimaryContacts';
import withLoadProjects from 'services/HOCs/withLoadProjects';
import { Redirect } from 'react-router-dom';
import checkOpenProject from 'utils/checkOpenProject';
import LayoutWithSidebar from 'layouts/LayoutWithSidebar';

const RoofProjectContainer = ({ data: projects, ...props }) => {
  const { loading, data } = useQuery(GET_CURRENT_USER);
  const isHasOpenedProject = checkOpenProject(projects?.roofProjects || []);

  if (loading)
    return (
      <LayoutWithSidebar>
        <Spinner />
      </LayoutWithSidebar>
    );

  if (isHasOpenedProject) return <Redirect to="/my-projects" />;

  const user = data?.user || {};

  const project = {
    contacts: sortByPrimaryContacts(data),
    address: user.account?.address,
    account: user.account,
  };

  return <RoofProjectPage {...props} project={project} isNewProject />;
};

RoofProjectContainer.propTypes = {
  match: PropTypes.object.isRequired,
  data: PropTypes.object,
};

RoofProjectContainer.defaultProps = {
  data: {},
};

export default withLoadProjects(RoofProjectContainer, { isCurrentProject: true });
