import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/shared/Button';
import NoteText from 'components/shared/NoteText';
import Link from 'components/shared/Link';
import WrapperBlock from 'components/shared/WrapperBlock';
import Icon from 'components/shared/IconSVG';
import { droneIcon, shapeRightIcon } from 'assets/icons';
import Text from 'components/shared/Text';
import MobileContext from 'context/MobileContext';
import { Footer as FooterButtonDesck } from 'components/Projects/RoofProjects/Footer';
import ContactForm from '../../Projects/RoofProjects/InsuranceClaimStep/ContactForm';
import PropertyDetailsForm from './PropertyDetailsForm';
import styles from './styles.module.css';

function ContactInfoStep({
  values,
  handleChange = () => {},
  handleGoPreviousStep,
  handleSubmit,
  dronePackage,
  isAvailableNext,
  handleGoNextStep,
}) {
  const isMobile = useContext(MobileContext);

  return (
    <>
      <fieldset disabled={isAvailableNext}>
        <form className={styles.projectFormWrapper} onSubmit={handleSubmit}>
          <div className={styles.selectedPackageContainer}>
            <div className={styles.iconContainer}>
              <div className={styles.shapePart} />
              <Icon src={shapeRightIcon} height="100%" className={styles.shapeIcon} />
              <Icon
                src={droneIcon}
                height="40px"
                width="40px"
                className={styles.droneIcon}
              />
            </div>
            <div className={styles.descriptionContainer}>
              <Text color="#837F7D" weight="wr" size="s16" tag="p" lineHeight="h21">
                You have selected
              </Text>
              <Text
                color="#000000"
                weight="wb"
                size="s16"
                tag="h2"
                lineHeight="h20"
                className={styles.packageType}
              >
                Drone Package {dronePackage[0]}, ${dronePackage[1]}
              </Text>
            </div>
            {!isMobile && !isAvailableNext && (
              <Button
                size="smaller"
                color="white"
                handleClick={handleGoPreviousStep}
                className={styles.buttonModify}
              >
                Modify
              </Button>
            )}
          </div>

          <div>
            <WrapperBlock title="Contact">
              {values.contacts.map((contact, index) => (
                <div key={index}>
                  <ContactForm values={contact} />
                  {values.contacts.length > 1 && index !== values.contacts.length - 1 && (
                    <div className={styles.bottomLine} />
                  )}
                </div>
              ))}
              {/* <NoteText isNoteDescription>
                You can modify your contact information or create a new contact under{' '}
                <Link bold isUnderlined href="/my-account">
                  My Account
                </Link>
              </NoteText> */}
            </WrapperBlock>

            <PropertyDetailsForm
              values={values.propertyDetails}
              handleChange={(event) => handleChange('propertyDetails', event)}
            />
          </div>
        </form>
      </fieldset>
      <FooterButtonDesck
        isHideCancel={isAvailableNext}
        handleSubmit={handleSubmit}
        handleCancel={handleGoPreviousStep}
        handleNextStep={handleGoNextStep}
        isHideNext={!isAvailableNext}
        isHideSubmit={isAvailableNext}
      />
    </>
  );
}

ContactInfoStep.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleGoNextStep: PropTypes.func.isRequired,
  handleGoPreviousStep: PropTypes.func,
  dronePackage: PropTypes.array.isRequired,
  values: PropTypes.object.isRequired,
  isAvailableNext: PropTypes.bool,
};

ContactInfoStep.defaultProps = {
  handleGoPreviousStep: () => {},
  isAvailableNext: false,
};

export default ContactInfoStep;
