import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import withLoadWarranties from 'services/HOCs/withLoadWarranties';
import { useHistory } from 'react-router-dom';
import CreateWarrantyPage from '../CreateWarrantyPage';

const ViewWarrantyPage = ({ match, data }) => {
  const history = useHistory();
  const [warranty, setWarranty] = useState(null);

  useEffect(() => {
    const currentWarranty =
      data?.getWarranties?.find(
        (x) => x.pgId === match?.params?.id || x.sfId === match?.params?.id
      ) ?? null;
    if (currentWarranty) setWarranty(currentWarranty);
    else history.goBack();
  }, [history, match, data]);

  return (
    <CreateWarrantyPage
      caseStatus={warranty}
      /* eslint-disable-next-line jsx-a11y/tabindex-no-positive */
      tabIndex={2}
    />
  );
};

export default withLoadWarranties(ViewWarrantyPage);

ViewWarrantyPage.propTypes = {
  data: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};
