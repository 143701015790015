import PropTypes from 'prop-types';
import React from 'react';
import { Formik } from 'formik';
import { string, object, array } from 'yup';
import { FormRow as Row, FormColumn as Col } from 'components/shared/Form';
import { toBase64UploadedFiles } from 'services/uploadFilesSF/toBase64';
import { Footer as Buttons } from 'components/Projects/RoofProjects/Footer';
import styles from './style.module.css';
import ProjectFormInfo from '../../shared/ProjectFormInfo';
import TextArea from '../../shared/TextArea';
import Text from '../../shared/Text';
import UploadFileComponent from '../../shared/UploadFile/container';
import Field from '../../shared/Field';

const validationSchema = object().shape({
  description: string().required().trim(),
  project: string().required().trim(),
  images: array().nullable(true),
});

const JobDetailsStep = ({
  handleCancel,
  createWarranty,
  caseDetails,
  availableProjects,
  isRequesting,
  isMobile,
}) => {
  const _handleSubmit = async (values) => {
    const images =
      values.images.length > 0 ? await toBase64UploadedFiles(values.images) : [];

    createWarranty({
      variables: {
        data: {
          opportunityPgId: values.project.split(',')[0],
          opportunitySfId: values.project.split(',')[1],
          description: values.description,
          images,
        },
      },
    });
  };

  return (
    <Formik
      initialValues={{
        description: caseDetails?.description,
        project: caseDetails?.opportunityPgId,
        images: [],
      }}
      validationSchema={validationSchema}
      onSubmit={_handleSubmit}
    >
      {({ values, handleChange, handleSubmit, errors }) => {
        return (
          <>
            <fieldset disabled={isRequesting}>
              <ProjectFormInfo title="Description" isForm>
                <Row>
                  <Col>
                    <Field
                      label="Project"
                      name="project"
                      type="select"
                      options={availableProjects}
                      onChange={handleChange}
                      value={values?.project}
                    />
                  </Col>
                </Row>
                <div className={styles.wrapper}>
                  <Text size="s16" color="#000000">
                    Please describe the roof damage
                  </Text>
                  <div className={styles.textAreaWrapper}>
                    <TextArea
                      name="description"
                      value={values.description}
                      handleChange={handleChange}
                    />
                  </div>
                  <br />
                  <Text size="s16" color="#000000" style={{ marginBottom: 10 }}>
                    Upload pictures if available
                  </Text>
                  <UploadFileComponent
                    accept=".jpeg, .png, .jpg, .pdf"
                    maxCountDocument={5}
                    uploadFiles={values.images}
                    buttonText="Upload file(s)"
                    handleChange={handleChange}
                    name="images"
                  />
                </div>
              </ProjectFormInfo>
            </fieldset>

            {!isMobile && <br />}

            <fieldset disabled={isRequesting}>
              <Buttons
                handleCancel={handleCancel}
                isHideNext
                handleSubmit={handleSubmit}
                isDisabledSubmit={!validationSchema.isValidSync(values)}
              />
            </fieldset>
          </>
        );
      }}
    </Formik>
  );
};

export default JobDetailsStep;

JobDetailsStep.propTypes = {
  availableProjects: PropTypes.arrayOf(PropTypes.object),
  caseDetails: PropTypes.object,
  createWarranty: PropTypes.func.isRequired,
  handleCancel: PropTypes.func,
  isRequesting: PropTypes.bool,
  isMobile: PropTypes.bool,
};

JobDetailsStep.defaultProps = {
  caseDetails: null,
  availableProjects: [],
  handleCancel: () => {},
  isRequesting: false,
  isMobile: false,
};
