import React from 'react';
import PropTypes from 'prop-types';
import RoofIcon from 'assets/icons/roof.svg';
import Text from '../Text';
import Icon from '../IconSVG';
import Button from '../Button';
import styles from './style.module.css';

const RoofProjectCard = ({
  status,
  stage,
  dateOfInstall,
  createdDate,
  handleClick = () => {},
  isAvailableDelete,
  handleDelete = () => {},
  isDisableOpen,
}) => {
  const iconSize = '40px';

  return (
    <div
      className={[
        styles.projectCardWrapper,
        isDisableOpen ? '' : styles.projectCardWrapperAvailable,
      ].join(' ')}
    >
      <div
        className={styles.projectCardBody}
        onClick={!isDisableOpen ? handleClick : () => {}}
      >
        <div className={styles.projectCardHeader}>
          <div className={styles.leftPart}>
            <Icon
              src={RoofIcon}
              height={iconSize}
              width={iconSize}
              className={styles.projectCardIcon}
            />
          </div>
          <div className={styles.projectCardLeftContent}>
            <div className={styles.projectCardTitle}>
              <Text color="#000" weight="wb" size="s16">
                Roof Project
              </Text>
            </div>

            {createdDate && (
              <div className={styles.centerContent}>
                <Text color="#767676" size="s14" weight="wm">
                  Date of creation: {createdDate}
                </Text>
              </div>
            )}

            <div className={[styles.status].join(' ')}>
              <span
                className={[
                  styles.indicator,
                  styles[`${status.toLowerCase()}Indicator`],
                ].join(' ')}
              />
              <Text color="#000" weight="wm" size="s14" tag="p">
                {status} {stage && `- ${stage}`}
              </Text>
            </div>
          </div>
        </div>
        {dateOfInstall && (
          <div className={styles.projectCardFooter}>
            <Text color="#000" weight="wb" size="s13" lineHeight="h14">
              Date of install: {dateOfInstall}
            </Text>
          </div>
        )}
      </div>
      {isAvailableDelete && (
        <div className={styles.projectCardButton}>
          <Button handleClick={handleDelete} size="medium" color="white">
            Delete
          </Button>
        </div>
      )}
    </div>
  );
};

RoofProjectCard.propTypes = {
  status: PropTypes.string.isRequired,
  dateOfInstall: PropTypes.string,
  handleClick: PropTypes.func,
  stage: PropTypes.string,
  createdDate: PropTypes.string,
  isAvailableDelete: PropTypes.bool,
  handleDelete: PropTypes.func,
  isDisableOpen: PropTypes.bool,
};

RoofProjectCard.defaultProps = {
  handleClick: () => {},
  stage: '',
  dateOfInstall: '',
  createdDate: '',
  isAvailableDelete: false,
  handleDelete: () => {},
  isDisableOpen: false,
};

export default RoofProjectCard;
