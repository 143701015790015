import { Route, withRouter, Redirect } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';

const PrivateRoute = ({ component: Component, allow, redirectTo, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        allow ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: redirectTo, state: rest.path }} />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  allow: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.func]),
  redirectTo: PropTypes.string,
};

PrivateRoute.defaultProps = {
  allow: null,
  component: null,
  redirectTo: '/',
};

export default withRouter(PrivateRoute);
