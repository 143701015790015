import React from 'react';
import Text from 'components/shared/Text';
import Link from 'components/shared/Link';
import styles from '../style.module.css';

const SignUpHeader = () => {
  return (
    <div className={styles.authFormHeader}>
      <Text color="#00000D" weight="wb" size="s24">
        Sign Up
      </Text>
      <Text color="#00000D" weight="wm" size="s16">
        Already have an account?{' '}
        <Link href="/sign-in" bold size="medium">
          Sign In
        </Link>
      </Text>
    </div>
  );
};

export default SignUpHeader;
