import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import TitlePage from '../../TitlePage';
import styles from './style.module.css';
import Text from '../../Text';
import notificationStatuses from '../../../../enums/notificationStatuses';

const NotificationCard = ({ createdAt, text, status }) => {
  const titlePropsEnum = {
    [notificationStatuses.notRead]: {
      weight: 'wb',
    },
    [notificationStatuses.read]: {},
  };

  return (
    <div className={styles.cardWrapper}>
      <Text {...titlePropsEnum[status]} color="#000000" lineHeight="h26" size="s14">
        {moment(createdAt).format('MM/DD/YYYY - HH:mmA')}
      </Text>
      <Text color="#00000D" lineHeight="h18" size="s14">
        {text}
      </Text>
    </div>
  );
};

const NotificationsModal = ({ notifications, isShow }) => {
  return (
    <div style={{ display: `${isShow ? 'block' : 'none'}` }}>
      <div className={[styles.wrapper, styles['slide-bottom']].join(' ')}>
        <TitlePage>Notifications</TitlePage>
        <div className={styles.notificationsContainer}>
          {notifications.length ? (
            notifications.map((notification) => (
              <NotificationCard
                key={notification.id}
                createdAt={notification.createdAt}
                text={notification.text}
                status={notification.status}
              />
            ))
          ) : (
            <>
              <br />
              <Text size="s16" color="#000000">
                No notifications yet
              </Text>
            </>
          )}
        </div>
      </div>
      <span className={[styles.triangle, styles['slide-bottom']].join(' ')} />
    </div>
  );
};

export default NotificationsModal;

NotificationsModal.propTypes = {
  isShow: PropTypes.bool,
  notifications: PropTypes.array,
};

NotificationsModal.defaultProps = {
  isShow: false,
  notifications: [],
};

NotificationCard.propTypes = {
  createdAt: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  text: PropTypes.string,
};

NotificationCard.defaultProps = {
  text: '',
};
