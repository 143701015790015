import gql from 'graphql-tag';
import { Auth } from '../../fragments/auth.fragment';

export const SIGNIN_MUTATION = gql`
  mutation SIGNIN_MUTATION($email: String!, $password: String!) {
    signInUser(email: $email, password: $password) {
      token
    }
  }
`;

export const FIRST_TIME_SIGN_IN_MUTATION = gql`
  mutation FIRST_TIME_SIGN_IN_MUTATION($id: String!) {
    firstTimeSignedIn(id: $id) {
      message
    }
  }
`;

export const SIGNOUT_MUTATION = gql`
  mutation SIGNOUT_MUTATION($token: String!) {
    signOutUser(token: $token) {
      message
    }
  }
`;

export const SIGNUP_MUTATION = gql`
  mutation SIGNUP_MUTATION($data: SignUpData!) {
    signUpUser(data: $data) {
      message
    }
  }
`;

export const UPDATE_CONTACTS_MUTATION = gql`
  mutation UPDATE_CONTACTS_MUTATION(
    $updateUserData: ChangeUserInfoData!
    $updateContactsData: [InputContactsData]
  ) {
    updateUser(data: $updateUserData) {
      ...user
    }
    updateContacts(data: $updateContactsData) {
      pgId
      sfId
      firstName
      lastName
      phone
      mobilePhone
      email
      alternateEmail
    }
  }
  ${Auth.fragments.user}
`;

export const CONFIRM_EMAIL_FROM_NEW_EMAIL_MUTATION = gql`
  mutation CONFIRM_EMAIL_FROM_NEW_EMAIL_MUTATION($activationToken: String!) {
    confirmEmailFromNewEmail(activationToken: $activationToken) {
      token
    }
  }
`;
export const CONFIRM_EMAIL_FROM_OLD_EMAIL_MUTATION = gql`
  mutation CONFIRM_EMAIL_FROM_OLD_EMAIL_MUTATION($newEmail: String!, $oldEmail: String!) {
    confirmEmailFromOldEmail(newEmail: $newEmail, oldEmail: $oldEmail) {
      token
    }
  }
`;
export const ACCOUNT_ACTIVATION_MUTATION = gql`
  mutation ACCOUNT_ACTIVATION_MUTATION(
    $password: String!
    $repeatedPassword: String!
    $activationToken: String!
  ) {
    passwordActivation(
      password: $password
      repeatedPassword: $repeatedPassword
      activationToken: $activationToken
    ) {
      message
    }
  }
`;

export const REQUEST_RESET_MUTATION = gql`
  mutation REQUEST_RESET_MUTATION($email: String!) {
    requestReset(email: $email) {
      message
    }
  }
`;
export const RESET_PASSWORD_MUTATION = gql`
  mutation RESET_PASSWORD_MUTATION(
    $password: String!
    $repeatedPassword: String!
    $resetToken: String!
  ) {
    resetPassword(
      password: $password
      repeatedPassword: $repeatedPassword
      resetToken: $resetToken
    ) {
      message
    }
  }
`;
