import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Text from 'components/shared/Text';
import Button from '../../Button';
import styles from './style.module.css';

const StatusTabsComponentMobile = ({
                                       tabList,
                                       tabListSize,
                                       currentTabIndex,
                                       activeTabIndex,
                                       isContractors,
                                       appStatus,
                                       handlePreviousClick,
                                       handleNextClick,
                                   }) => {
    const activeIndex = activeTabIndex || currentTabIndex;
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentTabIndex]);

    return (
        <Tabs
            className={styles.tabsPlanning}
            selectedIndex={currentTabIndex}
            onSelect={() => {}}
        >
            <TabList className={styles.tabListPlanning}>
                {isContractors && [
                    <Button
                        handleClick={handlePreviousClick}
                        className={`${styles.navigationButton} ${styles.prevButton}`}
                        disabled={activeIndex === 0 ? 'disabled' : ''}
                    />,
                    <Button
                        handleClick={handleNextClick}
                        className={`${styles.navigationButton} ${styles.nextButton}`}
                        disabled={activeIndex === tabListSize ? 'disabled' : ''}
                    />,
                ]}
                {tabList.map(({ title, isClosed, isTabCompleted }, index) => {
                    return (
                        <Tab
                            key={`${index}tab`}
                            className={[
                                styles.tabPlanning,
                                index <= activeIndex
                                    ? isContractors
                                    ? isTabCompleted === 100
                                        ? styles.finishedTabPlanning
                                        : styles.defaultTabPlaning
                                    : styles.finishedTabPlanning
                                    : styles.defaultTabPlaning,
                            ].join(' ')}
                            selectedClassName={!isClosed ? styles.selectedTabPlanning : null}
                            disabledClassName={styles.disabledTabPlaning}
                            disabled={index > activeIndex}
                        >
                            {currentTabIndex === index && (
                                <Text
                                    color="#000"
                                    weight="wb"
                                    size="s16"
                                    tag="h2"
                                    lineHeight="h16"
                                    className={styles.droneReportMobileTitle}
                                >
                                    {title}
                                </Text>
                            )}
                        </Tab>
                    );
                })}
            </TabList>

            {tabList.map(({ children }, index) => (
                <TabPanel
                    key={`${index}tabPanel`}
                    className={styles.tabPanelPlanning}
                    selectedClassName={styles.selectedTabPanelPlanning}
                >
                    {isContractors ? (
                        <fieldset disabled={appStatus !== 'Draft'}>{children}</fieldset>
                    ) : (
                        children
                    )}
                </TabPanel>
            ))}
        </Tabs>
    );
};

StatusTabsComponentMobile.propTypes = {
    tabList: PropTypes.array.isRequired,
    currentTabIndex: PropTypes.number.isRequired,
    activeTabIndex: PropTypes.number,
    isContractors: PropTypes.bool,
    handlePreviousClick: PropTypes.func,
    handleNextClick: PropTypes.func,
    tabListSize: PropTypes.number,
    appStatus: PropTypes.string,
};
StatusTabsComponentMobile.defaultProps = {
    activeTabIndex: null,
    isContractors: false,
    handlePreviousClick: () => {},
    handleNextClick: () => {},
    tabListSize: 4,
    appStatus: 'Draft',
};

export default StatusTabsComponentMobile;
