import React from 'react';
import PropTypes from 'prop-types';
import Field from 'components/shared/Field';
import { FormRow as Row, FormColumn as Col } from 'components/shared/Form';
import InputMask from 'react-input-mask';
import TextArea from 'components/shared/TextArea';
import Button from 'components/shared/Button';
import styles from './style.module.css';

function ContactForm({ values, handleChange, errors, touched, handleSubmit }) {
  return (
    <div className={styles.formContainer}>
      <Row type="single">
        <Col>
          <Field
            name="fullName"
            type="text"
            isDisabled
            label="Full Name"
            value={values.fullName}
            onChange={handleChange}
          />
        </Col>
      </Row>

      <Row type="single">
        <Col>
          <Field
            name="email"
            type="email"
            label="Email"
            value={values.email}
            onChange={handleChange}
            isError={touched?.email && Boolean(errors?.email)}
          />
        </Col>
      </Row>
      <Row type="single">
        <Col>
          <InputMask
            mask="(999) 999-9999"
            value={values.phoneNumber}
            onChange={(e) =>
              handleChange({
                target: {
                  name: e.target.name,
                  value: e.target.value.replace(/\D/g, ''),
                },
              })
            }
          >
            {() => (
              <Field
                name="phoneNumber"
                type="tel"
                label="Phone Number"
                inputMode="tel"
                autoComplete="tel"
                isError={touched?.phoneNumber && Boolean(errors?.phoneNumber)}
              />
            )}
          </InputMask>
        </Col>
      </Row>
      <Row type="single">
        <Col>
          <Field
            name="subject"
            isDisabled={false}
            type="text"
            label="Subject"
            value={values.subject}
            onChange={handleChange}
            isError={touched?.subject && Boolean(errors?.subject)}
          />
        </Col>
      </Row>
      <div className={styles.textareaContainer}>
        <TextArea
          name="message"
          label="Message"
          placeholder="Your message.."
          handleChange={handleChange}
          value={values.message}
          isError={touched?.message && Boolean(errors?.message)}
          differentMaxHeight
        />
      </div>
      <Button size="medium" className={styles.submitButton} handleClick={handleSubmit}>
        Submit
      </Button>
    </div>
  );
}

ContactForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object,
  touched: PropTypes.object,
};

ContactForm.defaultProps = {
  errors: {},
  touched: {},
};

export default ContactForm;
