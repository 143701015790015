import React from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useHistory } from 'react-router-dom';
import styles from './style.module.css';

const TabsComponent = ({
  tabList = [],
  currentTabIndex = 0,
  handleClick,
  projectPgId,
}) => {
  const history = useHistory();

  return (
    <Tabs className={styles.tabs} selectedIndex={currentTabIndex} onSelect={() => {}}>
      <TabList className={styles.tabList}>
        {tabList.map(({ title: tabTitle, isDisabled }, index) => (
          <Tab
            key={`${index}tab`}
            className={styles.tab}
            selectedClassName={styles.selectedTab}
            disabled={isDisabled}
            disabledClassName={styles.disabledTab}
            onClick={() => {
              if (isDisabled) return;
              if (
                tabTitle === 'Roof Project' &&
                history.location.pathname.includes('roof-project') &&
                projectPgId
              ) {
                history.push(`/my-projects/roofs/${projectPgId}`);
                handleClick(index);
              } else handleClick(index);
            }}
          >
            {tabTitle}
          </Tab>
        ))}
      </TabList>

      {tabList.map(({ children }, index) => (
        <TabPanel
          key={`${index}tabPanel`}
          className={styles.tabPanel}
          selectedClassName={styles.selectedTabPanel}
        >
          {children}
        </TabPanel>
      ))}
    </Tabs>
  );
};

TabsComponent.propTypes = {
  tabList: PropTypes.array.isRequired,
  currentTabIndex: PropTypes.number,
  handleClick: PropTypes.func,
  projectPgId: PropTypes.string,
};

TabsComponent.defaultProps = {
  currentTabIndex: 0,
  handleClick: () => {},
  projectPgId: '',
};

export default TabsComponent;
