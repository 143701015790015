import { useMutation } from '@apollo/react-hooks';
import { useState } from 'react';
import FollowAnalytics from 'followanalytics';
import ReactGa from 'react-ga';
import { SIGNIN_MUTATION } from '../../graphql/mutations/auth';
import auth from '../../utils/auth';
import api from '../api';
import { formatAuthMessage } from '../../utils/graphQLErrorFormatters';


export function useAuth() {
  const [signInUser] = useMutation(SIGNIN_MUTATION);

  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      await signInUser({
        variables: { email: values.email, password: values.password },
        update: (cache, { data }) => {
          auth.setToken(data.signInUser.token, values.isKeepLogged);
          localStorage.setItem('logged', 'true');
          FollowAnalytics.setUserId(values.email);
          ReactGa.set({ userEmail: values.email });
          api.setAuthTokenToHeader(data.signInUser.token);
          // Do not use history (react-router) here!
          window.location.replace('/');
        },
      });
    } catch (e) {
      setLoading(false);
      const errorMessage = formatAuthMessage(e?.message);
      setError(errorMessage);
    }
  };
  return { isLoading, error, handleSubmit };
}
