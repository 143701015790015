import React from 'react';
import PropTypes from 'prop-types';
import MyDocuments from './MyDocuments';
// import PowerForms from './PowerForms';
import styles from '../style.module.css';

function DocumentsTabContent({ project }) {
  return (
    <div className={styles.projectTabWrapper}>
      {/* <PowerForms /> */}
      <MyDocuments project={project} />
    </div>
  );
}

DocumentsTabContent.propTypes = {
  project: PropTypes.object.isRequired,
};

DocumentsTabContent.defaultProps = {};

export default DocumentsTabContent;
