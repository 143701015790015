import React, { useContext, useEffect } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { insuranceClaimValidationSchema } from 'validation';
import { useMutation, useQuery } from '@apollo/react-hooks';
import InsuranceClaimStep from 'components/Projects/RoofProjects/InsuranceClaimStep';
import {
  CREATE_ROOF_PROJECT,
  SAVE_AS_DRAFT_ROOF_PROJECT,
} from 'graphql/mutations/projects';
import AppConfigContext from 'context/AppConfigContext';
import { ROOF_PROJECT_OPTIONS } from 'graphql/queries/projects';
import { updateRoofProjectList, removeRoofProject } from 'graphql/cache/project';
import { FooterButtons as Buttons } from 'components/Projects/RoofProjects/InsuranceClaimStep/FooterButtons';
import AcknowledgmentModal from 'components/shared/modals/AcknowledgmentModal';
import Spinner from 'components/shared/Spinner';
import Text from 'components/shared/Text';
import Button from 'components/shared/Button';
import styles from 'components/Projects/style.module.css';
import ReactGa from 'react-ga';

const convertValues = ({
  isIncludeMortgageCompany,
  isGateCodeRequired,
  isRequiredOtherInsurance,
  deductibleAmount,
  ...values
}) => {
  return { ...values, deductibleAmount: deductibleAmount || null };
};

const InsuranceClaimStepContainer = ({
  values: initialValues,
  isNewProject,
  handleClose,
  handleNextStep,
  contract,
  projectId,
  projectSfId,
  setIsNavigationBlocked,
  isOpenConfirmChangeModal,
  handleClickConfirmChangeModal,
  path,
  setPath,
  setCurrentTabIndex,
  ...props
}) => {
  const history = useHistory();
  const appConfig = useContext(AppConfigContext);

  const { loading: isLoadingOptions, data } = useQuery(ROOF_PROJECT_OPTIONS);
  const [createRoofProject, { loading: isRequestCreateProject }] = useMutation(
    CREATE_ROOF_PROJECT
  );
  const [saveAsDraftRoofProject, { loading: isRequestSaveDraft }] = useMutation(
    SAVE_AS_DRAFT_ROOF_PROJECT
  );

  function blockHistory() {
    history.block(({ pathname }) => {
      setPath(pathname);

      handleClickConfirmChangeModal();

      return false;
    });
  }

  function unBlockHistory() {
    history.block(() => { });
  }

  useEffect(() => {
    history.listen(() => {
      unBlockHistory();
    });
  }, [history]);

  const options = {
    mortgageCompanies: data?.mortgageCompanies || [],
    insuranceCompanies: data?.insuranceCompanies || [],
    roofTypes: data?.roofTypes || [],
  };

  const isRelatedContract = !!contract?.contractSignedDate;
  const isDisableForm = !isNewProject || isRelatedContract || isRequestCreateProject;

  const contractLink = (opportunitySfId, existingShingleType) =>
    `${appConfig?.contractUrl}&ID=${opportunitySfId}&RURL=${window.location.href}
    `;

  const handleSubmit = (stepValues) => {
    ReactGa.event({
      category: "Roof Project",
      action: "Pressed the Generate My Contract button from the Insurance Information tab",
      label: "Generate My Contract"
    });
    unBlockHistory();
    const convertedValues = convertValues(stepValues);
    createRoofProject({
      variables: { data: convertedValues, draftOpportunityId: projectId },
      update: (cache, { data: { createRoofProject: project } }) => {
        updateRoofProjectList({ data: project });

        if (projectId) removeRoofProject(projectId);
        return history.replace(`/my-projects/roofs/${project.pgId}`);
      },
    });
  };

  const handleSaveDraftStage = ({ isRequiredOtherInsurance, ...draftValues }) => {
    ReactGa.event({
      category: "Roof Project",
      action: "Pressed the Save as Draft button from the Insurance Information tab",
      label: "Save as Draft"
    });
    unBlockHistory();
    setIsNavigationBlocked(false);
    saveAsDraftRoofProject({
      variables: { data: draftValues, projectId },
      update: (cache, { data: { saveDraftRoofProject } }) => {
        updateRoofProjectList({ data: saveDraftRoofProject, projectId });
        handleClickConfirmChangeModal();
        if (path.startsWith('index')) {
          setCurrentTabIndex(Number(path.split(':')[1]));
          return null;
        }
        return history.push(path || `/`);
      },
    });
  };

  if (isRequestCreateProject) return <Spinner />;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={insuranceClaimValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ handleChange, ...formikProps }) => {
        const _handleChange = (event) => {
          handleChange(event);
          setIsNavigationBlocked(true);
          blockHistory();
          formikProps.setTouched({});
        };

        const handleChangeInsuranceCompany = ({ target }) => {
          const isOtherInsurance = target.value.label.includes('Other');
          _handleChange({
            target: { name: 'insuranceCompanyId', value: target.value.value },
          });

          if (isOtherInsurance)
            return handleChange({
              target: { name: 'isRequiredOtherInsurance', value: true },
            });

          handleChange({
            target: { name: 'isRequiredOtherInsurance', value: false },
          });
          return handleChange({
            target: { name: 'otherInsurance', value: null },
          });
        };

        return (
          <>
            <form
              onSubmit={formikProps.handleSubmit}
              className={styles.projectFormWrapper}
            >
              <fieldset disabled={isDisableForm}>
                <InsuranceClaimStep
                  {...formikProps}
                  {...props}
                  contractProps={contract}
                  isDisableForm={isDisableForm}
                  isNewProject={isNewProject}
                  projectId={projectSfId}
                  isRelatedContract={isRelatedContract}
                  isLoadingOptions={isLoadingOptions}
                  handleChange={_handleChange}
                  handleChangeInsuranceCompany={handleChangeInsuranceCompany}
                  options={options}
                  contractLink={contractLink(
                    projectSfId,
                    initialValues.existingShingleType
                  )}
                  bannerFrame={
                    appConfig?.insuranceBannerText ? (
                      <Text color="#837F7D" size="s13">
                        {appConfig?.insuranceBannerText}
                      </Text>
                    ) : null
                  }
                />
              </fieldset>
              <fieldset
                disabled={
                  !isRelatedContract && (isRequestCreateProject || isRequestSaveDraft)
                }
              >
                <Buttons
                  handleCancel={() => {
                    if (
                      formikProps.values.isRequiredOtherInsurance &&
                      !formikProps.values.otherInsurance
                    ) {
                      formikProps.setTouched({ otherInsurance: true });
                      return formikProps.setFieldError(
                        'otherInsurance',
                        'field is required'
                      );
                    }

                    return handleSaveDraftStage(formikProps.values);
                  }}
                  isHideCancel={!isNewProject || isRelatedContract}
                  handleNextStep={handleNextStep}
                  isAvailableNext={isRelatedContract}
                  handleSubmit={formikProps.handleSubmit}
                  isDisabled={!isNewProject}
                />
              </fieldset>

              {!isRelatedContract && (
                <fieldset disabled={isRequestSaveDraft}>
                  <AcknowledgmentModal
                    isSmallSize
                    handleClose={() => {
                      handleClickConfirmChangeModal();
                      setIsNavigationBlocked(true);
                    }}
                    footer={
                      <div>
                        <Button
                          size="medium"
                          handleClick={() => {
                            handleSaveDraftStage(formikProps.values);
                          }}
                        >
                          Save as Draft
                        </Button>
                        <span className={styles.modalButtonsLine} />
                        <Button
                          size="medium"
                          color="white"
                          handleClick={() => {
                            unBlockHistory();

                            handleClickConfirmChangeModal();

                            if (path === 'reload') {
                              history.go();
                            } else if (path.startsWith('index')) {
                              setCurrentTabIndex(Number(path.split(':')[1]));
                              setIsNavigationBlocked(false);
                            } else {
                              history.push(path);
                            }
                          }}
                        >
                          Discard
                        </Button>
                      </div>
                    }
                    title="What would you like to do?"
                    description='You have unsaved changes on your Roof Project page. 
              You can save information that you have entered by clicking on "Save As Draft" 
              or continue and discard any unsaved changes.'
                    isShow={isOpenConfirmChangeModal}
                  />
                </fieldset>
              )}
            </form>
          </>
        );
      }}
    </Formik>
  );
};

InsuranceClaimStepContainer.propTypes = {
  values: PropTypes.object.isRequired,
  isNewProject: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleNextStep: PropTypes.func.isRequired,
  contract: PropTypes.object,
  projectId: PropTypes.string,
  projectSfId: PropTypes.string,
  setIsNavigationBlocked: PropTypes.func.isRequired,
  isOpenConfirmChangeModal: PropTypes.bool.isRequired,
  handleClickConfirmChangeModal: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  setPath: PropTypes.func.isRequired,
  setCurrentTabIndex: PropTypes.func.isRequired,
  contacts: PropTypes.array.isRequired,
};

InsuranceClaimStepContainer.defaultProps = {
  contract: {},
  projectId: '',
  projectSfId: '',
};

export default InsuranceClaimStepContainer;
