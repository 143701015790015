import gql from 'graphql-tag';

export const GET_HOMEOWNER_CONFIG = gql`
  query getHomeownerConfig {
    appConfigHomeowner {
      homepageBannerTitle
      homepageBannerBody
      homepageBannerButtonText
      homepageBannerUrl
      bannerInsuranceStepUrl
      bannerDashboardUrl
      firstStepAcknowledgmentTitle
      firstStepAcknowledgmentBody
      linkProductDetailsFile
      readBeforeInstallLink
      warrantyPolicyLink
      insurancePolicyLink
      readBeforeRepairLink
      warrantyPageText
      hoaFormsLink
      welcomeBannerText
      welcomeBannerButtonText
      planningSubmitButtonText
      planningSubmitBodyText
      mortgageCompanyHint
      insuranceBannerText
      contractUrl
      paymentUrl
      urlAppStore
      urlGooglePlay
    }
  }
`;

export const GET_STORE_URLS = gql`
  query storeURLS {
    storeURLS {
      urlAppStore
      urlGooglePlay
    }
  }
`;
