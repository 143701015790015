import React from 'react';
import PropTypes from 'prop-types';
import phoneNumberFormatWithCode from 'utils/phoneNumberFormatter';
import RowText from 'components/Projects/RowText';
import NoteText from 'components/shared/NoteText';
import Link from 'components/shared/Link';
import { Footer as Buttons } from 'components/Projects/RoofProjects/Footer';
import ProjectFormInfo from '../../shared/ProjectFormInfo';
import styles from './style.module.css';

function ContactForm({ contacts = [], handleCancel, handleGoNext, isMobile }) {
  return (
    <>
      {/* {isMobile && (
        <div className={styles.noteWrapper}>
          <NoteText isNoteDescription isMobile={isMobile}>
            You can modify your contact information or create a new contact under{' '}
            <Link bold isUnderlined href="/my-account" size="s13Link">
              My Account
            </Link>
          </NoteText>
        </div>
      )} */}

      <ProjectFormInfo mInfo title="Account Details">
        {contacts.map((contact, index) => (
          <div key={index}>
            <div className={styles.contactInfoWrapper}>
              <div className={styles.contactInfoRow}>
                <RowText
                  value={`${contact.firstName}  ${contact.lastName}`}
                  name="Full Name"
                  isValueBold={isMobile}
                />
              </div>
              <div className={styles.contactInfoRow}>
                <RowText
                  value={contact.email}
                  name="Email Address"
                  isValueBold={isMobile}
                />
              </div>
              <div className={styles.contactInfoRow}>
                <RowText
                  value={contact.alternativeEmail}
                  name="Alternative Email Address"
                  isValueBold={isMobile}
                />
              </div>
              <div className={styles.contactInfoRow}>
                <RowText
                  value={phoneNumberFormatWithCode(contact.phone)}
                  name="Phone Number"
                  isValueBold={isMobile}
                />
              </div>
              <div className={styles.contactInfoRow}>
                <RowText
                  value={phoneNumberFormatWithCode(contact.mobilePhone)}
                  name="Mobile Number"
                  isValueBold={isMobile}
                />
              </div>
            </div>
            {contacts.length > 1 && index !== contacts.length - 1 && (
              <div className={styles.bottomLine} />
            )}
          </div>
        ))}
{/* 
        {!isMobile && (
          <NoteText isNoteDescription>
            You can modify your contact information or create a new contact under{' '}
            <Link bold isUnderlined href="/my-account">
              My Account
            </Link>
          </NoteText>
        )} */}
      </ProjectFormInfo>
      <Buttons
        handleCancel={handleCancel}
        handleNextStep={handleGoNext}
        isHideCancel
        isHideSubmit
      />
    </>
  );
}

ContactForm.propTypes = {
  contacts: PropTypes.array.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleGoNext: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
};

ContactForm.defaultProps = {
  isMobile: false,
};

export default ContactForm;
