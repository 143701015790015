import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/shared/IconSVG';
import phoneIcon from 'assets/icons/phone.svg';
import styles from './style.module.css';
import { defaultIconProps } from '../IconSVG';

const PhoneLink = ({ phoneNumber, text, isBold }) => {
  if (!text || !phoneNumber) return null;
  return (
    <div className={styles.linkWrapper}>
      <Icon
        src={phoneIcon}
        width="15px"
        height="15px"
        className={isBold ? defaultIconProps.className : styles.emailIcon}
      />
      <a
        href={`tel:${phoneNumber}`}
        className={[
          isBold ? styles.primaryLinkBold : '',
          styles.emailLink,
          styles.primaryLink,
        ].join(' ')}
      >
        {text || phoneNumber}
      </a>
    </div>
  );
};

PhoneLink.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
  text: PropTypes.string,
  isBold: PropTypes.bool,
};

PhoneLink.defaultProps = {
  text: '',
  isBold: false,
};

export default PhoneLink;
