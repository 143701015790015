import React from 'react';
import PropTypes from 'prop-types';
import {
  fbIcon,
  instagramIcon,
  linkedinIcon,
  twitterIcon,
  youtubeIcon,
  chevronIcon,
} from 'assets/icons';

import logoRoof from 'assets/logos/RoofClaim-Transparent-logo.webp';
import Icon from 'components/shared/IconSVG';
import Text from 'components/shared/Text';
import { useQuery } from '@apollo/react-hooks';
import { GET_USER } from 'graphql/queries/auth';
import styles from './style.module.css';

const Footer = ({ loading }) => {
  const { data } = useQuery(GET_USER);

  const usefulLinks = [
    { name: 'FAQs', url: '/faq' },
    { name: 'About Us', url: 'https://www.roofclaim.com/about-us/' },
    { name: 'Contact Us', url: '/contact-us' },
  ];

  const mediaLinks = [
    {
      icon: fbIcon,
      url: 'https://www.facebook.com/RoofClaimUS/',
    },
    {
      icon: twitterIcon,
      url: 'https://twitter.com/RoofclaimUS',
    },
    {
      icon: youtubeIcon,
      url: 'https://www.youtube.com/channel/UCbIWrNbRjbWFm0GFNN2f01Q',
    },
    {
      icon: linkedinIcon,
      url: 'https://www.linkedin.com/company/roofclaim-com/',
    },
    {
      icon: instagramIcon,
      url: 'https://www.instagram.com/roofclaimus/',
    },
  ];

  return (
    <div className={styles.container}>
      {/* {!loading && <Chat user={data?.getUser} />} */}
      <Icon src={logoRoof} width="223px" className={styles.logo} />
      <div className={styles.usefulLinksContainer}>
          <Text
            tag="h2"
            size="s14"
            weight="wb"
            lineHeight="h16"
            color="#FFFFFF"
            className={styles.linksTitle}
          >
            Useful Links
          </Text>
          {usefulLinks.map(({ url, name }) => (
            <a
              href={url}
              key={name}
              target={name === 'Contact Us' ? '' : '_blank'}
              rel="noreferrer noopener"
            >
              <Text
                tag="h2"
                size="s14"
                weight="wr"
                lineHeight="h16"
                color="#FFFFFF"
                className={styles.usefulLink}
              >
                {name}
              </Text>
            </a>
          ))}
        </div>
      <div className={styles.mediaLinksContainer}>
          <Text
            tag="h2"
            size="s14"
            weight="wb"
            lineHeight="h16"
            color="#FFFFFF"
            className={styles.linksTitle}
          >
            Follow Us
          </Text>
          <div className={styles.mediaIconContainer}>
            {mediaLinks.map(({ url, icon }) => (
              <a href={url} key={url} target="_blank" rel="noreferrer noopener">
                <Icon
                  src={icon}
                  height="20px"
                  width="20px"
                  className={styles.mediaLink}
                />
              </a>
            ))}
          </div>
        </div>

      <div
        onClick={() =>
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          })
        }
        className={styles.anchor}
      >
        <Icon
          src={chevronIcon}
          height="20px"
          width="20px"
          className={styles.anchorIcon}
        />
      </div>
    </div>
  );
};

Footer.propTypes = {
  loading: PropTypes.bool,
};

Footer.defaultProps = {
  loading: false,
};

export default Footer;
