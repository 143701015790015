import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/shared/Button';
import styles from './style.module.css';
import { MobileButton, SubmitButton } from './buttons';

export const CenterButton = ({
  children,
  size = 'large',
  handleClick,
  isDisabled,
  bottom,
  id,
}) => {
  return (
    <div className={[bottom ? styles.projectFormFooterBottom : styles.projectFormFooter]}>
      <Button
        size={size}
        type="submit"
        handleClick={handleClick}
        disabled={isDisabled}
        className={styles.footerButtonBig}
        id={id}
      >
        {children}
      </Button>
    </div>
  );
};

CenterButton.propTypes = {
  children: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  bottom: PropTypes.bool,
  size: PropTypes.string,
  id: PropTypes.string,
};

CenterButton.defaultProps = {
  isDisabled: false,
  size: 'large',
  bottom: false,
};
export const FooterButton = ({ children, size = 'large', handleClick, isDisabled }) => {
  return (
    <div className={styles.footerTabWrapperMob}>
      <div className={styles.footerTabMob}>
        <Button
          size={size}
          type="submit"
          handleClick={handleClick}
          disabled={isDisabled}
          className={styles.footerButtonBig}
        >
          {children}
        </Button>
      </div>
    </div>
  );
};

FooterButton.propTypes = {
  children: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  size: PropTypes.string,
  id: PropTypes.string,
};

FooterButton.defaultProps = {
  isDisabled: false,
  size: 'large',
};

export const Footer = ({
  handleCancel,
  isHideCancel,
  handleNextStep,
  submitButtonText,
  handleSubmit,
  isHideSubmit,
  isHideNext,
  isDisabledSubmit,
  ...props
}) => {
  return (
    <div className={styles.footerTabWrapper}>
      <div className={styles.footerTab}>
        {!isHideSubmit && (
          <SubmitButton
            {...props}
            handleSubmit={handleSubmit}
            disabled={isDisabledSubmit}
            className={styles.footerTabButtonSubmit}
          >
            {submitButtonText}
          </SubmitButton>
        )}
        <div className={styles.footerTabBottom}>
          <div>{!isHideCancel && <MobileButton onClick={handleCancel} />}</div>

          {!isHideCancel && !isHideNext && (
            <div className={styles.arrowButtonDesktopLine} />
          )}

          {!isHideNext && (
            <div>
              <MobileButton isNext onClick={handleNextStep} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = {
  handleCancel: PropTypes.func,
  handleNextStep: PropTypes.func,
  isHideCancel: PropTypes.bool,
  isDisabledSubmit: PropTypes.bool,
  isHideNext: PropTypes.bool,
  submitButtonText: PropTypes.string,
  handleSubmit: PropTypes.func,
  isHideSubmit: PropTypes.bool,
};

Footer.defaultProps = {
  handleCancel: () => {},
  handleSubmit: () => {},
  handleNextStep: () => {},
  isHideCancel: false,
  isHideSubmit: false,
  isHideNext: false,
  isDisabledSubmit: false,
  submitButtonText: 'Submit',
};

export const CloseButton = ({ handleCancel, closeButtonText = 'Back' }) => {
  return (
    <div className={styles.footerTabWrapper}>
      <div className={styles.footerTab}>
        <MobileButton onClick={handleCancel}>{closeButtonText}</MobileButton>
      </div>
    </div>
  );
};

CloseButton.propTypes = {
  handleCancel: PropTypes.func,
  closeButtonText: PropTypes.string,
};

CloseButton.defaultProps = {
  closeButtonText: 'Back',
  handleCancel: () => {},
};
