import { object, array, boolean, string } from 'yup';

export default ({
  filesHOApproval = 1,
  maxCountInsuranceDocuments = 1,
  isReinspectionDate = false,
}) => {
  const reinspectionDate = isReinspectionDate
    ? string().required().nullable(false)
    : string().nullable(true);
  return object().shape({
    productId: string().required(),
    hoaApproval: string().required(),
    insuranceAttachments: array().max(maxCountInsuranceDocuments).min(1).required(),
    filesHOApproval: array().when('hoaApproval', {
      is: true,
      then: array().max(filesHOApproval).min(1).required(),
    }),
    insuranceInspectionDate: string().required(),
    reinspectionDate,
  });
};
