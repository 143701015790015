import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

import { droneReportValidationSchema } from 'validation';
import ContactInfoStep from 'components/DroneReport/ContactInfo';
import { useMutation } from '@apollo/react-hooks';
import { ORDER_DRONE_MUTATION } from 'graphql/mutations/droneReport';
import { GET_DRONE } from 'graphql/queries/droneReport';
import ReactGa from 'react-ga';

const DroneReportContactInfoStep = ({
  values,
  handleGoNextStep,
  handleGoPreviousStep,
  dronePackage,
  handleRefetch,
  ...props
}) => {
  const [orderDrone] = useMutation(ORDER_DRONE_MUTATION);

  const handleSubmit = async (value) => {
    ReactGa.event({
      category: "Drone Inspection",
      action: "Pressed the Submit button from the Contact Information tab",
      label: "Submit"
    });
    handleGoNextStep();
    await orderDrone({
      variables: {
        data: {
          price: dronePackage[1],
          serviceType: 'Drone Report',
          dronePackage: dronePackage[0],
          isRoofTarped: value.propertyDetails.isRoofTarped,
        },
      },

      update: (cache, { data }) => {
        handleRefetch();

        const droneRep = cache.readQuery({
          query: GET_DRONE,
        });

        const filteredData = droneRep.droneReports.filter(
          (item) => item.pgId !== data.orderDrone.pgId
        );

        cache.writeData({
          query: GET_DRONE,
          data: {
            droneReports: [...filteredData, data.orderDrone],
          },
        });
      },
    });
  };

  return (
    <Formik
      initialValues={values}
      validationSchema={droneReportValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ handleChange, ...formikProps }) => {
        const _handleChange = (name, { target }) => {
          handleChange({
            target: { name: `${name}.${target.name}`, value: target.value },
          });
        };

        return (
          <ContactInfoStep
            {...props}
            {...formikProps}
            handleChange={_handleChange}
            handleGoPreviousStep={handleGoPreviousStep}
            dronePackage={dronePackage}
            handleGoNextStep={handleGoNextStep}
          />
        );
      }}
    </Formik>
  );
};
DroneReportContactInfoStep.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleGoNextStep: PropTypes.func,
  handleGoPreviousStep: PropTypes.func,
  values: PropTypes.object.isRequired,

  dronePackage: PropTypes.array.isRequired,
  handleRefetch: PropTypes.func,
};

export default DroneReportContactInfoStep;

DroneReportContactInfoStep.defaultProps = {
  handleGoNextStep: () => { },
  handleRefetch: () => { },
  handleGoPreviousStep: () => { },
};
