import React from 'react';
import PropTypes from 'prop-types';
import WrapperBlock from 'components/shared/WrapperBlock';
import Checkbox from 'components/shared/CheckBox';
import Text from 'components/shared/Text';
import { flagIcon } from 'assets/icons';
import Icon from 'components/shared/IconSVG';
import styles from '../styles.module.css';

function PropertyDetailsForm({ values = {}, handleChange }) {
  const address = values.address || values.propertyAddress;
  const fixedAddress = address.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  return (
    <WrapperBlock title="Property Details" isForm>
      <div className={styles.adressContainer}>
        <Icon src={flagIcon} width="15px" height="auto" className={styles.flagIcon} />
        <Text color="#000" size="s13" weight="wb">
          {fixedAddress}
        </Text>
      </div>
      <div>
        <Text color="#000" tag="p" weight="wb" size="s14">
          Is the roof tarped?
        </Text>
        <Text color="#837F7D" size="s13" weight="wm">
          Note: If your roof is tarped, it will need to be removed for a thorough damage
          assessment.
        </Text>
        <div className={styles.projectFormColumn}>
          <div className={styles.projectFormCheckbox}>
            <Checkbox
              text="Yes"
              name="isRoofTarped"
              checked={values.isRoofTarped}
              onChange={() =>
                handleChange({ target: { name: 'isRoofTarped', value: true } })
              }
            />
          </div>
          <div>
            <Checkbox
              text="No"
              name="isRoofTarped"
              checked={!values.isRoofTarped}
              onChange={() =>
                handleChange({ target: { name: 'isRoofTarped', value: false } })
              }
            />
          </div>
        </div>
      </div>
    </WrapperBlock>
  );
}

PropertyDetailsForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

PropertyDetailsForm.defaultProps = {};

export default PropertyDetailsForm;
