import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.css';

const colorStyles = {
  red: styles.noteScaleRed,
  orange: styles.noteScaleOrange,
  yellow: styles.noteScaleYellow,
  greenLight: styles.noteScaleGreenLight,
  green: styles.noteScaleGreen,
};

const NoteScale = ({ handleClick, value, name, isSelected, color }) => {
  return (
    <div
      className={[
        styles.noteScaleWrapper,
        isSelected ? styles.noteScaleSelected : styles.noteScale,
        colorStyles[color],
      ].join(' ')}
      onClick={() => handleClick(value)}
    >
      {name}
    </div>
  );
};

NoteScale.propTypes = {
  handleClick: PropTypes.func.isRequired,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  color: PropTypes.string,
};

NoteScale.defaultProps = {
  isSelected: false,
  value: '',
  color: 'green',
};

export default NoteScale;
