import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/shared/Button';
import Text from 'components/shared/Text';
import Icon, { defaultIconProps } from 'components/shared/IconSVG';
import MobileContext from 'context/MobileContext';
import styles from './style.module.css';

const BlockTile = ({ buttonText, text, icon, handleClick }) => {
  const isMobile = useContext(MobileContext);
  return (
    <div className={styles.blockTileWrapper}>
      <div className={styles.blockTileImage}>
        <Icon {...defaultIconProps} src={icon} width={isMobile ? '50px' : '60px'} />
      </div>
      <div className={styles.blockTileBody}>
        <div className={styles.blockTileBodyText}>
          <Text color="#000" weight="wm" size="s16" lineHeight="h20">
            {text}
          </Text>
        </div>
        <div>
          <Button size="large" handleClick={handleClick}>
            {buttonText}
          </Button>
        </div>
      </div>
    </div>
  );
};

BlockTile.propTypes = {
  buttonText: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default BlockTile;
