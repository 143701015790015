import React from 'react';
import PropTypes from 'prop-types';
import RoofProjectCard from 'components/shared/ProjectCard/RoofProjectCard';
import DroneReportCard from 'components/shared/ProjectCard/DroneReportCard';
import PageLayout from 'layouts/EmptyPage';
import Dropdown from 'components/shared/DropdownButton';
import ProjectTypes from 'enums/projectTypes';
import { useMutation } from '@apollo/react-hooks';
import { removeRoofProject } from 'graphql/cache/project';
import { REMOVE_DRAFTED_ROOF_PROJECT } from 'graphql/mutations/projects';
import { roofProjectStages } from 'enums/roofProjectStatus';
import { formatDate } from 'utils/dateFormatters';
import checkOpenProject from 'utils/checkOpenProject';
import checkNotPaidReport from 'utils/checkNotPaidReport';
import getStageRoofProject from 'utils/stageRoofProject';
import styles from './style.module.css';

const ProjectContent = ({
  roofProjects = [],
  droneReports = [],
  handleAddProject,
  handleAddDroneReport,
  handleOpenProject,
}) => {
  const isHasOpenedProject = checkOpenProject(roofProjects);
  const isHasNotPaidReport = checkNotPaidReport(droneReports);
  const [removeDraftedRoofProject, { loading }] = useMutation(
    REMOVE_DRAFTED_ROOF_PROJECT
  );
  const handleDeleteRoofProject = (projectId) => {
    removeDraftedRoofProject({
      variables: { projectId },
      update: () => {
        removeRoofProject(projectId);
      },
    });
  };
  const dropdownProps = {
    // title: 'Start a New Project',
    // items: [
    //   {
    //     text: 'Start a roof project',
    //     handleClick: handleAddProject,
    //     isDisabled: isHasOpenedProject,
    //   },
    //   {
    //     text: 'Order a drone inspection',
    //     handleClick: handleAddDroneReport,
    //     isDisabled: isHasNotPaidReport,
    //   },
    // ],
  };

  return (
    <PageLayout
      title="My Projects"
      //rightComponent={<Dropdown {...dropdownProps} />}
      topMobileRightComponent
    >
      <fieldset disabled={loading}>
        {roofProjects.map((project) => {
          const stage = getStageRoofProject(project);
          return (
            <div className={styles.cardWrapper} key={project.pgId}>
              <RoofProjectCard
                {...project}
                stage={stage?.stageName}
                status={stage?.status}
                isDisableOpen={stage?.isDisableOpen}
                dateOfInstall={formatDate(project.dateOfInstall)}
                createdDate={formatDate(project.createdDate)}
                isAvailableDelete={project.status === roofProjectStages.Draft.status}
                handleClick={() =>
                  handleOpenProject(project.pgId, ProjectTypes.Opportunity)
                }
                handleDelete={() => handleDeleteRoofProject(project.pgId)}
              />
            </div>
          );
        })}
      </fieldset>
      {droneReports.map((droneReport) => (
        <div className={styles.cardWrapper} key={`${droneReport.pgId}drone`}>
          <DroneReportCard
            {...droneReport}
            scheduledDate={formatDate(droneReport.scheduledDate)}
            handleClick={() =>
              handleOpenProject(
                droneReport.pgId || droneReport.sfId,
                ProjectTypes.DroneReport
              )
            }
          />
        </div>
      ))}
    </PageLayout>
  );
};

ProjectContent.propTypes = {
  roofProjects: PropTypes.array,
  droneReports: PropTypes.array,
  handleAddProject: PropTypes.func.isRequired,
  handleAddDroneReport: PropTypes.func.isRequired,
  handleOpenProject: PropTypes.func.isRequired,
};


ProjectContent.defaultProps = {
  droneReports: [],
  roofProjects: [],
};

export default ProjectContent;
