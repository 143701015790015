/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.css';

const stylesColor = {
  primary: styles.primaryButton,
  secondary: styles.secondaryButton,
  white: styles.whiteButton,
};

const stylesSize = {
  smallest: styles.smallestButton,
  smaller: styles.smallerButton,
  small: styles.smallButton,
  medium: styles.mediumButton,
  large: styles.largeButton,
  larger: styles.largerButton,
};

const textSizeStyles = {
  small: styles.buttonTextSmall,
  base: styles.buttonText,
};

const heightSizeStyles = {
  small: styles.heightButtonSmall,
  base: styles.heightButtonBase,
  big: styles.heightButtonBig,
};

const Button = ({
  type = 'button',
  color = 'primary',
  textSize = 'base',
  size,
  children,
  handleClick,
  className,
  height = 'base',
  id,
  ...props
}) => {
  const classNames = [
    styles.button,
    className,
    stylesColor[color],
    stylesSize[size],
    textSizeStyles[textSize],
    heightSizeStyles[height],
  ].join(' ');

  return (
    <button {...props} id={id} onClick={handleClick} type={type} className={classNames}>
      {children}
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  handleClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  textSize: PropTypes.string,
  height: PropTypes.string,
  id: PropTypes.string,
};

Button.defaultProps = {
  children: '',
  type: 'button',
  className: '',
  textSize: 'base',
  height: 'base',
  color: 'primary',
  size: '',
  handleClick: () => {},
};

export default Button;
