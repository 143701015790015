import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import { DELETE_RELATED_FILE } from 'graphql/mutations/files';
import UploadFileComponent from 'components/shared/UploadFile';
import { toBase64UploadedFiles } from 'services/uploadFilesSF/toBase64';
import { updateFilesRoofProject, deleteFileRoofProject } from 'graphql/cache/project';
import roofProjectAttachmentTypes from 'enums/roofProjectAttachmentTypes';
import { UPLOAD_LOSS_SHEET_FILES, UPLOAD_HOA_FILES } from 'graphql/mutations/projects';

const fileTypes = {
  lossSheet: {
    description: roofProjectAttachmentTypes.insuranceFile,
    isSetUploadedDate: true,
  },
  hoa: {
    description: roofProjectAttachmentTypes.hoaFile,
    isSetUploadedDate: false,
  },
};

const UploadFileContainer = ({
  uploadFiles,
  parentId,
  type,
  handleChange,
  name,
  ...props
}) => {
  const fileProps = fileTypes[type];

  const [uploadFile, { loading: loadingUpload }] = useMutation(
    fileProps.isSetUploadedDate ? UPLOAD_LOSS_SHEET_FILES : UPLOAD_HOA_FILES
  );
  const [deleteUploadedFiles, { loading: loadingDelete }] = useMutation(
    DELETE_RELATED_FILE
  );

  const handleUpload = async (newFiles) => {
    const uploadedFiles = await toBase64UploadedFiles(newFiles);
    handleChange({ target: { name, value: [uploadFiles, newFiles || []].flat() } });
    uploadFile({
      variables: {
        files: uploadedFiles,
        parentId,
        description: fileProps.description,
      },
      update: (cache, { data: { uploadFiles: upFiles } }) => {
        updateFilesRoofProject({ projectId: parentId, files: upFiles });
      },
    });
  };

  const handleDeleteFile = (removedFile, fileIndex) => {
    const files = uploadFiles.filter((file, index) => index !== fileIndex);
    deleteUploadedFiles({
      variables: {
        fileId: removedFile.contentDocumentId,
      },
      update: (cache, { data: { deleteRelatedFile } }) => {
        if (!deleteRelatedFile) return null;
        handleChange({ target: { name, value: files } });
        return deleteFileRoofProject({ fileId: removedFile.contentDocumentId, parentId });
      },
    });
  };

  return (
    <fieldset disabled={loadingUpload || loadingDelete}>
      <UploadFileComponent
        {...props}
        uploadFiles={uploadFiles}
        handleUpload={handleUpload}
        handleDelete={handleDeleteFile}
      />
    </fieldset>
  );
};

UploadFileContainer.propTypes = {
  uploadFiles: PropTypes.array,
  parentId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

UploadFileContainer.defaultProps = {
  uploadFiles: [],
};

export default UploadFileContainer;
