import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { contactUsValidationSchema } from 'validation';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_ROOF_PROJECTS_LIST } from 'graphql/queries/projects';
import ContactUs from 'components/ContactUs/index';

import useModal from 'services/hooks/useIsShowComponent';
import phoneNumberFormatWithCode, { cleanNumber } from 'utils/phoneNumberFormatter';

import { CONTACT_US_MUTATION } from 'graphql/mutations/contactUs';

const ContactInfo = ({ user }) => {
  const { data } = useQuery(GET_ROOF_PROJECTS_LIST);

  const { isShow: isOpenModal, handleClick } = useModal();

  const [contactUs, { loading }] = useMutation(CONTACT_US_MUTATION, {
    onCompleted: () => handleClick(),
  });

  const handleCloseModal = () => {
    handleClick();
  };

  const handleSubmit = (value, { resetForm }) => {
    contactUs({
      variables: {
        data: {
          fullName: value.fullName,
          email: value.email,
          phoneNumber: phoneNumberFormatWithCode(value.phoneNumber),
          subject: value.subject,
          message: value.message,
          opportunitySfId: data?.roofProjects[0]?.sfId || '',
        },
      },
    });
    resetForm();
  };

  const values = {
    fullName: `${user?.firstName || ''} ${user?.lastName || ''}`,
    email: user?.email,
    phoneNumber: cleanNumber(user?.mobilePhone || user?.phone),
    subject: '',
    message: '',
  };

  return (
    <Formik
      initialValues={values}
      validationSchema={contactUsValidationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => {
        return (
          <ContactUs
            {...formikProps}
            loading={loading}
            handleCloseModal={handleCloseModal}
            isOpenModal={isOpenModal}
          />
        );
      }}
    </Formik>
  );
};
ContactInfo.propTypes = {
  user: PropTypes.object.isRequired,
};
ContactInfo.defaultProps = {};
export default ContactInfo;
