import gql from 'graphql-tag';

export const DOWNLOAD_FILES_NAME = gql`
  query downloadFilesName($documentCategory: String!, $opportunityId: String!) {
    downloadFilesName(
      documentCategory: $documentCategory
      opportunityId: $opportunityId
    ) {
      contentDocumentId
      name
      description
      contentType
    }
  }
`;
export const DOWNLOAD_FILES_DESCRIPTION = gql`
  query downloadFilesDescription($opportunitySfId: String!) {
    downloadFilesDescription(opportunitySfId: $opportunitySfId) {
      contentDocumentId
      name
      description
      contentType
    }
  }
`;

export const DOWNLOAD_FILES_LINK = gql`
  query downloadFilesLink {
    downloadFilesLink {
      warrantyPolicyLink
      insurancePolicyLink
    }
  }
`;
export const DOWNLOAD_FILES_DATA = gql`
  query downloadFilesData($contentDocumentId: String!) {
    downloadFilesData(contentDocumentId: $contentDocumentId) {
      base64String
      type
      name
      contentDocumentId
    }
  }
`;
