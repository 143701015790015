import React from 'react';
import PropTypes from 'prop-types';
import DroneReportFinishStep from 'components/DroneReport/DroneReportFinish';
import useLoadAttachments from 'services/hooks/loadAttachments';
import Spinner from 'components/shared/Spinner';

const DroneReportContainer = ({ droneReport, ...props }) => {
  const { data, loading } = useLoadAttachments({
    opportunityId: droneReport.sfId,
    documentCategory: 'DroneReport',
  });

  if (loading)
    return (
      <div style={{ height: 'calc(100vh - 250px)' }}>
        <Spinner />
      </div>
    );

  return (
    <DroneReportFinishStep
      {...props}
      files={data?.downloadFilesName}
      loading={loading}
      droneReport={droneReport}
    />
  );
};

DroneReportContainer.propTypes = {
  droneReport: PropTypes.object.isRequired,
};

export default DroneReportContainer;
