import React from 'react';
import PropTypes from 'prop-types';
import { ProductSelection } from 'containers';
import Collapse from 'components/shared/Collapse';
import styles from '../../style.module.css';
import DroneReportStatus from './DroneReportStatus';
import ProjectPlanningInformation from './PPInformation';
import InsuranceDocumentationSection from './InsuranceDocumentationSection';
import { PaidContent, DefaultContent } from './PayDeductible';
import HomeOwnerAssociation from './HomeOwnerAssociation';
import InsuranceInspectionSection from './InsuranceInspectionSection';

const DamageAssessmentStep = ({
  values,
  errors = {
    product: {},
  },
  touched = {
    product: {},
  },
  handleChange = () => {},
  isDisabled,
  isCloseCollapses,
  isPaid,
  maxCountInsuranceDocuments,
  handleChangeIsApproved,
  project,
  handleOpenDocuments,
  inspectionDocuments,
  handleChangeFiles,
  ...props
}) => {
  return (
    <div>
      <div className={styles.projectForm}>
        <Collapse
          title="Project Planning Information"
          isOpen={!isCloseCollapses}
          isBigContent
        >
          <ProjectPlanningInformation
            inspectionDocuments={inspectionDocuments}
            handleOpenDocuments={handleOpenDocuments}
            roofInspectionDate={project.service?.scheduledDate || project.inspectionDate}
          />
        </Collapse>
      </div>
      <div className={styles.projectForm}>
        <Collapse title="Roof Assessment" isOpen={!isCloseCollapses} isBigContent>
          <DroneReportStatus
            inspectionDocuments={inspectionDocuments}
            handleOpenDocuments={handleOpenDocuments}
            roofInspectionDate={project.service?.scheduledDate || project.inspectionDate}
          />
        </Collapse>
      </div>
      <div className={styles.projectForm}>
        <Collapse title="Insurance Inspection" isOpen={!isCloseCollapses} isBigContent>
          <InsuranceInspectionSection
            {...props}
            handleChange={handleChange}
            values={values}
            insuranceStatus={project.adjusterStatus}
            isDisabled={isDisabled}
          />
        </Collapse>
      </div>
      <div className={styles.projectForm}>
        <Collapse title="Insurance Documentation" isOpen={!isCloseCollapses}>
          <InsuranceDocumentationSection
            maxCountDocument={maxCountInsuranceDocuments}
            files={values.insuranceAttachments}
            handleChange={handleChangeFiles}
            projectId={project.sfId}
          />
        </Collapse>
      </div>
      <div className={styles.projectForm}>
        <Collapse title="Product Selection" isOpen={!isCloseCollapses}>
          <ProductSelection
            {...props}
            values={{
              stoneMetalPanelType: {
                label: project.product?.name || '',
                value: project.product?.sfId || '',
                secondValue: project.product?.displayUrl || '',
              },
              productType: project.existingShingleType,
            }}
            isDisabled={isDisabled}
            projectId={project?.pgId}
            productId={project.replacementShingleId}
            handleChange={(value) =>
              handleChange({ target: { name: 'productId', value } })
            }
          />
        </Collapse>
      </div>
      <div className={styles.projectForm}>
        <Collapse title="Home Owner Association Approval" isOpen={!isCloseCollapses}>
          <HomeOwnerAssociation
            maxCountDocument={5}
            isDisabled={isDisabled}
            values={values}
            handleChange={handleChangeFiles}
            handleChangeIsApproved={handleChangeIsApproved}
            projectId={project.sfId}
          />
        </Collapse>
      </div>
      <div className={styles.projectForm}>
        <Collapse
          title="Pay Deductible"
          isBigContent={!isPaid}
          isOpen={!isCloseCollapses}
        >
          {isPaid ? (
            <PaidContent
              balance={project.deductibleAmount}
              signedDate={project.deductiblePaymentDate}
            />
          ) : (
            <DefaultContent
              balance={project.deductibleAmount}
              opportunitySfId={project.sfId}
            />
          )}
        </Collapse>
      </div>
    </div>
  );
};

DamageAssessmentStep.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isPaid: PropTypes.bool,
  maxCountInsuranceDocuments: PropTypes.number.isRequired,
  handleChangeIsApproved: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
  handleOpenDocuments: PropTypes.func.isRequired,
  handleChangeFiles: PropTypes.func.isRequired,
  isCloseCollapses: PropTypes.bool,
  inspectionDocuments: PropTypes.array,
};

DamageAssessmentStep.defaultProps = {
  errors: {
    product: {},
  },
  touched: {
    product: {},
  },
  isPaid: false,
  isCloseCollapses: false,
  inspectionDocuments: [],
};

export default DamageAssessmentStep;
