import React from 'react';
import PropTypes from 'prop-types';
import Text from 'components/shared/Text';
import IconSVG, { secondaryIconProps } from 'components/shared/IconSVG';
import Button from 'components/shared/Button';
import chevronRightIcon from 'assets/icons/chevron_right.svg';
import styles from './style.module.css';

export const NextButton = (props) => {
  return (
    <div {...props} className={styles.arrowButtonDesktop}>
      <Text color="#000" weight="wb" size="s13" className={styles.mobileButtonText}>
        Next
      </Text>
      <IconSVG {...secondaryIconProps} src={chevronRightIcon} />
    </div>
  );
};

export const SubmitButton = ({ children, handleSubmit, ...props }) => {
  return (
    <Button {...props} size="medium" type="submit" handleClick={handleSubmit}>
      {children}
    </Button>
  );
};

export const BackButton = ({ ...props }) => {
  return (
    <div {...props} className={styles.arrowButtonDesktop}>
      <IconSVG
        {...secondaryIconProps}
        src={chevronRightIcon}
        className={[secondaryIconProps.className, styles.iconLeft].join(' ')}
      />
      <Text color="#000" weight="wb" size="s13" className={styles.mobileButtonText}>
        Previous
      </Text>
    </div>
  );
};

export const MobileButton = ({ isNext, disabled, ...props }) => {
  if (disabled) return null;

  if (isNext) return <NextButton {...props} />;
  return <BackButton {...props} />;
};

SubmitButton.propTypes = {
  children: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

MobileButton.propTypes = {
  isNext: PropTypes.bool,
  disabled: PropTypes.bool,
};

MobileButton.defaultProps = {
  isNext: false,
  disabled: false,
};
