import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import warrantyIcon from 'assets/icons/warranty.svg';
import { NavLink } from 'react-router-dom';
import buttonStyles from 'components/shared/Button/style.module.css';
import AppConfigContext from 'context/AppConfigContext';
import styles from './empty.module.css';
import EmptyPageLayout from '../../layouts/EmptyPage';
import IconSVG from '../../components/shared/IconSVG';
import Text from '../../components/shared/Text';
import Button from '../../components/shared/Button';
import MobileContext from '../../context/MobileContext';

const EmptyWarrantyPage = ({ text, buttonText, buttonLink }) => {
  const isMobile = useContext(MobileContext);
  const appConfig = useContext(AppConfigContext);

  if (isMobile)
    return (
      <div className={styles.emptyPageWrapper}>
        <div className={styles.emptyPageHeader}>
          <div className={styles.emptyPageIcon}>
            <IconSVG src={warrantyIcon} width="40px" />
          </div>
          <Text
            size="s21"
            lineHeight="h21"
            color="#000000"
            weight="wb"
            style={{ marginLeft: 10 }}
          >
            {text}
          </Text>
        </div>
        <Text
          size="s16"
          lineHeight="h21"
          color="#000000"
          style={{ marginBottom: 40, textAlign: 'center' }}
        >
          {appConfig?.warrantyPageTexts}
        </Text>
        <NavLink to={buttonLink} className={buttonStyles.fullWidthMobile}>
          <Button className={buttonStyles.fullWidthMobile}>{buttonText}</Button>
        </NavLink>
      </div>
    );
  return (
    <EmptyPageLayout title="Warranty">
      <div className={styles.emptyPageWrapper}>
        <IconSVG src={warrantyIcon} width="80px" />
        <br />
        <Text size="s19" lineHeight="h25" color="#000000">
          {text}
        </Text>
        <br />
        <NavLink to={buttonLink}>
          <Button size="large">{buttonText}</Button>
        </NavLink>
      </div>
    </EmptyPageLayout>
  );
};

export default EmptyWarrantyPage;

EmptyWarrantyPage.propTypes = {
  buttonLink: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};
