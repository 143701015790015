import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import StatusTabs from 'components/shared/StatusTabs/Desktop';
import {
  InsuranceClaimStep,
  DamageAssessmentStep,
  InstallationPreparation as InstallationPreparationStep,
} from 'containers';
import MobileContext from 'context/MobileContext';
import StatusTabsMobile from 'components/shared/StatusTabs/Mobile';
import { roofProjectStages } from 'enums/roofProjectStatus';
import { formatDate } from 'utils/dateFormatters';
import RoofInstallationStep from './RoofInstallationStep';
import WarrantyStep from './WarrantyStep';
import BillingStep from './BillingStep';
import WrapperStage from './WrapperStep';
import styles from '../style.module.css';
import WelcomeMessage from './WelcomeMessage';

const description = `All cars, trailers, recreational vehicles, boats, etc., 
must be moved out of the driveway the night before installation, 
but no later than 6:00am the day of installation.  If vehicles 
are left in the driveway, they will be at risk for damage from 
falling debris and tools; RoofClaim shall not be liable for 
any damage incurred. This is a major construction project. 
Debris will be falling from your roof.  Until RoofClaim 
leaves the site, we prefer that you do not enter or exit the 
premises.  If you must, 1. Get the attention of a crew member 
so that they may cease work and allow you to safely enter or 
exit the premises, 2. Only walk on hard surfaces as roofing debris 
may be hidden in landscaping, and 3. Secure all pets; do not allow 
them to roam the property. Rotted decking and/or planks will be 
automatically replaced, as it is required by Building Code.  
RoofClaim will replace one (1) sheet of decking OR three (3) 
pieces of planking at no charge.  Any additional decking will be 
replaced, and the homeowner shall be charged $125.00 per sheet; 
additional planking shall be charged at the rate of $50.00 per piece.  
The amount is due on the day of installation.`;

function RoofProject({
  projectData = {},
  isNewProject,
  history,
  handleOpenDocuments,
  currentStage = {},
  ...props
}) {
  const isMobile = useContext(MobileContext);
  const currentStageIndex = currentStage?.tabIndex;
  const stage = !isNewProject
    ? roofProjectStages[projectData.stageName?.replace(' ', '')]
    : 0;
  const [currentTabIndex, setCurrentTabIndex] = useState(currentStageIndex);
  const handleClose = () => {
    history.push('/my-projects');
  };

  const handleGoNextStep = () => {
    return setCurrentTabIndex(currentTabIndex + 1);
  };

  const handleGoBackStep = () => {
    if (currentTabIndex === 0) return null;
    return setCurrentTabIndex(currentTabIndex - 1);
  };

  const handlesProps = {
    handleClose: handleGoBackStep,
    handleNextStep: handleGoNextStep,
  };

  const projectId = projectData.pgId;
  const bannerMessage = projectData.messageHomeowner;

  const insuranceClaimProps = {
    values: {
      insuranceCompanyId: projectData.insuranceCompanyId,
      policyNumber: projectData.policyNumber,
      claimNumber: projectData.claimNumber,
      isInsuranceApprovedClaim: projectData.isInsuranceApprovedClaim,

      mortgageCompanyId: projectData.mortgageCompanyId || null,
      loanNumber: projectData.loanNumber,
      isIncludeMortgageCompany: isNewProject
        ? projectData.isIncludeMortgageCompany
        : !!projectData.mortgageCompanyId,

      gateCode: currentStage?.isDraft
        ? projectData.gateCode
        : projectData?.account?.gateCode,
      isGateCodeRequired: currentStage?.isDraft
        ? projectData.isGateCodeRequired
        : isNewProject
        ? !!projectData?.account?.gateCode || null
        : !!projectData?.account?.gateCode,
      existingShingleType: projectData.existingShingleType,
      isRoofTarped: projectData.isRoofTarped,
      otherInsurance: projectData.otherInsurance,
      isRequiredOtherInsurance: !!projectData.otherInsurance,
      deductibleAmount: projectData.deductibleAmount,
    },
    projectId,
    projectSfId: projectData.sfId,
    contract: {
      contractSignedDate: projectData.contractSignedDate,
    },
    address: projectData.address,
    contacts: projectData.contacts,
    welcomeMessage: isMobile && isNewProject && <WelcomeMessage />,
  };

  const tabsList = [
    {
      title: 'Project Information',
      isAvailableNextStep: Boolean(projectData.contractSignedDat),
      children: (
        <InsuranceClaimStep
          {...insuranceClaimProps}
          {...props}
          handleClose={handleClose}
          handleNextStep={handleGoNextStep}
          isNewProject={currentStage?.isDraft || isNewProject}
        />
      ),
    },
    {
      title: 'Project Planning',
      isAvailableNextStep: currentStageIndex > roofProjectStages.Planning.tabIndex,
      children: (
        <WrapperStage
          bannerMessage={bannerMessage}
          isCurrentStage={currentStageIndex === roofProjectStages.Planning.tabIndex}
        >
          <DamageAssessmentStep
            {...handlesProps}
            handleOpenDocuments={handleOpenDocuments}
            project={{
              ...projectData,
              isSubmittedPlanning: stage.tabIndex > roofProjectStages.Planning.tabIndex,
            }}
            isConfirmStage={currentStageIndex > roofProjectStages.Planning.tabIndex}
            {...props}
          />
        </WrapperStage>
      ),
    },
    {
      title: 'Installation Preparation',
      isAvailableNextStep: currentStageIndex > roofProjectStages.InstallPrep.tabIndex,
      children: (
        <WrapperStage
          bannerMessage={bannerMessage}
          isCurrentStage={currentStageIndex === roofProjectStages.InstallPrep.tabIndex}
        >
          <InstallationPreparationStep
            {...handlesProps}
            isConfirmStage={currentStageIndex > roofProjectStages.InstallPrep.tabIndex}
            materialDeliveryDate={formatDate(projectData.materialDeliveryDate)}
            satelliteDish={projectData.satelliteDish}
            projectId={projectData.pgId || ''}
            solarPanelsRemovalDate={formatDate(projectData.solarPanelsRemovalDate)}
            numberSolarPanels={projectData.numberSolarPanels}
            installationDate={formatDate(projectData.dateOfInstall)}
          />
        </WrapperStage>
      ),
    },
    {
      title: 'Roof Installation',
      isAvailableNextStep: currentStageIndex > roofProjectStages.Install.tabIndex,
      children: (
        <WrapperStage
          bannerMessage={bannerMessage}
          isCurrentStage={currentStageIndex === roofProjectStages.Install.tabIndex}
        >
          <RoofInstallationStep
            {...handlesProps}
            stepData={{
              contractor: projectData.contractor,
              status: projectData.installationStatus,
              installationDate: formatDate(projectData.dateOfInstall),
              description,
            }}
            projectId={projectId || ''}
            delayMessage={projectData.delayMessage}
            existingShingleType={projectData.existingShingleType}
            isContactorMarkedComplete={
              projectData.isContactorMarkedComplete &&
              !projectData.isHOConfirmInstallationComplete &&
              projectData.isNetworkContractor
            }
            isConfirmStage={currentStageIndex > roofProjectStages.Install.tabIndex}
          />
        </WrapperStage>
      ),
    },
    {
      title: 'Billing',
      isAvailableNextStep: currentStageIndex > roofProjectStages.Billing.tabIndex,
      children: (
        <WrapperStage
          bannerMessage={bannerMessage}
          isCurrentStage={currentStageIndex === roofProjectStages.Billing.tabIndex}
        >
          <BillingStep
            {...handlesProps}
            projectId={projectData.sfId || ''}
            balance={projectData.billingBalance}
            depositBalance={projectData.billingDepositBalance}
            overdueDays={projectData.billingDaysOverdue}
            totalBalance={projectData.billingInvoiceTotal}
            isConfirmStage={currentStageIndex > roofProjectStages.Billing.tabIndex}
          />
        </WrapperStage>
      ),
    },
    {
      title: 'Warranty',
      isAvailableNextStep: false,
      children: (
        <WrapperStage
          bannerMessage={bannerMessage}
          isCurrentStage={currentStageIndex === roofProjectStages.Warranty.tabIndex}
        >
          <WarrantyStep
            handleClose={handleGoBackStep}
            handleOpenDocuments={handleOpenDocuments}
            expirationDate={formatDate(projectData.warrantyExpirationDate)}
            materialWarranty={projectData.materialWarrantyBy}
            description={projectData.warrantyVoided}
            contractor={projectData.contractor}
            opportunityPgId={projectData?.pgId || ''}
          />
        </WrapperStage>
      ),
    },
  ];

  if (isMobile)
    return (
      <StatusTabsMobile
        tabList={tabsList}
        currentTabIndex={currentTabIndex}
        setCurrentTabIndex={setCurrentTabIndex}
        activeTabIndex={currentStageIndex}
      />
    );

  return (
    <div className={styles.projectWrapper}>
      {currentTabIndex === 0 && isNewProject && <WelcomeMessage />}
      <StatusTabs
        tabList={tabsList}
        currentTabIndex={currentTabIndex}
        activeTabIndex={currentStageIndex}
        handleSelectTab={setCurrentTabIndex}
        isClickable
      />
    </div>
  );
}

RoofProject.propTypes = {
  projectData: PropTypes.object,
  isNewProject: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  handleOpenDocuments: PropTypes.func.isRequired,
  currentStage: PropTypes.object,
};

RoofProject.defaultProps = {
  projectData: {},
  currentStage: {},
};

export default RoofProject;
