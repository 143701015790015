import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/shared/IconSVG';
import emailIcon from 'assets/icons/email.svg';
import styles from './style.module.css';
import { defaultIconProps } from '../IconSVG';

const EmailLink = ({ mailTo, text, isBold }) => {
  if (!mailTo) return null;
  return (
    <div className={styles.linkWrapper}>
      <Icon
        src={emailIcon}
        width="15px"
        height="15px"
        className={isBold ? defaultIconProps.className : styles.emailIcon}
      />
      <a
        href={`mailto:${mailTo}`}
        className={[
          isBold ? styles.primaryLinkBold : '',
          styles.emailLink,
          styles.primaryLink,
        ].join(' ')}
      >
        {text || mailTo}
      </a>
    </div>
  );
};

EmailLink.propTypes = {
  mailTo: PropTypes.string.isRequired,
  text: PropTypes.string,
  isBold: PropTypes.bool,
};

EmailLink.defaultProps = {
  text: '',
  isBold: false,
};

export default EmailLink;
