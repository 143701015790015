export const DeviceWidthObject = {
    MobileSmall: { max: 320, min: 0 },
    MobileMedium: { max: 375, min: 321 },
    MobileLarge: { max: 767, min: 376 },
};

export const IdDeviceBreakpointsByWidth = {
    mobile_max: 767,
    default_min: 768 // Unrecognized device
};
export const IdMobileHeight = {
    mobileLandscape_min: 320,
    mobileLandscape_max: 425
};

export const getWindowDimension = () => {
    const width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
    const height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
    return { width, height };
};

export const getDeviceTypeInfo = () => {
    const { width, height } = getWindowDimension();
    const buildDeviceDetails = {
        deviceType: "",
        deviceTypeVariant: "",
        orientation: "Portrait",
        width,
        height
    };
    if (height < width) {
        // Orientation is landscape
        buildDeviceDetails.orientation = "Landscape";
        if (height <= IdMobileHeight.mobileLandscape_max) {
            // Mobile (landscape)
            buildDeviceDetails.deviceType = "Mobile";

            for (const devc in DeviceWidthObject) {
                if (
                    height <= DeviceWidthObject[devc].max &&
                    height >= DeviceWidthObject[devc].min
                ) {
                    buildDeviceDetails.deviceTypeVariant = devc;
                    break;
                }
            }
        }
    }
    else {
        // Orientation is portrait
        buildDeviceDetails.orientation = "Portrait";
        for (const devc in DeviceWidthObject) {
            if (
                width <= DeviceWidthObject[devc].max &&
                width >= DeviceWidthObject[devc].min
            ) {
                buildDeviceDetails.deviceTypeVariant = devc;
                break;
            }
        }

        if (width <= IdDeviceBreakpointsByWidth.mobile_max) {
            buildDeviceDetails.deviceType = "Mobile";
        }
        return buildDeviceDetails;
    }
};
