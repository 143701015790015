import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/shared/IconSVG';
import validateIcon from 'assets/icons/validate.svg';
import Modal from 'components/shared/modals/SunnyModal';
import MobileContext from 'context/MobileContext';
import { ReactComponent as ChevronIcon } from 'assets/icons/chevron_right.svg';
import styles from '../modal.module.css';
import modalStyles from './style.module.css';
import DeclinedResultStage from './DeclinedResult';
import ReviewFormStage from './ReviewForm';
import FirstStage from './FirstPage';

const CompletedInstallationModal = ({ handleClick, isShow }) => {
  const isMobile = useContext(MobileContext);
  const [isConfirm, setIsConfirm] = useState(null);
  const [isFirstStep, setIsFirstStep] = useState(true);

  const handleSendReview = ({ text, rating }) => {
    handleClick({ reviewText: text, reviewRating: rating, isConfirm });
  };

  if (!isShow) return null;

  const size = isMobile ? '50px' : '60px';
  return (
    <Modal
      isSmallSize
      headerContent={
        <>
          <Icon
            src={validateIcon}
            height={size}
            width={size}
            className={styles.questionIcon}
          />

          {!isFirstStep && (
            <ChevronIcon
              className={modalStyles.modalLeftArrow}
              onClick={() => {
                setIsFirstStep(true);
                setIsConfirm(null);
              }}
              style={{ height: '18px', width: '18px' }}
            />
          )}
        </>
      }
      mainContent={
        <div className={modalStyles.modalMainContent}>
          {isFirstStep ? (
            <FirstStage
              handleConfirm={() => {
                setIsConfirm(true);
                setIsFirstStep(false);
              }}
              handleReject={() => {
                setIsConfirm(false);
                setIsFirstStep(false);
              }}
              isConfirm={isConfirm}
            />
          ) : isConfirm ? (
            <ReviewFormStage handleClick={handleSendReview} />
          ) : (
            <DeclinedResultStage handleClick={() => handleClick({ isConfirm })} />
          )}
        </div>
      }
    />
  );
};

CompletedInstallationModal.propTypes = {
  handleClick: PropTypes.func.isRequired,
  isShow: PropTypes.bool,
};

CompletedInstallationModal.defaultProps = {
  isShow: false,
};

export default CompletedInstallationModal;
