import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Field from 'components/shared/Field';
import { FormRow as Row, FormColumn as Col } from 'components/shared/Form';
import MobileContext from 'context/MobileContext';
import Icon, { secondaryIconProps } from 'components/shared/IconSVG';
import InputMask from 'react-input-mask';
import Text from 'components/shared/Text';
import { deleteIcon } from 'assets/icons';
import styles from './style.module.css';

function ContactsForm({
  values = [],
  handleChange,
  errors = [],
  touched = [],
  handleDeleteContact,
}) {
  const isMobile = useContext(MobileContext);
  const DeleteButton = () => (
    <div className={styles.deleteButton}>
      <div onClick={handleDeleteContact} className={styles.deleteButtonContent}>
        <Icon src={deleteIcon} {...secondaryIconProps} />
        <Text color="#000" weight="wb" size="s12">
          Delete this contact
        </Text>
      </div>
    </div>
  );

  return (
    <>
      {!isMobile && <DeleteButton />}

      <Row>
        <Col>
          <Field
            name="firstName"
            type="text"
            label="First Name"
            value={values.firstName}
            onChange={handleChange}
            isError={touched?.firstName && Boolean(errors?.firstName)}
            preventDefault
          />
        </Col>
        <Col>
          <Field
            name="lastName"
            type="text"
            label="Last Name"
            value={values.lastName}
            onChange={handleChange}
            isError={touched?.lastName && Boolean(errors?.lastName)}
            preventDefault
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            name="email"
            type="email"
            label="Email Address"
            value={values.email}
            onChange={handleChange}
            isError={touched?.email && Boolean(errors?.email)}
            preventDefault
          />
        </Col>
        <Col>
          <InputMask
            mask="(999) 999-9999"
            value={values.phoneNumber}
            onChange={(e) =>
              handleChange({
                target: {
                  name: e.target.name,
                  value: e.target.value.replace(/\D/g, ''),
                },
              })
            }
          >
            {() => (
              <Field
                name="phoneNumber"
                type="tel"
                label="Phone Number"
                inputMode="tel"
                autoComplete="tel"
                isError={touched?.phoneNumber && Boolean(errors?.phoneNumber)}
                preventDefault
              />
            )}
          </InputMask>
        </Col>
      </Row>

      <Row>
        <Col>
          <Field
            name="alternateEmail"
            type="text"
            label="Alternative Email Address (Optional)"
            value={values?.alternateEmail}
            onChange={handleChange}
            isError={touched?.alternateEmail && Boolean(errors?.alternateEmail)}
            preventDefault
          />
        </Col>
        <Col>
          <InputMask
            mask="(999) 999-9999"
            value={values.mobilePhone}
            onChange={(e) =>
              handleChange({
                target: {
                  name: e.target.name,
                  value: e.target.value.replace(/\D/g, ''),
                },
              })
            }
          >
            {() => (
              <Field
                name="mobilePhone"
                type="tel"
                label="Mobile Number (Optional)"
                inputMode="tel"
                autoComplete="tel"
                isError={touched?.mobilePhone && Boolean(errors?.mobilePhone)}
                preventDefault
              />
            )}
          </InputMask>
        </Col>
      </Row>

      {isMobile && <DeleteButton />}
    </>
  );
}

ContactsForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleDeleteContact: PropTypes.func.isRequired,
};

ContactsForm.defaultProps = {
  errors: {},
  touched: {},
};

export default ContactsForm;
