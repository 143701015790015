import React from 'react';
import PropTypes from 'prop-types';
import Text from 'components/shared/Text';
import Button from 'components/shared/Button';
import Icon from 'components/shared/IconSVG';
import { shapeTopIcon } from 'assets/icons';
import { Footer as FooterButtonDesck } from 'components/Projects/RoofProjects/Footer';
import ReactGa from 'react-ga';
import styles from './styles.module.css';


const DronPackage = ({
  handleGoNextStep,
  setDronePackage,
  droneReportStatusIndex,
  dronePackage,
}) => {
  const packegeList = [
    {
      name: 'Platinum',
      price: '399',
      description: [
        {
          title: 'Claims Report:',
          text:
            'Itemized scope of work with replacement cost values based on contractor & insurance industry standard software, Xactimate, zip code specific pricing',
        },

        {
          title: 'Measurement Report:',
          text:
            'Complete measurements of the entire roof, including total area, area by pitch, ridge, hip, valley and more',
        },
        {
          title: 'Damage Report:',
          text:
            'Zoomed-out view giving overview of damage as well as zoomed-in details of damage with facet location.',
        },
      ],
    },
    {
      name: 'Gold',
      price: '299',
      description: [
        {
          title: 'Measurement Report:',
          text:
            'Complete measurements of the entire roof, including total area, area by pitch, ridge, hip, valley and more',
        },
        {
          title: 'Damage Report:',
          text:
            'Zoomed-out view giving overview of damage as well as zoomed-in details of damage with facet location.',
        },
      ],
    },
    {
      name: 'Silver',
      price: '199',
      description: [
        {
          title: 'Damage Report:',
          text:
            'Zoomed-out view giving overview of damage as well as zoomed-in details of damage with facet location.',
        },
      ],
    },
  ];

  function choosePackage(value) {
    ReactGa.event({
      category: "Drone Inspection",
      action: `Selected ${value[0]}($${value[1]}) package from the Select Drone Package tab`,
      label: "Get Started!"
    });
    setDronePackage(value);
    handleGoNextStep();
  }

  return (
    <>
      <div
        className={[
          droneReportStatusIndex > 2 ? styles.packageContainerWithButtons : '',
          styles.packageContainer,
        ].join(' ')}
      >
        {droneReportStatusIndex > 2 ? <div className={styles.popupBackground} /> : null}
        {packegeList.map(({ name, price, description }) => (
          <div
            onClick={() => choosePackage([name, price])}
            className={[
              name === 'Gold' ? styles.centerPackege : '',
              droneReportStatusIndex > 2 && name === dronePackage[0]
                ? styles.packageActive
                : '',
              styles.packege,
            ].join(' ')}
            key={name}
          >
            <div className={styles.packageType}>
              <div className={styles.partShape} />
              <Icon src={shapeTopIcon} width="100%" className={styles.shapeIcon} />
              <div className={styles.textContainer}>
                <Text color="#000" weight="wb" size="s21" tag="h2" lineHeight="h26">
                  {name}
                </Text>
                <div className={styles.whiteLine} />
                <Text color="#000" weight="wb" size="s21" tag="h2" lineHeight="h26">
                  ${price}
                </Text>
              </div>
            </div>
            <div className={styles.descriptionContainer}>
              {description.map(({ title, text }) => (
                <div className={styles.description} key={title}>
                  <Text color="#000" weight="wb" size="s16" tag="h2" lineHeight="h18">
                    {title}
                  </Text>
                  <Text color="#000" weight="wr" size="s16" tag="p" lineHeight="h18">
                    {text}
                  </Text>
                </div>
              ))}
            </div>
            <Text
              color="#837F7D"
              weight="wк"
              size="s12"
              tag="з"
              lineHeight="h16"
              className={styles.bottomText}
            >
              If you choose to purchase a roof replacement, this payment will be applied
              toward your roof cost.
            </Text>
            <Button size="larger" className={styles.buttonStarted}>
              Get Started!
            </Button>
          </div>
        ))}
      </div>
      {droneReportStatusIndex > 2 ? (
        <FooterButtonDesck isHideCancel isHideSubmit handleNextStep={handleGoNextStep} />
      ) : null}
    </>
  );
};

export default DronPackage;

DronPackage.propTypes = {
  handleGoNextStep: PropTypes.func,
  setDronePackage: PropTypes.func.isRequired,
  droneReportStatusIndex: PropTypes.number.isRequired,
  dronePackage: PropTypes.array.isRequired,
};

DronPackage.defaultProps = {
  handleGoNextStep: () => { },
};
