import gql from 'graphql-tag';

export const Auth = {
  fragments: {
    user: gql`
      fragment user on User {
        pgId
        accountPgId
        accountSfId
        firstName
        lastName
        name
        contractorStatus
        contactType
        phone
        mobilePhone
        email
        alternateEmail
        isFirstLogin
        token
        account {
          pgId
          parentId
          name
          billingCity
          billingLatitude
          billingLongitude
          billingPostalCode
          billingState
          billingCountry
          billingGeoCodeAccuracy
          billingStreet
          type
          loanNumber
          gateCode
          address
          insuranceCompany {
            sfId
            name
            address
            loanNumber
          }
          mortgageCompany {
            sfId
            name
            address
            loanNumber
          }
          contacts {
            pgId
            sfId
            firstName
            lastName
            phone
            mobilePhone
            email
          }
        }
      }
    `,
  },
};
