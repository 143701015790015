import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import WrapperBlock from 'components/shared/WrapperBlock';
import Text from 'components/shared/Text';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { formatDate } from 'utils/dateFormatters';
import styles from '../../../style.module.css';
import style from './style.module.css';

function ContractForm({ contractSignedDate: signedDate, handleSignContract }) {
  function handleEvent(e) {
    if (e.details === 'refresh') {
      window.location.reload();
    }
  }
  window.document.addEventListener('refreshEvent', handleEvent, false);

  return (
    <WrapperBlock title="Contract" isForm>
      {signedDate ? (
        <div className={styles.contractSectionItem}>
          <CheckIcon
            style={{
              width: '15px',
              height: '15px',
              filter:
                'invert(56%) sepia(24%) saturate(612%) hue-rotate(62deg) brightness(93%) contrast(88%)',
              marginRight: '10px',
            }}
          />
          <Text color="#000" size="s13" weight="wb">
            Contract signed on {formatDate(signedDate)}
          </Text>
        </div>
      ) : (
        <div className={styles.projectFormContent}>
          {/* <Text color="#000" size="s16" weight="wm">
            In order to validate this step, please sign your{' '}
            <div className={style.linkFrame} onClick={() => handleSignContract()}>
              RoofClaim contract
            </div>
          </Text> */}
        </div>
      )}
    </WrapperBlock>
  );
}

ContractForm.propTypes = {
  contractSignedDate: PropTypes.string,
  handleSignContract: PropTypes.func.isRequired,
};

ContractForm.defaultProps = {
  contractSignedDate: '',
};

export default ContractForm;
