import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/shared/Button';
import Text from 'components/shared/Text';
import styles from './style.module.css';

const DeclinedResult = ({ handleClick }) => {
  return (
    <div className={styles.modalBody}>
      <div>
        <div className={styles.modalContentDescription}>
          <Text size="s19" color="#000000" weight="wb" lineHeight="h29">
            Please contact our support if you think there is an issue with the work
            provided.
          </Text>
        </div>
      </div>

      <div className={styles.modalFooterButton}>
        <Button size="medium" handleClick={handleClick}>
          Ok
        </Button>
      </div>
    </div>
  );
};

DeclinedResult.propTypes = {
  handleClick: PropTypes.func.isRequired,
};

export default DeclinedResult;
