import PropTypes from 'prop-types';
import React from 'react';
import styles from './style.module.css';
// import Text from '../../../components/shared/Text';
import Icon from 'components/shared/IconSVG';
import logoRoof from 'assets/logos/RoofClaim-Transparent-logo.webp';

const MobileSignedOutHeader = ({ children }) => {
  return (
    <div>
      <div className={styles.bg}>
        <Icon src={logoRoof} height="40px" className={styles.logo} />
      </div>
      {children}
    </div>
  );
};

export default MobileSignedOutHeader;

MobileSignedOutHeader.propTypes = {
  children: PropTypes.node,
};

MobileSignedOutHeader.defaultProps = {
  children: null,
};
