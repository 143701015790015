import React from 'react';
import PropTypes from 'prop-types';
import Field from 'components/shared/Field';
import Button from 'components/shared/Button';
import Text from 'components/shared/Text';
import Link from 'components/shared/Link';
import emailIcon from 'assets/icons/email.svg';
import FormWrapper from 'components/Auth/FormWrapper';
import { FormColumn, FormFooter } from 'components/shared/Form';
import styles from './style.module.css';

const ForgotPasswordForm = ({
  handleChange,
  values,
  contactUsLink,
  errors,
  touched,
  handleSubmit,
}) => {
  return (
    <FormWrapper>
      <form className={styles.wrapper} onSubmit={handleSubmit} name="forgot-password">
        <Text
          color="#00000D"
          weight="wb"
          size="s21"
          lineHeight="h22"
          className={styles.centeredText}
        >
          Forgot Password
        </Text>
        <div className={styles.wrapperBody}>
          <div className={styles.wrapperContent}>
            <Text color="#292929" size="s14" lineHeight="h20" tag="p">
              Enter the email address that's associated with your Roofclaim account.
              <br />
              We will send you a password reset link
            </Text>
          </div>

          <FormColumn>
            <Field
              type="text"
              label="Email Address"
              rightIcon={emailIcon}
              name="email"
              value={values.email}
              onChange={handleChange}
              isError={touched.email && Boolean(errors.email)}
            />
          </FormColumn>
        </div>

        <FormFooter>
          <div>
            <div className={styles.wrapperFooter}>
              <Button
                size="large"
                handleClick={handleSubmit}
                disabled={Boolean(Object.keys(errors).length)}
              >
                Send an email
              </Button>
            </div>
            <div className={styles.wrapperFooter}>
              <Text color="#000">Need help? </Text>
              <Link href={contactUsLink} color="secondary" target="_blank" bold>
                Contact Us
              </Link>
            </div>
          </div>
        </FormFooter>
      </form>
    </FormWrapper>
  );
};

ForgotPasswordForm.propTypes = {
  contactUsLink: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  touched: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
};

export default ForgotPasswordForm;
