import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.css';
import Text from '../Text';

const colorStatusStyles = {
  Completed: styles.wrapperStatusCircleGreen,
  Confirmed: styles.wrapperStatusCircleOrange,
  InProgress: styles.wrapperStatusCircleOrange,
  Ongoing: styles.wrapperStatusCircleOrange,
  NotStarted: styles.wrapperStatusCircleOrange,
  Delay: styles.wrapperStatusCircleBlack,
};

const StatusText = ({ status, text }) => {
  return (
    <div className={styles.wrapperStatus}>
      <div
        className={[
          styles.wrapperStatusCircle,
          colorStatusStyles[status?.replace(/\s/g, '')],
        ].join(' ')}
      />
      <Text color="#000" size="s13" weight="wb">
        {text || status}
      </Text>
    </div>
  );
};

StatusText.propTypes = {
  status: PropTypes.string.isRequired,
  text: PropTypes.string,
};

StatusText.defaultProps = {
  text: '',
};

export default StatusText;
