import gql from 'graphql-tag';

export const Contact = {
  fragments: {
    contactData: gql`
      fragment ContactData on Contact {
        id
        name
        firstName
        lastName
        email
        phoneNumber
        mobilePhone
      }
    `,
    shortData: gql`
      fragment ShortContactData on Contact {
        name
        email
        phoneNumber
        mobilePhone
      }
    `,
  },
};
