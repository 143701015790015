import { string, object } from 'yup';

const productValidation = object().shape({
  productType: string().required(),
  stoneMetalPanelType: object().shape({
    label: string().required(),
    value: string().required(),
  }),
});

export default productValidation;
