import React, { useContext } from 'react';
import Text from 'components/shared/Text';
import Button from 'components/shared/Button';
import AppConfigContext from 'context/AppConfigContext';
import styles from './styles.module.css';

const Dron = () => {
  const config = useContext(AppConfigContext);

  const handleClick = () => {
    window.open(config?.bannerDashboardUrl, '_blank');
  };
  return (
    <div
      className={styles.placeholder}
      style={{ backgroundImage: `url(${config?.homepageBannerUrl})` }}
    >
      <div className={styles.textWrapper}>
        <Text tag="h2" size="s24" weight="wb" lineHeight="h26">
          {config?.homepageBannerTitle}
        </Text>
        <br />
        <Text size="s14" weight="wb" lineHeight="h20">
          {config?.homepageBannerBody}
        </Text>
        <br />
        {config?.homepageBannerButtonText ? (
          <Button
            size="medium"
            className={styles.button}
            color="secondary"
            textSize="small"
            handleClick={handleClick}
          >
            {config?.homepageBannerButtonText}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

Dron.propTypes = {};

export default Dron;
