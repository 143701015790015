import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import { CONFIRM_INSTALL_PREP_STAGE } from 'graphql/mutations/projects';
import { object, string, number } from 'yup';
import InstallationPreparation from 'components/Projects/RoofProjects/InstallationPreparationStep';
import satelliteDishOptions from 'enums/satelliteDishList';
import { Footer as FooterButtons } from 'components/Projects/RoofProjects/Footer';
import Spinner from 'components/shared/Spinner';
import { updateRoofProjectList } from 'graphql/cache/project';
import ReactGa from 'react-ga';

const validationSchema = object().shape({
  satelliteDish: string()
    .required()
    .oneOf(satelliteDishOptions.map(({ value }) => value)),
  numberSolarPanels: number().required(),
  solarPanelsRemovalDate: string().when('numberSolarPanels', (numberSolar) =>
    numberSolar > 0 ? string().required().nullable(false) : string().nullable(true)
  ),
});

const InstallationPreparationContainer = ({
  handleClose,
  handleNextStep,
  isConfirmStage,
  satelliteDish,
  numberSolarPanels,
  solarPanelsRemovalDate,
  projectId,
  ...props
}) => {
  const [confirmInstallPrep, { loading }] = useMutation(CONFIRM_INSTALL_PREP_STAGE);
  const [isFilledForm, setIsFilledForm] = useState(
    validationSchema.isValidSync({
      satelliteDish,
      solarPanelsRemovalDate,
      numberSolarPanels: numberSolarPanels || 0,
    })
  );

  return (
    <Formik
      initialValues={{
        satelliteDish: satelliteDish || null,
        solarPanelsRemovalDate: solarPanelsRemovalDate || null,
        numberSolarPanels: numberSolarPanels || 0,
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        ReactGa.event({
          category: "Roof Project",
          action: "Pressed the Submit button from Installation Preparation tab",
          label: "Submit"
        });
        const data = { satelliteDish: values.satelliteDish };
        if (values.numberSolarPanels > 0)
          data.solarPanelsRemovalDate = values.solarPanelsRemovalDate;
        confirmInstallPrep({
          variables: {
            ...data,
            projectId,
          },
          update: () => {
            updateRoofProjectList({
              projectId,
              data,
            });
            setIsFilledForm(true);
          },
        });
      }}
    >
      {({ values, ...formikProps }) => {
        if (loading) return <Spinner />;

        const isDisabledSubmit = isFilledForm || !validationSchema.isValidSync(values);

        return (
          <>
            <fieldset disabled={isConfirmStage}>
              <InstallationPreparation
                {...formikProps}
                {...props}
                isDisabled={isConfirmStage || isFilledForm}
                options={{ satelliteDishList: satelliteDishOptions }}
                satelliteDish={values.satelliteDish}
                solarPanelsRemovalDate={values.solarPanelsRemovalDate}
                isEditSolarDate={numberSolarPanels > 0}
              />
            </fieldset>

            <fieldset disabled={loading}>
              <FooterButtons
                handleCancel={handleClose}
                handleNextStep={handleNextStep}
                submitButtonText="Submit"
                handleSubmit={formikProps.handleSubmit}
                isHideNext={!isConfirmStage}
                isHideSubmit={isConfirmStage || isFilledForm}
                isDisabledSubmit={isDisabledSubmit}
              />
            </fieldset>
          </>
        );
      }}
    </Formik>
  );
};

InstallationPreparationContainer.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isConfirmStage: PropTypes.bool,
  handleNextStep: PropTypes.func.isRequired,
  satelliteDish: PropTypes.string,
  projectId: PropTypes.string.isRequired,
  numberSolarPanels: PropTypes.number,
  solarPanelsRemovalDate: PropTypes.string,
};

InstallationPreparationContainer.defaultProps = {
  isConfirmStage: false,
  satelliteDish: '',
  numberSolarPanels: 0,
  solarPanelsRemovalDate: '',
};

export default InstallationPreparationContainer;
