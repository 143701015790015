import React from 'react';
import PropTypes from 'prop-types';
import EmailLink from 'components/shared/Link/EmailLink';
import PhoneLink from 'components/shared/Link/PhoneLink';
import StatusText from 'components/shared/StatusText';
import phoneNumberFormatter from 'utils/phoneNumberFormatter';
import isCustomContractor from 'utils/checkIsCustomContractor';
import Row from '../../../RowText';
import styles from '../../../style.module.css';

const InstallationSection = ({ status, contractor, installationDate, isMobile }) => {
  return (
    <div>
      <div className={styles.rowContainerMargin}>
        <Row name="Installation Date" value={installationDate} isValueBold />
      </div>
      {isCustomContractor(contractor) && (
        <>
          <div className={styles.rowContainerMargin}>
            <Row
              name="Assigned Contractor"
              value={`${contractor?.firstName} ${contractor?.lastName}`}
              isValueBold={isMobile}
            />
          </div>
          <div className={[styles.rowContainerMargin].join(' ')}>
            <Row
              name="Contractor Contact"
              value={
                <div className={styles.contractSection}>
                  <div
                    className={[
                      styles.contractSectionItem,
                      styles.contractSectionItemFirst,
                    ].join(' ')}
                  >
                    <EmailLink mailTo={contractor?.email} isBold={isMobile} />
                  </div>
                  <div
                    className={[
                      styles.contractSectionItem,
                      styles.contractSectionItemLast,
                    ].join(' ')}
                  >
                    <PhoneLink
                      phoneNumber={phoneNumberFormatter(contractor?.phone)}
                      isBold={isMobile}
                    />
                  </div>
                </div>
              }
            />
          </div>
        </>
      )}

      <div className={styles.rowContainerMargin}>
        <Row
          name="Installation Status"
          value={<StatusText status={status?.replace('(delayed)', '')} text={status} />}
          isValueBold={isMobile}
        />
      </div>

      <div className={styles.rowContainerMargin}>
        <Row
          name="Contact Roofclaim"
          value={
            <div className={styles.contractSection}>
              <div
                className={[
                  styles.contractSectionItem,
                  styles.contractSectionItemFirst,
                ].join(' ')}
              >
                <PhoneLink
                  phoneNumber={process.env.REACT_APP_ROOFCALIM_PHONE}
                  text="Call us"
                  isBold={isMobile}
                />
              </div>
              <div
                className={[
                  styles.contractSectionItem,
                  styles.contractSectionItemLast,
                ].join(' ')}
              >
                <EmailLink
                  mailTo={process.env.REACT_APP_ROOFCALIM_EMAIL}
                  text="Send us an email"
                  isBold={isMobile}
                />
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};

InstallationSection.propTypes = {
  status: PropTypes.string,
  contractor: PropTypes.object,
  installationDate: PropTypes.string,
  isMobile: PropTypes.bool,
};

InstallationSection.defaultProps = {
  status: '',
  contractor: {},
  installationDate: '',
  isMobile: false,
};

export default InstallationSection;
