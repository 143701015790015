import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Text from 'components/shared/Text';
import AppConfigContext from 'context/AppConfigContext';
import UploadFileArea from 'containers/RoofProject/UploadInsuranceDocumentsContainer';
import { FormColumn, FormRow as Row, FormColumn as Col } from 'components/shared/Form';
import Field from 'components/shared/Field';
import Link from 'components/shared/Link';
import styles from '../../../style.module.css';

function HomeOwnerAssociationSection({
  maxCountDocument = 1,
  isDisabled,
  values,
  handleChangeIsApproved,
  handleChange,
  projectId,
}) {
  const options = [
    {
      label: 'N/A',
      value: 'N/A',
    },
    {
      label: 'Yes',
      value: 'Yes',
    },
    {
      label: 'No',
      value: 'No',
    },
  ];
  const appConfig = useContext(AppConfigContext);

  return (
    <div>
      <div className={styles.projectFormContent}>
        <Text color="#000" weight="wb" size="s16">
          Home Owner Association (HOA) approval of materials must be obtained prior to
          scheduling the installation.
        </Text>
      </div>
      <FormColumn>
        <div className={styles.projectFormColumn}>
          <Row>
            <Col>
              <Field
                name="hoaApproval"
                label="Do you have HOA approval?"
                type="select"
                value={values.hoaApproval}
                isDisabled={isDisabled}
                options={options}
                onChange={(event) => {
                  handleChangeIsApproved(event.target.value);
                }}
              />
            </Col>
          </Row>
        </div>
      </FormColumn>
      {values.hoaApproval && (
        <>
          <div className={styles.projectForm}>
            <Text color="#292929" size="s14">
              Then, upload your Home Owner Association approval of materials:
            </Text>
          </div>
          <fieldset disabled={['No', 'N/A'].includes(values.hoaApproval)}>
            <UploadFileArea
              maxCountDocument={maxCountDocument}
              uploadFiles={values.filesHOApproval}
              buttonText="Upload file(s)"
              accept=".jpeg, .png, .jpg, .pdf"
              handleChange={handleChange}
              name="filesHOApproval"
              parentId={projectId}
              type="hoa"
            />
          </fieldset>
        </>
      )}
    </div>
  );
}

HomeOwnerAssociationSection.propTypes = {
  maxCountDocument: PropTypes.number,
  values: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool,
  handleChangeIsApproved: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
};

HomeOwnerAssociationSection.defaultProps = {
  maxCountDocument: 1,
  isDisabled: false,
};

export default HomeOwnerAssociationSection;
