import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import './react-datepicker.css';
import moment from 'moment';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import styles from './style.module.css';

const CustomInput = ({ value, onClick, isError, customClass }) => {
  const classNames = [
    styles.datepickerCustomInput,
    isError ? styles.errorInput : '',
    customClass,
  ].join(' ');
  return (
      <div
          className={[styles.datepickerInputWrapper, styles.datepickerWrapper].join(' ')}
          onClick={onClick}
      >
        <input
            type="text"
            className={classNames}
            value={value ? moment(value).format('dddd MM/DD/YYYY') : ''}
            onChange={onClick}
        />
        <div className={styles.datepickerCalendarIcon}>
          <CalendarIcon style={{ width: '15px', height: '15px' }} />
        </div>
      </div>
  );
};

CustomInput.propTypes = {
  value: PropTypes.any,
  onClick: PropTypes.func,
  isError: PropTypes.bool,
  customClass: PropTypes.string,
};

CustomInput.defaultProps = {
  value: '',
  onClick: () => {},
  isError: false,
  customClass: '',
};

const Datepicker = ({
                      handleChange,
                      value,
                      name,
                      isDisabled,
                      isError,
                      customDatePickerClass,
                      ...props
                    }) => {
  return (
      <DatePicker
          {...props}
          selected={value ? new Date(value) : null}
          onChange={handleChange}
          value={value}
          name={name}
          todayButton="Today"
          minDate={new Date()}
          disabled={isDisabled}
          className={styles.customDatepicker}
          calendarClassName={styles.datepickerCalendar}
          customInput={<CustomInput isError={isError} customClass={customDatePickerClass} />}
      />
  );
};

Datepicker.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  name: PropTypes.string,
  handleChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  isError: PropTypes.bool,
  customDatePickerClass: PropTypes.string,
};

Datepicker.defaultProps = {
  value: '',
  name: '',
  handleChange: () => {},
  isDisabled: false,
  isError: false,
  customDatePickerClass: '',
};

export default Datepicker;
