import React from 'react';
import PropTypes from 'prop-types';
import DownloadLink from './DownloadLink';
import PrimaryLink from './Link';

const LinkPDF = ({ href, id, isDownload, fileName, children, loading, ...props }) => {
  if (isDownload)
    return (
      <DownloadLink {...props} id={id} href={href} fileName={fileName} loading={loading}>
        {children}
      </DownloadLink>
    );

  return <PrimaryLink id={id} href={href}>{children}</PrimaryLink>;
};

LinkPDF.propTypes = {
  href: PropTypes.string.isRequired,
  isDownload: PropTypes.bool,

  loading: PropTypes.bool,
  children: PropTypes.string.isRequired,
  fileName: PropTypes.string,
  id: PropTypes.string,
};

LinkPDF.defaultProps = {
  isDownload: false,
  loading: false,
  fileName: '',
};

export default LinkPDF;
