export const roofProjectStages = Object.freeze({
  Draft: {
    tabIndex: 0,
    status: 'Draft',
    isDraft: true,
    stageName: 'Insurance Claim',
  },
  Measurements: {
    tabIndex: 0,
    status: 'Ongoing',
    stageName: 'Insurance Claim',
  },
  Adjuster: {
    tabIndex: 1,
    status: 'Ongoing',
    stageName: 'Project Planning',
  },
  DisputedClaim: {
    tabIndex: 1,
    status: 'Ongoing',
    stageName: 'Project Planning',
  },
  Planning: {
    tabIndex: 1,
    status: 'Ongoing',
    stageName: 'Project Planning',
    name: 'Planning',
  },
  InstallPrep: {
    tabIndex: 2,
    status: 'Ongoing',
    stageName: 'Installation Preparation',
  },
  Install: {
    tabIndex: 3,
    status: 'Ongoing',
    stageName: 'Roof Installation',
  },
  Billing: { tabIndex: 4, status: 'Ongoing', stageName: 'Billing' },
  PostInstall: { tabIndex: 4, status: 'Ongoing', stageName: 'Billing' },
  Warranty: { tabIndex: 5, status: 'Completed', stageName: 'Warranty', isClosed: true },
  Closed: {
    tabIndex: 5,
    status: 'Closed',
    stageName: '',
    isDisableOpen: true,
    isClosed: true,
  },
  Cancelled: {
    tabIndex: 5,
    status: 'Cancelled',
    stageName: '',
    isDisableOpen: true,
    isClosed: true,
  },
});

export const roofProjectStatusEnum = Object.freeze({
  ongoing: 'Ongoing',
  closed: 'Closed',
  cancelled: 'Cancelled',
  completed: 'Completed',
  draft: 'Draft',
});
