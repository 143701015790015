export default Object.freeze({
  new: 'New',
  inProgress: 'In Progress',
  waitingForFeedback: 'Waiting for Feedback',
  qa: 'QA',
  readyForProduction: 'Ready For Release',
  onHold: 'On Hold',
  escalated: 'Escalated',
  wontFix: "Won't Fix",
  completed: 'Completed / Closed',
});
