import React from 'react';
import './styles.css'
import Modal, { closeStyle } from 'simple-react-modal';
import { Responsive } from "components/Responsive/Responsive";

export default class WelcomeSplashScreen extends React.Component {
    constructor() {
        super();
        this.state = {
            show: true
        };
    }

    show() {
        this.setState({ show: true });
    }

    close() {
        this.setState({ show: false });
    }

    onDragOver = ev => {
        ev.preventDefault();
    };

    onDragStart = (ev, id) => {
        console.log('dragstart:', ev.target);
    };

    render() {
        return (
            <div>
                <Responsive displayIn={["Mobile"]}>
                    <div
                        onDragOver={this.onDragOver}
                        draggable
                        onDragStart={this.onDragStart}
                    >
                        <Modal
                            closeOnOuterClick
                            show={this.state.show}
                            onClose={this.close.bind(this)}
                            style={{ background: 'rgba(255, 255, 255,10)' }}
                            className="modalbox"
                        >
                            <a style={closeStyle} className="dot" onClick={this.close.bind(this)}>
                                X
                            </a>
                            <div className="recnot_screen">
                                <div className="recnot_cont">
                                    <div className="rfcln_box">
                                        <div className="rfcln_tpdesc">
                                            <h4>Why Roofclaim</h4>
                                            {/* <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown </p> */}
                                        </div>
                                        <ul className="rfcln_list">
                                            <li>&#x2714; We offer fast, accurate, <b>free roof inspections</b></li>
                                            <li>&#x2714; We manage your project <b>from begining to end</b></li>
                                            <li>&#x2714; We have a <b>five star reputation</b> in the roofing industry</li>
                                            <li>&#x2714; We offer fast, accurate, <b>free roof inspection</b></li>
                                            <li>&#x2714; We have a <b>five star reputation</b> in the roofing industry</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </div>
                </Responsive>
            </div>
        );
    }
}
