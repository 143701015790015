import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import UploadIcon from 'assets/icons/upload.svg';
import Icon from 'components/shared/IconSVG';
import styles from './style.module.css';
import Text from '../Text';

const UploadFileComponent = ({
  maxCountDocument = 1,
  handleUpload = () => {},
  handleDelete = () => {},
  isInvalid,
  buttonText = 'Upload file',
  accept = '.pdf',
  uploadFiles = [],
  prefixFileName = 'CUST-',
}) => {
  const regex = new RegExp(`^${prefixFileName}`);

  function _handleChange(files) {
    const countUploadedFiles = uploadFiles.length;

    if (files.length > maxCountDocument - countUploadedFiles) {
      const spliceFiles = files.splice(0, maxCountDocument - countUploadedFiles);
      return handleUpload(spliceFiles);
    }

    return handleUpload(files);
  }

  const handleRemoveFile = (fileIndex) => {
    const removedFile = uploadFiles[fileIndex];
    return handleDelete(removedFile, fileIndex);
  };

  const onDrop = useCallback(_handleChange, [uploadFiles]);
  const { getRootProps, getInputProps, inputRef } = useDropzone({ onDrop });

  return (
    <div className={styles.uploadFileContent}>
      <div
        className={[
          styles.uploadFileInput,
          isInvalid ? styles.uploadFileInputInvalid : '',
        ].join(' ')}
      >
        {uploadFiles.map((file, fileIndex) => (
          <div className={styles.fileWrapper} key={fileIndex}>
            <div className={styles.file}>
              <p className={styles.fileName}>{`${file.name.replace(regex, '')}${
                file.contentType ? `.${file.contentType}` : ''
              }`}</p>
            </div>
            <button
              className={styles.fileButton}
              type="button"
              onClick={() => handleRemoveFile(fileIndex)}
            >
              X
            </button>
          </div>
        ))}
      </div>

      <div {...getRootProps({ accept, onClick: (evt) => evt.preventDefault() })}>
        <input
          type="file"
          id="file"
          accept={accept}
          hidden
          multiple
          ref={inputRef}
          disabled={uploadFiles.length === maxCountDocument}
          {...getInputProps({ accept })}
        />
        <label htmlFor="file">
          <button
            type="button"
            className={styles.uploadFileButton}
            disabled={uploadFiles.length === maxCountDocument}
          >
            <Icon
              src={UploadIcon}
              height="14px"
              width="14px"
              className={styles.uploadFileIcon}
            />
            <Text className={styles.fileButtonText}>{buttonText}</Text>
          </button>
        </label>
      </div>
    </div>
  );
};

UploadFileComponent.propTypes = {
  maxCountDocument: PropTypes.number,
  handleDelete: PropTypes.func,
  handleUpload: PropTypes.func,
  isInvalid: PropTypes.bool,
  accept: PropTypes.string,
  buttonText: PropTypes.string,
  uploadFiles: PropTypes.array,
  prefixFileName: PropTypes.string,
};

UploadFileComponent.defaultProps = {
  maxCountDocument: 1,
  isInvalid: false,
  handleUpload: () => {},
  handleDelete: () => {},
  accept: '.pdf',
  buttonText: 'Upload file',
  uploadFiles: [],
  prefixFileName: 'CUST-',
};

export default UploadFileComponent;
