import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from 'App';
import { ApolloProvider } from '@apollo/react-hooks';
import client from 'graphql/client';
import FollowAnalytics from 'followanalytics';
import * as serviceWorker from './serviceWorker';
import './index.css';

const dataWalletPolicyChange = policy => {
  /* eslint-disable-next-line no-console */
  console.warn('Data Policy Privacy changed');
  /* eslint-disable-next-line no-console */
  console.warn(policy);
};

FollowAnalytics.initialize({
  FAID: 'duhcBZ6PQpbmLQ',
  userId: '',
  isVerbose: false,
  sessionTimeout: 10,
  bundleId: 'com.roofclaim.support',
  geolocationEnabled: false,
  optInAnalyticsDefault: true,
  analyticsScope: FollowAnalytics.AnalyticsScope.FULL,
  onDataWalletPolicyChange: dataWalletPolicyChange,
  isDataWalletEnabled: true,
  initServiceWorker: false,
  defaultIcon: 'https://fa-assets.s3-eu-west-1.amazonaws.com/webpush/fa-web-notification-icon.png',
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Router>
        <App />
      </Router>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
