const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export async function toBase64FilesArray(files) {
  const base64Files = await Promise.all(files.map(async (item) => toBase64(item)));
  const fileNames = files.map(({ name }) => name);
  return [base64Files, fileNames];
}

export async function toBase64UploadedFiles(files) {
  const base64Files = await Promise.all(files.map(async (item) => toBase64(item)));
  return files.map(({ name }, index) => ({
    file: base64Files[index],
    name,
  }));
}
