import client from 'graphql/client';
import { GET_DRONE } from 'graphql/queries/droneReport';

export const getDroneReportsFromCache = () => {
  const { droneReports } = client.cache.readQuery({
    query: GET_DRONE,
  });

  return droneReports;
};
