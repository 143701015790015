import React from 'react';
import { Formik } from 'formik';
import { signInValidationSchema } from 'validation';
import SignInForm from 'components/Auth/SignIn';
import { useAuth } from '../services/hooks/useAuth';

const SignInComponent = () => {
  const { isLoading, error, handleSubmit } = useAuth();

  return (
    <Formik
      initialValues={{ email: '', password: '', isKeepLogged: false }}
      validationSchema={signInValidationSchema}
      validateOnMount
      onSubmit={handleSubmit}
    >
      {(formikProps) => {
        const handleChangeIsKeepLogged = () => {
          formikProps.setFieldValue('isKeepLogged', !formikProps.values.isKeepLogged);
        };
        return (
          <fieldset disabled={isLoading}>
            <SignInForm
              {...formikProps}
              errorMessage={error}
              handleChangeIsKeepLogged={handleChangeIsKeepLogged}
            />
          </fieldset>
        );
      }}
    </Formik>
  );
};

export default SignInComponent;
