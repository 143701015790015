import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import MobileContext from 'context/MobileContext';
import Text from 'components/shared/Text';
import styles from './style.module.css';

function WrapperBlock({
                          children,
                          title,
                          isForm,
                          icon,
                          rightComponent,
                          className,
                          isContractorsStyle,
                          titleSize,
                          titleHeight,
                          tag,
                          isHideSeparateLine,
                          isOnBoarding,
                          isChecked,
                      }) {
    const isMobile = useContext(MobileContext);

    const classNameBlockRight = [styles.wrapperBlockRight, className].join(' ');

    const [isVisible, setIsVisible] = useState(true);

    const handleToggleBlock = () => {
        setIsVisible(!isVisible);
    };

    return (
        <section
            className={[
                styles.wrapperBlock,
                isOnBoarding
                    ? isChecked
                    ? styles.wrapperCheckBlockChecked
                    : styles.wrapperCheckBlock
                    : '',
            ].join(' ')}
        >
            {isOnBoarding && (
                <span className={[styles.checkbox, isChecked ? styles.checked : ''].join(' ')} />
            )}
            <div className={isOnBoarding ? styles.titleBlock : ''}>
                <div className={styles.wrapperBlockJustify}>
                    <div>
                        <div className={styles.wrapperBlockTitle}>
                            <Text
                                color="#000"
                                weight="wb"
                                size={titleSize}
                                lineHeight={titleHeight}
                                tag={tag}
                            >
                                {title}
                            </Text>
                            {icon && <div className={styles.wrapperBlockIcon}>{icon}</div>}
                        </div>
                        {!isHideSeparateLine && (
                            <div
                                className={[
                                    styles.wrapperBlockLine,
                                    isContractorsStyle ? styles.wrapperBlockLineContractor : '',
                                ].join(' ')}
                            />
                        )}
                    </div>
                    {rightComponent !== null && (
                        <div className={classNameBlockRight}>{rightComponent}</div>
                    )}
                </div>
            </div>
            {isOnBoarding && isMobile && (
                <span
                    onClick={handleToggleBlock}
                    className={[
                        styles.toggleButton,
                        isVisible ? styles.toggleButtonRotate : '',
                    ].join(' ')}
                />
            )}
            <main
                className={[
                    isForm ? styles.wrapperBlockMainForm : styles.wrapperBlockMain,
                    isContractorsStyle ? styles.wrapperBlockMainContractors : '',
                    isOnBoarding ? styles.wrapperBlockMainOnBoarding : '',
                    isVisible ? '' : styles.hidden,
                ].join(' ')}
            >
                {children}
            </main>
        </section>
    );
}

WrapperBlock.propTypes = {
    children: PropTypes.node.isRequired,
    icon: PropTypes.node,
    isForm: PropTypes.bool,
    rightComponent: PropTypes.node,
    title: PropTypes.string.isRequired,
    className: PropTypes.string,
    isContractorsStyle: PropTypes.bool,
    titleSize: PropTypes.string,
    titleHeight: PropTypes.string,
    tag: PropTypes.string,
    isHideSeparateLine: PropTypes.bool,
    isOnBoarding: PropTypes.bool,
    isChecked: PropTypes.bool,
};

WrapperBlock.defaultProps = {
    className: '',
    icon: null,
    isForm: false,
    rightComponent: null,
    isContractorsStyle: false,
    titleSize: 's19',
    titleHeight: 'h14',
    tag: 'span',
    isHideSeparateLine: false,
    isOnBoarding: false,
    isChecked: false,
};

export default WrapperBlock;
