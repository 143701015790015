import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/shared/IconSVG';
import styles from './style.module.css';

const Input = ({ invalid, rightIcon, leftIcon, className, ...props }) => {
  const isIcon = Boolean(leftIcon) || Boolean(rightIcon);

  const classNames = [
    styles.input,
    className,
    invalid ? styles.invalidInput : '',
    (rightIcon && leftIcon) || rightIcon ? styles.inputBig : styles.inputSmall,
  ].join(' ');

  if (isIcon) {
    return (
      <div className={styles.wrapperInput}>
        {rightIcon && (
          <Icon src={rightIcon} width="15px" className={styles.iconInput} height="15px" />
        )}
        <input className={classNames} {...props} />
        {leftIcon}
      </div>
    );
  }

  return <input className={classNames} {...props} />;
};

Input.propTypes = {
  invalid: PropTypes.bool,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  className: PropTypes.string,
};

Input.defaultProps = {
  invalid: false,
  leftIcon: null,
  rightIcon: null,
  className: null,
};

export default Input;
