import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Field from 'components/shared/Field';
import emailIcon from 'assets/icons/email.svg';
import Checkbox from 'components/shared/CheckBox';
import Button from 'components/shared/Button';
import Text from 'components/shared/Text';
import buttonStyles from 'components/shared/Button/style.module.css';
import FormWrapper from 'components/Auth/FormWrapper';
import Link from 'components/shared/Link';
import {
  FormContainer,
  FormContent,
  FormColumn as Col,
  FormFooter,
} from 'components/shared/Form';
import MobileContext from 'context/MobileContext';
import styles from '../style.module.css';

const SignInComponent = ({
  handleSubmit,
  values,
  errors,
  touched,
  errorMessage,
  handleChange,
  handleChangeIsKeepLogged,
}) => {
  const isMobile = useContext(MobileContext);

  return (
    <FormWrapper
      header={
        isMobile ? null : (
          <div className={styles.authFormHeader}>
            <Text color="#0d1e49" weight="wb" size="s21">
              Sign In
            </Text>
            {/* <Text color="#00000D" weight="wm" size="s16">
              Don't have an account?{' '}
              <Link href="/sign-up" bold size="medium">
                Sign Up
              </Link>
            </Text> */}
          </div>
        )
      }
    >
      <form onSubmit={handleSubmit} name="sign-in">
        <FormContainer>
          {isMobile && (
            <div className={styles.authFormHeaderMobile}>
              <Text color="#00000D" weight="wb" size="s21">
                Sign In
              </Text>
            </div>
          )}
          <FormContent>
            {errorMessage ? (
              <Col>
                <Text
                  size="s16"
                  lineHeight="l14"
                  weight="ws"
                  color="#f44336"
                  className={styles.pre}
                >
                  {errorMessage}
                </Text>
              </Col>
            ) : null}
            <Col>
              <Field
                name="email"
                label="Email Address"
                type="text"
                inputMode="text"
                required
                value={values.email}
                autoComplete="on"
                rightIcon={emailIcon}
                onChange={handleChange}
                isError={touched.email && Boolean(errors.email)}
              />
            </Col>
            <Col>
              <Field
                name="password"
                label="Password"
                type="password"
                inputMode="text"
                required
                value={values.password}
                autoComplete="current-password"
                onChange={handleChange}
                isError={touched.password && Boolean(errors.password)}
              />
            </Col>

            <Col>
              <div className={styles.authFormHeader}>
                <Checkbox
                  text="Remember me"
                  name="isKeepLogged"
                  value={values.isKeepLogged}
                  textSize="large"
                  onChange={handleChangeIsKeepLogged}
                />
                <div className={styles.authFooterLink}>
                  <Link href="/forgot-password">Update Password?</Link>
                </div>
              </div>
            </Col>
          </FormContent>

          <FormFooter>
            {isMobile ? (
              <div className={styles.formFooter}>
                <Button
                  size="medium"
                  type="submit"
                  handleClick={handleSubmit}
                  disabled={Object.keys(errors)?.length}
                  className={buttonStyles.fullWidthMobile}
                >
                  Log Me In
                </Button>
                <br />
                {/* <Text color="#00000D" weight="wm" size="s16">
                  Don't have an account?{' '}
                  <Link href="/sign-up" bold size="medium" color="secondary">
                    Sign Up
                  </Link>
                </Text> */}
              </div>
            ) : (
                <Button
                  size="medium"
                  type="submit"
                  handleClick={handleSubmit}
                  disabled={Object.keys(errors)?.length}
                >
                  Log Me In
                </Button>
              )}
          </FormFooter>
        </FormContainer>
      </form>
    </FormWrapper>
  );
};

SignInComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errorMessage: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleChangeIsKeepLogged: PropTypes.func.isRequired,
};

SignInComponent.defaultProps = {
  errorMessage: '',
};

export default SignInComponent;
