import { authErrors } from 'enums/errors/authErrors';
import { defaultErrorMessage } from '../enums/errors';

export const formatMessage = (message) => {
  if (message) return message?.replace(/GraphQL error: /, '');
  return defaultErrorMessage;
};

export const formatAuthMessage = (message) => {
  if (message) return authErrors?.[formatMessage(message)?.replace(/AuthenticationError: /, '')] ?? defaultErrorMessage;
  return defaultErrorMessage;
};
