import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { GET_CURRENT_USER } from 'graphql/cache';
import StatusTabs from 'components/shared/StatusTabs/Desktop';
import StatusTabsMobile from 'components/shared/StatusTabs/Mobile';
import MobileContext from 'context/MobileContext';
import DroneReportContactInfoStep from 'containers/DroneReport/DroneReportContactInfoContainer';
import Text from 'components/shared/Text';
import Icon from 'components/shared/IconSVG';
import { chevronIcon } from 'assets/icons';

import { FinishDroneReport } from 'containers';
import sortByPrimaryContacts from 'utils/sortByPrimaryContacts';

import DronPackageStep from './DronPackage/Desktop';
import PaymentStep from './Payment';
import DronPackageStepMobile from './DronPackage/Mobile';
import styles from './styles.module.css';

function DronReport({ droneReport, history, handleRefetch }) {
  const { data } = useQuery(GET_CURRENT_USER);

  const droneReportStatusIndex = droneReport.paid ? 3 : droneReport?.dronePackage ? 2 : 0;
  const [currentTabIndex, setCurrentTabIndex] = useState(droneReportStatusIndex);

  const packag = droneReport ? droneReport.dronePackage : '';
  const price = droneReport ? droneReport.price : '';
  const [dronePackage, setDronePackage] = useState([packag, price]);

  const isMobile = useContext(MobileContext);

  const handleGoNextStep = () => {
    setCurrentTabIndex(currentTabIndex + 1);
  };
  const handleGoPreviousStep = () => {
    setCurrentTabIndex(currentTabIndex - 1);
  };

  const _initialValues = {
    contacts: sortByPrimaryContacts(data),
    propertyDetails: {
      isRoofTarped: false,
      propertyAddress: data?.user?.account?.address || '',
    },
  };

  const handleClose = () => {
    history.push('/my-projects');
  };
  const isCompleted = droneReport.status === 'Completed';

  const dronPakage = isMobile ? (
    <DronPackageStepMobile
      handleGoNextStep={handleGoNextStep}
      setDronePackage={setDronePackage}
    />
  ) : (
    <DronPackageStep
      handleGoNextStep={handleGoNextStep}
      setDronePackage={setDronePackage}
      droneReportStatusIndex={droneReportStatusIndex}
      dronePackage={dronePackage}
    />
  );

  const tabsList = [
    {
      title: 'Select Drone Package',
      children: dronPakage,
    },
    {
      title: 'Contact Information',
      isBlockedPrevStep: false,
      children: (
        <div className={styles.stageWrapper}>
          <DroneReportContactInfoStep
            values={_initialValues}
            handleClose={handleClose}
            handleGoNextStep={handleGoNextStep}
            handleGoPreviousStep={handleGoPreviousStep}
            dronePackage={dronePackage}
            handleRefetch={handleRefetch}
            isAvailableNext={droneReportStatusIndex >= 3}
          />
        </div>
      ),
    },
    {
      title: 'Payment',
      isBlockedPrevStep: true,
      children: (
        <div className={styles.stageWrapper}>
          <PaymentStep
            handleGoPreviousStep={handleGoPreviousStep}
            dronePackage={dronePackage}
            droneReport={droneReport}
            isDisabled={droneReportStatusIndex > 2}
            handleGoNextStep={handleGoNextStep}
            isAvailableNext={droneReportStatusIndex >= 3}
            contactSfId={data?.user?.sfId}
          />
        </div>
      ),
    },
    {
      title: 'Drone Report',
      isClosed: isCompleted,
      isBlockedPrevStep: true,
      children: (
        <div className={styles.stageWrapper}>
          <FinishDroneReport
            droneReport={droneReport}
            isCompleted={isCompleted}
            handleGoNextStep={handleGoNextStep}
            handleGoPreviousStep={handleGoPreviousStep}
            isHideNext
          />
        </div>
      ),
    },
  ];

  return (
    <>
      {!isMobile && (
        <>
          <div className={styles.droneReportTitle}>
            <div className={styles.droneReportTitleStep} onClick={handleClose}>
              <Text
                color="#837F7D"
                weight="wr"
                size="s16"
                tag="p"
                lineHeight="h16"
                className={styles.title}
              >
                My Projects
              </Text>
            </div>

            <Icon src={chevronIcon} height="14px" className={styles.chevronIcon} />
            <Text color="#000" weight="wb" size="s14" tag="p">
              Drone Inspection
            </Text>
          </div>
          <div className={styles.dronReportWrapper}>
            <StatusTabs
              tabList={tabsList}
              currentTabIndex={currentTabIndex}
              activeTabIndex={droneReportStatusIndex}
              handleSelectTab={setCurrentTabIndex}
              isClickable
            />
          </div>
        </>
      )}
      {isMobile && (
        <div className={styles.dronReportWrapperMobile}>
          <StatusTabsMobile
            tabList={tabsList}
            currentTabIndex={currentTabIndex}
            setCurrentTabIndex={setCurrentTabIndex}
            activeTabIndex={droneReportStatusIndex}
          />
        </div>
      )}
    </>
  );
}
DronReport.propTypes = {
  droneReport: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  handleRefetch: PropTypes.func,
};

DronReport.defaultProps = {
  handleRefetch: () => { },
};
export default DronReport;
