import React from 'react';
import PropTypes from 'prop-types';
import Text from '../Text';
import styles from './style.module.css';

const NoteText = ({ children, isNoteDescription, smallSize, projectPlaningInfo }) => {
  return (
    <div
      className={[
        styles.noteTextWrapper,
        smallSize ? styles.noteTextWrapperSmall : styles.noteTextWrapperBig,
        projectPlaningInfo ? styles.greenBackground : '',
      ].join(' ')}
    >
      {isNoteDescription && (
        <Text color="#000" size="s13" weight="wb">
          Note:{' '}
        </Text>
      )}
      <Text color="#000" size="s14" lineHeight="h20">
        {children}
      </Text>
    </div>
  );
};

NoteText.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  isNoteDescription: PropTypes.bool,
  smallSize: PropTypes.bool,
  projectPlaningInfo: PropTypes.bool,
};

NoteText.defaultProps = {
  isNoteDescription: false,
  smallSize: false,
  projectPlaningInfo: false,
};

export default NoteText;
