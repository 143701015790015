import React from 'react';
import PropTypes from 'prop-types';
import Text from 'components/shared/Text';
import { FormColumn } from 'components/shared/Form';
import Datepicker from 'components/shared/Datepicker';
import TimePicker from 'components/shared/Datepicker/TimePicker';
import Note from 'components/shared/NoteText';
import Button from 'components/shared/Button';
import sectionStyles from './style.module.css';

function InsuranceInspectionSection({
  handleChange,
  isDisabled,
  isReinspectionDate,
  values,
  insuranceStatus,
  handleChangeDate,
  handleChangeTime,
  saveDateTime,
}) {
  return (
    <div>
      <div className={sectionStyles.wrapper}>
        {insuranceStatus && (
          <FormColumn>
            <div className={sectionStyles.rowContainer}>
              <div className={sectionStyles.rowContainerParent}>
                <Text color="#686868" size="s14" weight="wm">
                  Inspection Status
                </Text>
              </div>

              <Text color="#000" size="s13" weight="wb">
                {insuranceStatus}
              </Text>
            </div>
          </FormColumn>
        )}
        <FormColumn>
          <div className={sectionStyles.rowContainer}>
            <div className={sectionStyles.rowContainerParent}>
              <Text color="#686868" size="s14" weight="wm">
                Insurance Inspection Date
              </Text>
            </div>
            <div className={sectionStyles.rowContainerPicker}>
              <div>
                <Datepicker
                  value={values.insuranceInspectionDate}
                  name="insuranceInspectionDate"
                  isDisabled={isDisabled}
                  handleChange={(date) => {
                    handleChangeDate({
                      target: { value: date, name: 'insuranceInspectionDate' },
                    });
                  }}
                />
              </div>
              <div className={sectionStyles.centerText}>
                <Text color="#686868" size="s14" weight="wm">
                  at
                </Text>
              </div>

              <div>
                <TimePicker
                  value={values.insuranceInspectionTime}
                  name="insuranceInspectionTime"
                  isDisabled={isDisabled}
                  handleChange={(time) => {
                    handleChangeTime({
                      target: { value: time, name: 'insuranceInspectionTime' },
                    });
                  }}
                />
              </div>
              <Button
                size="smallest"
                id="btn-save-date"
                handleClick={saveDateTime}
                color="primary"
                textSize="small"
              >
                Save Date
              </Button>
            </div>
          </div>
        </FormColumn>
        {isReinspectionDate && (
          <FormColumn>
            <div className={sectionStyles.rowContainer}>
              <div className={sectionStyles.rowContainerParent}>
                <Text color="#686868" size="s14" weight="wm">
                  Reinspection Date
                </Text>
              </div>
              <div className={sectionStyles.rowContainerPicker}>
                <div>
                  <Datepicker
                    value={values.reinspectionDate}
                    name="reinspectionDate"
                    isDisabled={isDisabled}
                    handleChange={(date) => {
                      handleChange({
                        target: { value: date, name: 'reinspectionDate' },
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </FormColumn>
        )}
      </div>

      <Note isNoteDescription smallSize>
        In case the initial appointment time has been rescheduled, please do not forget to
        update the date.
      </Note>
    </div>
  );
}

InsuranceInspectionSection.propTypes = {
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool,
  insuranceStatus: PropTypes.string,
  isReinspectionDate: PropTypes.string,
  handleChangeDate: PropTypes.func.isRequired,
  handleChangeTime: PropTypes.func.isRequired,
  saveDateTime: PropTypes.func.isRequired,
};

InsuranceInspectionSection.defaultProps = {
  isDisabled: false,
  insuranceStatus: '',
  isReinspectionDate: '',
};

export default InsuranceInspectionSection;
