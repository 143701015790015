import React, { useMemo, useState } from 'react';
import { GET_USER_NOTIFICATIONS_SETTINGS } from 'graphql/queries/notifications';
import { useMutation, useQuery } from '@apollo/react-hooks';
import update from 'immutability-helper';
import { UPDATE_NOTIFICATIONS_SETTINGS } from 'graphql/mutations/notifications';
import isEmpty from 'lodash/isEmpty';
import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';
import client from 'graphql/client';
import styles from './style.module.css';
import Text from '../../shared/Text';
import WrapperBlock from '../../shared/WrapperBlock';
import Spinner from '../../shared/Spinner';
import Checkbox from '../../shared/CheckBox';
import Button from '../../shared/Button';

const SettingsPage = () => {
  const [_settingsTemplate, setSettingsTemplate] = useState(null);
  const [isShowButtons, setShowButtons] = useState(false);
  const { data: settingsTemplate, loading } = useQuery(GET_USER_NOTIFICATIONS_SETTINGS, {
    onCompleted: () => {
      setSettingsTemplate(settingsTemplate.notificationsSettings);
    },
  });

  const [saveNotificationsSettings, { loading: updateLoading }] = useMutation(
    UPDATE_NOTIFICATIONS_SETTINGS
  );

  const submit = async () => {
    await saveNotificationsSettings({
      variables: {
        settings: _settingsTemplate,
      },
      update: () => {
        client.cache.writeQuery({
          query: GET_USER_NOTIFICATIONS_SETTINGS,
          data: { notificationsSettings: _settingsTemplate },
        });
      },
    });
  };
  const cancel = () => setSettingsTemplate(settingsTemplate.notificationsSettings);

  function handleChange({ divisionIndex, typeIndex, target }) {
    if (isShowButtons) setShowButtons(false);

    setSettingsTemplate(
      update(_settingsTemplate, {
        [divisionIndex]: {
          types: {
            [typeIndex]: {
              value: {
                [target]: {
                  $apply(x) {
                    return !x;
                  },
                },
              },
            },
          },
        },
      })
    );
  }

  const hasFormChanged = useMemo(
    () =>
      !isEmpty(
        differenceWith(
          settingsTemplate?.notificationsSettings,
          _settingsTemplate,
          isEqual
        )
      ),
    [settingsTemplate, _settingsTemplate]
  );

  const getCheckboxValue = (divisionIndex, typeIndex) =>
    _settingsTemplate?.[divisionIndex].types[typeIndex].value ?? {
      email: false,
      push: false,
    };

  if (loading) return <Spinner />;

  return (
    <div className={styles.pageWrapper}>
      <WrapperBlock title="Notification Settings">
        <div className={styles.row}>
          {/*  // POSTPONE: Email settings
          <Text color="#686868" size="s14" className={styles.emailTitle}>
          Email
          </Text> */}
          <Text color="#686868" size="s14" className={styles.emailTitle}>
            Push
          </Text>
        </div>
        {settingsTemplate?.notificationsSettings.map((division, divisionIndex) => (
          <div className={styles.division} key={division.title}>
            <Text
              weight="wb"
              color="#000000"
              size="s16"
              style={{ textDecoration: 'underline' }}
            >
              {division.title}
            </Text>
            <br />
            {division.types.map((type, typeIndex) => (
              <div
                className={[styles.row, styles.settingsType].join(' ')}
                key={type.field}
              >
                <Text color="#000000" size="s14" className={styles.snuggledToLeft}>
                  {type.name}
                </Text>
                {/*  // POSTPONE: Email settings
                <Checkbox
                  name="email"
                  checked={getCheckboxValue(divisionIndex, typeIndex).email}
                  onChange={() =>
                    handleChange({
                      divisionIndex,
                      typeIndex,
                      fieldName: type.field,
                      target: 'email',
                    })
                  }
                />
                */}
                <Checkbox
                  name="push"
                  disabled="true"
                  checked={getCheckboxValue(divisionIndex, typeIndex).push}
                  onChange={() =>
                    handleChange({
                      divisionIndex,
                      typeIndex,
                      fieldName: type.field,
                      target: 'push',
                    })
                  }
                />
              </div>
            ))}
          </div>
        ))}
        {hasFormChanged ? (
          <div className={styles.footer}>
            <div className={styles.buttonContainer}>
              <Button
                type="submit"
                size="small"
                disabled={loading || updateLoading}
                handleClick={submit}
              >
                Save Changes
              </Button>
            </div>
            <div className={styles.buttonContainer}>
              <Button
                color="white"
                size="small"
                disabled={loading || updateLoading}
                handleClick={cancel}
              >
                Cancel
              </Button>
            </div>
          </div>
        ) : null}
      </WrapperBlock>
    </div>
  );
};

export default SettingsPage;
