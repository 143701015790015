import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import capitalize from 'lodash/capitalize';
import moment from 'moment';
import { handleOpenChat } from 'utils/handleOpenChat';
import ProjectFormInfo from 'components/shared/ProjectFormInfo';
import formInfoStyles from 'components/shared/ProjectFormInfo/style.module.css';
import Text from 'components/shared/Text';
import LinkPDF from 'components/shared/LinkPDF';
import MobileContext from 'context/MobileContext';
import AppConfigContext from 'context/AppConfigContext';
import Button from 'components/shared/Button';
import IconSVG from 'components/shared/IconSVG';
import phone from 'assets/icons/phone.svg';
import email from 'assets/icons/email.svg';
import { chatIcon } from 'assets/icons';
import { formatDateTime } from 'utils/dateFormatters';
import styles from '../RepairPlanningStep/styles.module.css';

const contactUsText = `If you are not satisfied with your roof install, or if you 
encounter any other issues or concerns during the repair process, please reach out 
to RoofClaim so that we can help you out.`;

export const ReadBeforeRepair = () => {
  const config = useContext(AppConfigContext);

  return (
    <LinkPDF href={config?.readBeforeRepairLink} fileName="Read Before Repair">
      Read Before Repair
    </LinkPDF>
  );
};

function RepairPlanningForm({ children, caseStatus }) {
  const isMobile = useContext(MobileContext);
  if (!caseStatus) return null;
  const { repairDateTime, caseNumber, status, resolutionNotes } = caseStatus;
  const repairDate = moment(caseStatus?.repairDateTime);

  return (
    <>
      <ProjectFormInfo
        title="Repair"
        rightComponent={isMobile ? null : <ReadBeforeRepair />}
        isForm
      >
        {children}
        <div className={formInfoStyles.wrapper}>
          <span className={formInfoStyles.label}>
            <Text size="s14" lineHeight="h16" color={isMobile ? '#000' : '#686868'}>
              Repair Date/Time
            </Text>
          </span>
          {repairDateTime ? (
            <Text size="s14" lineHeight="h16" weight="wb" color="#000000">
              {`${formatDateTime(repairDate)}`}
            </Text>
          ) : (
            <Text
              size="s14"
              lineHeight="h16"
              color="#000000"
              weight={isMobile ? 'wb' : 'wr'}
            >
              Date is being finalized. You will see it here once it's in the system.
            </Text>
          )}
        </div>

        {/* Case № */}
        <div className={formInfoStyles.wrapper}>
          <span className={formInfoStyles.label}>
            <Text size="s14" lineHeight="h16" color={isMobile ? '#000' : '#686868'}>
              Case Number
            </Text>
          </span>
          <Text
            size="s14"
            lineHeight="h16"
            color="#000000"
            weight={isMobile ? 'wb' : 'wr'}
          >
            {caseNumber ??
              "Case Number is being finalized. You will see it here once it's in the system."}
          </Text>
        </div>

        {/* Repair Status */}
        <div className={formInfoStyles.wrapper}>
          <span className={formInfoStyles.label}>
            <Text size="s14" lineHeight="h16" color={isMobile ? '#000' : '#686868'}>
              Repair Status
            </Text>
          </span>
          <div>
            <span style={{ backgroundColor: '#FF4E00' }} className={styles.indicator} />
            <Text size="s13" lineHeight="h14" color="#000000" weight="wb">
              {capitalize(status)}
            </Text>
          </div>
        </div>
        {/* resolutionNotes */}
        {resolutionNotes ? (
          <div className={formInfoStyles.wrapper}>
            <span className={formInfoStyles.label}>
              <Text size="s14" lineHeight="h16" color={isMobile ? '#000' : '#686868'}>
                Resolution Notes
              </Text>
            </span>
            <div>
              <Text size="s14" lineHeight="h16" color="#000000" weight="wr">
                {capitalize(resolutionNotes)}
              </Text>
            </div>
          </div>
        ) : null}
      </ProjectFormInfo>

      <ProjectFormInfo title="Contact Us" isForm>
        <Text color="#000000" size="s15" lineHeight="h20">
          {contactUsText}
        </Text>
        <div className={styles.arrangedButtons}>
          <a href={`tel:${process.env.REACT_APP_ROOFCALIM_PHONE}`}>
            <Button className={styles.arrangedButton}>
              <IconSVG src={phone} width="15px" className={styles.gap} />
              Call us
            </Button>
          </a>
          <a href={`mailto:${process.env.REACT_APP_ROOFCALIM_EMAIL}`}>
            <Button className={styles.arrangedButton}>
              <IconSVG src={email} width="15px" className={styles.gap} />
              Send an email
            </Button>
          </a>
          <Button className={styles.arrangedButton} handleClick={handleOpenChat}>
            <IconSVG src={chatIcon} width="15px" className={styles.gap} />
            Chat with us
          </Button>
        </div>
      </ProjectFormInfo>
    </>
  );
}

RepairPlanningForm.propTypes = {
  caseStatus: PropTypes.object,
  children: PropTypes.node,
};

export default RepairPlanningForm;

RepairPlanningForm.defaultProps = {
  caseStatus: null,
  children: null,
};
