import React from 'react';
import PropTypes from 'prop-types';
import Text from 'components/shared/Text';
import { useQuery } from '@apollo/react-hooks';
import { ROOF_PROJECT_MESSAGES } from 'graphql/queries/projects';
import Spinner from 'components/shared/Spinner';
import { formatDateTime } from 'utils/dateFormatters';
import styles from './style.module.css';

const Messages = ({ projectId }) => {
  const { loading, data } = useQuery(ROOF_PROJECT_MESSAGES, {
    variables: { parentId: projectId },
  });

  if (loading)
    return (
      <div className={styles.tabContentSpinner}>
        <Spinner />
      </div>
    );

  if (!data?.messages?.length)
    return (
      <div className={styles.tabContentEmpty}>
        <Text color="#000" size="s16">
          You don't have any messages yet
        </Text>
      </div>
    );

  return (
    <div className={styles.tabContent}>
      {data?.messages?.map(({ title, body, type, createdDate }, sectionIndex) => {
        return (
          <div className={styles.messageWrapper} key={sectionIndex}>
            <div className={styles.messageTitle}>
              <Text color="#000000" weight="wb" size="s16">
                {type}
              </Text>{' '}
              <Text color="#000000" weight="wb" size="s14">
                {formatDateTime(createdDate)}
              </Text>
              <div className={styles.wrapperBlockLine} />
            </div>
            <div>
              {/* {title && (
                <Text color="#000000" weight="wb" size="s14" tag="p">
                  Title: {title}
                </Text>
              )} */}
              {body && (
                <Text color="#000000" weight="wr" size="s14" tag="p" lineHeight="h18">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: body
                        .replace('CUST-', '')
                        .replace('CUST -', '')
                        .replace('CUST', ''),
                    }}
                  />
                </Text>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
Messages.propTypes = {
  projectId: PropTypes.string.isRequired,
};

function MessagesTabContent({ projectId }) {
  if (!projectId)
    return (
      <div className={styles.tabContentEmpty}>
        <Text color="#000" size="s16">
          You don't have any messages yet
        </Text>
      </div>
    );

  return <Messages projectId={projectId} />;
}

MessagesTabContent.propTypes = {
  projectId: PropTypes.string,
};

MessagesTabContent.defaultProps = {
  projectId: '',
};

export default MessagesTabContent;
