import React, { useState, useContext, useEffect } from 'react';
import FormWrapper from 'components/Auth/FormWrapper';
import { Formik } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import { signUpFirstStepValidationSchema } from 'validation';
import { FirstStepSignUp, SignUpHeader as Header } from 'components/Auth/SignUp';
import { SIGNUP_MUTATION } from 'graphql/mutations/auth';
import ConfirmEmailComponent from 'components/Auth/ConfirmEmail';
import MobileContext from 'context/MobileContext';
import { formatAuthMessage } from 'utils/graphQLErrorFormatters';
import { authErrors } from '../enums/errors/authErrors';

const SignUpForm = () => {
  const [signUpUser, { loading: isLoading, error }] = useMutation(SIGNUP_MUTATION);
  const [isFirstStep, setIsFirstStep] = useState(true);
  const [isAddressError, setIsAddressError] = useState(false);
  const [isEmailError, setIsEmailError] = useState(false);
  const [email, setEmail] = useState('');

  const isMobile = useContext(MobileContext);

  const handleSubmit = async (values) => {
    setEmail(values.email);
    await signUpUser({
      variables: {
        data: {
          ...values,
          mobilePhone: values.mobilePhone.slice(1),
          phoneNumber: values.phoneNumber.slice(1),
        },
      },
      update: () => {
        setIsFirstStep(false);
      },
    });
  };

  useEffect(() => {
    setIsAddressError(
      error && formatAuthMessage(error?.message) === authErrors.notUniqueAddress
    );
    setIsEmailError(error && formatAuthMessage(error?.message) === 'notUniqueEmail');
  }, [error]);

  return (
    <FormWrapper header={isFirstStep && !isMobile ? <Header /> : null}>
      {isFirstStep ? (
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            phoneNumber: '',
            mobilePhone: '',
            email: '',
            city: '',
            state: '',
            propertyAddress: '',
            addressPlaceID: '',
            latitude: '',
            longitude: '',
            country: '',
            street: '',
            zipCode: '',
            unsubscribed: false,
            agreesWithTerms: false,
          }}
          validationSchema={signUpFirstStepValidationSchema}
          validateOnMount
          onSubmit={handleSubmit}
        >
          {(formikProps) => {
            const handleChangeAddress = () => {
              setIsAddressError(false);
            };

            const handleChangeEmail = (event) => {
              formikProps.handleChange(event);
              setIsEmailError(false);
            };
            return (
              <fieldset disabled={isLoading}>
                <FirstStepSignUp
                  {...formikProps}
                  handleChangeAddress={handleChangeAddress}
                  handleChangeEmail={handleChangeEmail}
                  isMobile={isMobile}
                  isLoading={isLoading}
                  userExistError={isEmailError}
                  addressExistError={isAddressError}
                />
              </fieldset>
            );
          }}
        </Formik>
      ) : (
        <ConfirmEmailComponent isCreatePassword email={email} />
      )}
    </FormWrapper>
  );
};

export default SignUpForm;
