import React, { useContext } from 'react';
import MobileContext from 'context/MobileContext';
import PropTypes from 'prop-types';
import Button from 'components/shared/Button';
import styles from './style.module.css';
import { MobileButton } from '../../Footer/buttons';

export const FooterButtons = ({
  handleCancel,
  isHideCancel,
  handleNextStep,
  isAvailableNext,
  isDisabled,
}) => {
  const isMobile = useContext(MobileContext);

  if (!isMobile)
    return (
      <div className={styles.footerTabWrapper}>
        <div className={styles.footerTab}>
          {!isHideCancel && (
            <Button
              size="small"
              color="white"
              className={styles.primaryButton}
              handleClick={handleCancel}
            >
              Save as draft
            </Button>
          )}

          <MobileButton isNext onClick={handleNextStep} disabled={!isAvailableNext} />
        </div>
      </div>
    );

  return (
    <div
      className={
        isAvailableNext ? styles.footerTabWrapper : styles.projectFormFooterBottom
      }
    >
      <div className={styles.footerTab}>
        {!isAvailableNext ? (
          <Button
            size="small"
            color="white"
            handleClick={handleCancel}
            disabled={isDisabled}
          >
            Save as draft
          </Button>
        ) : (
          <>
            <div />
            <MobileButton isNext onClick={handleNextStep}>
              Next
            </MobileButton>
          </>
        )}
      </div>
    </div>
  );
};

FooterButtons.propTypes = {
  handleCancel: PropTypes.func,
  handleNextStep: PropTypes.func.isRequired,
  isAvailableNext: PropTypes.bool,
  isHideCancel: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

FooterButtons.defaultProps = {
  handleCancel: () => {},
  isHideCancel: false,
  isAvailableNext: false,
  isDisabled: false,
};
