import gql from 'graphql-tag';

export const Warranty = {
  fragments: {
    Warranty: gql`
      fragment Warranty on Warranty {
        pgId
        sfId
        description
        status
        roofInstallDate
        dateOfInstall
        resolutionNotes
        repairDateTime
        caseNumber
        subject
        opportunityPgId
      }
    `,
  },
};
