import React from 'react';
import PropTypes from 'prop-types';
import Field from 'components/shared/Field';
import Checkbox from 'components/shared/CheckBox';
import Text from 'components/shared/Text';
import { FormRow as Row, FormColumn as Col } from 'components/shared/Form';
import { flagIcon } from 'assets/icons';
import Icon, { secondaryIconProps } from 'components/shared/IconSVG';
import styles from '../../../style.module.css';

function PropertyDetailsForm({
  values = [],
  handleChange,
  errors = [],
  touched = [],
  options = {},
  isDisabled,
  address,
  isLoadingOptions,
}) {
  // const filteredOptions = options.roofTypes.filter(
  //   ({ value }) => value !== 'Architectural'
  // );
  const filteredOptions = options.roofTypes;

  return (
    <div>
      <Col>
        <Icon src={flagIcon} {...secondaryIconProps} />
        <Text color="#000" weight="wb" size="s14">
          {address}
        </Text>
      </Col>
      <Row>
        <Col>
          <Text color="#000" size="s14" weight="wm">
            Does your property require a gate code?
          </Text>
          <div className={styles.projectFormColumn}>
            <div className={styles.projectFormCheckbox}>
              <Checkbox
                text="Yes"
                name="isGateCodeRequired"
                checked={values.isGateCodeRequired === true}
                isError={
                  touched?.isGateCodeRequired && Boolean(errors?.isGateCodeRequired)
                }
                onChange={() =>
                  handleChange({ target: { name: 'isGateCodeRequired', value: true } })
                }
              />
            </div>
            <div>
              <Checkbox
                text="No"
                name="isGateCodeRequired"
                checked={values.isGateCodeRequired === false}
                isError={
                  touched?.isGateCodeRequired && Boolean(errors?.isGateCodeRequired)
                }
                onChange={() => {
                  handleChange({ target: { name: 'isGateCodeRequired', value: false } });
                  handleChange({ target: { name: 'gateCode', value: '' } });
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
      {values.isGateCodeRequired && (
        <Row>
          <Col>
            <Field
              name="gateCode"
              type="text"
              label="Gate Code"
              value={values.gateCode}
              onChange={handleChange}
              isError={touched?.gateCode && Boolean(errors?.gateCode)}
            />
          </Col>
        </Row>
      )}
      {/* <Row>
        <Col>
          <Field
            name="existingShingleType"
            type="select"
            label="Existing Roof Type"
            isSearchable
            isDisabled={isDisabled}
            isLoading={isLoadingOptions}
            options={filteredOptions}
            value={values.existingShingleType}
            onChange={handleChange}
            isError={touched?.existingShingleType && Boolean(errors?.existingShingleType)}
          />
        </Col>
      </Row> */}
      <div>
        <Text color="#000" tag="p" weight="wb" size="s14">
          Is the roof tarped?
        </Text>
        <Text color="#000" size="s13" weight="wm">
          Note: If your roof is tarped, it will need to be removed for a thorough damage
          assessment.
        </Text>
        <div className={styles.projectFormColumn}>
          <div className={styles.projectFormCheckbox}>
            <Checkbox
              text="Yes"
              name="isRoofTarped"
              checked={values.isRoofTarped === true}
              isError={touched?.isRoofTarped && Boolean(errors?.isRoofTarped)}
              onChange={() =>
                handleChange({ target: { name: 'isRoofTarped', value: true } })
              }
            />
          </div>
          <div>
            <Checkbox
              text="No"
              name="isRoofTarped"
              checked={values.isRoofTarped === false}
              isError={touched?.isRoofTarped && Boolean(errors?.isRoofTarped)}
              onChange={() =>
                handleChange({ target: { name: 'isRoofTarped', value: false } })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

PropertyDetailsForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object,
  touched: PropTypes.object,
  options: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  address: PropTypes.string,
  isLoadingOptions: PropTypes.bool,
};

PropertyDetailsForm.defaultProps = {
  errors: {},
  touched: {},
  address: '',
  isLoadingOptions: false,
};

export default PropertyDetailsForm;
