import { useQuery } from '@apollo/react-hooks';
import { DOWNLOAD_FILES_NAME } from 'graphql/queries/file';

export default ({ opportunityId, documentCategory }) => {
  const { data, loading } = useQuery(DOWNLOAD_FILES_NAME, {
    variables: {
      opportunityId,
      documentCategory,
    },
  });

  return {
    loading,
    data,
  };
};
