import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.css';
import Note from './NoteScale';

const notes = [
  {
    value: 'A',
    name: 'A',
    color: 'green',
  },
  {
    value: 'B',
    name: 'B',
    color: 'greenLight',
  },
  {
    value: 'C',
    name: 'C',
    color: 'yellow',
  },
  {
    value: 'D',
    name: 'D',
    color: 'orange',
  },
  {
    value: 'E',
    name: 'E',
    color: 'red',
  },
];

const RatingScale = ({ handleChange, defaultValue }) => {
  const [ratingValue, setRatingValue] = useState(defaultValue);

  const handleSelect = (value) => {
    setRatingValue(value);
    handleChange(value);
  };

  return (
    <div className={styles.ratingScale}>
      {notes.map((noteProps) => {
        const isSelected = noteProps.value === ratingValue;

        return (
          <Note
            {...noteProps}
            handleClick={handleSelect}
            key={noteProps.value}
            isSelected={isSelected}
          />
        );
      })}
    </div>
  );
};

RatingScale.propTypes = {
  handleChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string.isRequired,
};

export default RatingScale;
