import React, { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { MY_PROJECTS } from 'graphql/queries/projects';
import Spinner from 'components/shared/Spinner';
import LayoutWithSidebar from 'layouts/LayoutWithSidebar';
import WithError from './withError';

export default (WrappedComponent, wrapperProps) => {
  return (props) => {
    const { loading, data, error, refetch } = useQuery(MY_PROJECTS, {
      notifyOnNetworkStatusChange: true,
    });

    useEffect(() => {
      if (!loading && !wrapperProps?.isCurrentProject) refetch();
    }, []);

    if (loading)
      return (
        <LayoutWithSidebar loading={loading}>
          <Spinner />
        </LayoutWithSidebar>
      );

    return (
      <WithError isShow={!!error}>
        <WrappedComponent {...props} data={data || {}} />
      </WithError>
    );
  };
};
