import gql from 'graphql-tag';

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    user @client {
      account {
        address
        gateCode
        billingCity
        billingCountry
        billingGeoCodeAccuracy
        billingLatitude
        billingLongitude
        billingPostalCode
        billingState
        billingStreet
        loanNumber
        gateCode
        name
        parentId
        pgId
        type
        insuranceCompany {
          sfId
          name
          loanNumber
          address
        }
        mortgageCompany {
          sfId
          name
          loanNumber
          address
        }
        contacts {
          pgId
          sfId
          firstName
          lastName
          phone
          mobilePhone
          email
          alternateEmail
        }
      }
      accountPgId
      accountSfId
      alternateEmail
      contactType
      contractorStatus
      email
      firstName
      lastName
      name
      pgId
      sfId
      phone
      mobilePhone
      isFirstLogin
    }
  }
`;
export const GET_PROJECTS = gql`
  query GetProjects {
    roofProjects @client {
      address
      installDate
    }
  }
`;

export const GET_CONTACT = gql`
  query GetCONTACT {
    contact @client {
      id
      pgId
      firstName
      lastName
      phoneNumber
      email
      propertyAddress
      addressPlaceID
      city
      state
      zipCode
      isFirstLogin
    }
  }
`;

export const GET_ROOF_PROJECT = gql`
  query GetRoofProject($id: String) {
    roofProject(id: $id) @client
  }
`;

export const GET_DRONE_REPORT = gql`
  query GetDroneReport($id: String) {
    droneReport(id: $id) @client
  }
`;
