import { useLazyQuery } from '@apollo/react-hooks';
import { DOWNLOAD_FILES_DATA } from 'graphql/queries/file';

export default () => {
  const [downloadFilesData, { data, loading }] = useLazyQuery(DOWNLOAD_FILES_DATA, {
    onCompleted: () => {
      const link = document.createElement('a');
      link.setAttribute(
        'href',
        `data:${data?.downloadFilesData?.type};base64,${data?.downloadFilesData?.base64String}`
      );
      link.setAttribute('download', data?.downloadFilesData?.name);
      link.click();
    },
  });

  return {
    handleDownload: downloadFilesData,
    isLoading: loading,
    data,
  };
};
