import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Field from 'components/shared/Field';
import MobileContext from 'context/MobileContext';
import Button from 'components/shared/Button';
import Text from 'components/shared/Text';
import Link from 'components/shared/Link';
import { FormColumn, FormFooter } from 'components/shared/Form';
import zxcvbn from 'zxcvbn';
import styles from './style.module.css';
import PasswordStrength from '../PasswordStrength';

const CreatePasswordComponent = ({
  handleSubmit = () => {},
  values,
  errors,
  handleChange,
  networkError,
}) => {
  const isMobile = useContext(MobileContext);
  return (
    <form className={styles.wrapper}>
      <Text color="#00000D" weight="wb" size="s24" lineHeight="h22">
        Create Password
      </Text>
      <div className={styles.wrapperBody}>
        <div className={styles.wrapperContent}>
          <Text color="#292929" size="s14" lineHeight="h20" tag="p">
            Your password must be a minimum of 6 characters long. We suggest it contains
            at least one capital letter, one number and a special character.
          </Text>
        </div>
        {networkError ? (
          <FormColumn>
            <Text size="s16" lineHeight="l14" color="#f44336">
              {networkError}
            </Text>
          </FormColumn>
        ) : null}
        <FormColumn>
          <Field
            type="password"
            label="Create New Password"
            name="password"
            onChange={handleChange}
            autoComplete="password"
            value={values.password}
            isError={values.password && Boolean(errors.password)}
          />
        </FormColumn>
        <FormColumn>
          <Field
            errorMessage={errors.repeatedPassword}
            type="password"
            label="Repeat New Password"
            name="repeatedPassword"
            autoComplete="password"
            value={values.repeatedPassword}
            isError={values.repeatedPassword && Boolean(errors.repeatedPassword)}
            onChange={handleChange}
          />
        </FormColumn>
        <FormColumn>
          {values.password.length > 0 ? (
            <PasswordStrength strength={zxcvbn(values.password)} />
          ) : null}
        </FormColumn>
      </div>

      <FormFooter>
        <div>
          <div className={styles.wrapperContent}>
            <Button
              size={isMobile ? 'large' : 'medium'}
              handleClick={handleSubmit}
              disabled={Boolean(Object.keys(errors).length)}
            >
              Save
            </Button>

            <br />
            {isMobile ? (
              <Link href="/sign-in" bold>
                Cancel
              </Link>
            ) : (
              <div>
                <Text color="#000">Already have an account? </Text>
                <Link href="/sign-in" color="secondary" bold>
                  Sign in
                </Link>
              </div>
            )}
          </div>
        </div>
      </FormFooter>
    </form>
  );
};

CreatePasswordComponent.propTypes = {
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
  networkError: PropTypes.string,
  values: PropTypes.object.isRequired,
};

export default CreatePasswordComponent;

CreatePasswordComponent.defaultProps = {
  handleSubmit: () => {},
  networkError: '',
};
