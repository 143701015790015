import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Text from 'components/shared/Text';
import Button from 'components/shared/Button';
import { useSwipeable } from 'react-swipeable';
import Icon from 'components/shared/IconSVG';
import { shapeTopIcon } from 'assets/icons';
import styles from './styles.module.css';

const DronPackageMobile = ({ handleGoNextStep, setDronePackage }) => {
  const packegeList = [
    {
      name: 'Platinum',
      price: '399',
      className: [styles.platinum, styles.platinumActive],
      description: [
        {
          title: 'Claims Report:',
          text:
            'Itemized scope of work with replacement cost values based on contractor & insurance industry standard software, Xactimate, zip code specific pricing',
        },

        {
          title: 'Measurement Report:',
          text:
            'Complete measurements of the entire roof, including total area, area by pitch, ridge, hip, valley and more',
        },
        {
          title: 'Damage Report:',
          text:
            'Zoomed-out view giving overview of damage as well as zoomed-in details of damage with facet location.',
        },
      ],
    },
    {
      className: [styles.gold],
      name: 'Gold',
      price: '299',
      description: [
        {
          title: 'Measurement Report:',
          text:
            'Complete measurements of the entire roof, including total area, area by pitch, ridge, hip, valley and more',
        },
        {
          title: 'Damage Report:',
          text:
            'Zoomed-out view giving overview of damage as well as zoomed-in details of damage with facet location.',
        },
      ],
    },
    {
      className: [styles.silver],
      name: 'Silver',
      price: '199',
      description: [
        {
          title: 'Damage Report:',
          text:
            'Zoomed-out view giving overview of damage as well as zoomed-in details of damage with facet location.',
        },
      ],
    },
  ];

  function choosePackage(value) {
    setDronePackage(value);
    handleGoNextStep();
  }

  const platinumRef = useRef(null);
  const silverRef = useRef(null);

  let platinumLocation = null;

  const handlers = useSwipeable({
    onSwipedRight: () => {
      platinumLocation = platinumRef.current.getBoundingClientRect().x;
      platinumRef.current.classList.remove(
        platinumLocation > -290 ? styles.secondPosition : styles.thirdPosition
      );
    },
    onSwipedLeft: () => {
      platinumLocation = platinumRef.current.getBoundingClientRect().x;
      platinumRef.current.classList.add(
        platinumLocation > 10 ? styles.secondPosition : styles.thirdPosition
      );
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const handlePackageNameClick = (name) => {
    platinumLocation = platinumRef.current.getBoundingClientRect().x;

    if (platinumLocation > 10) {
      switch (name) {
        case 'Platinum':
          return;
        case 'Gold':
          platinumRef.current.classList.add(styles.secondPosition);
          break;
        case 'Silver':
          platinumRef.current.classList.add(styles.secondPosition, styles.thirdPosition);
          break;
        default:
      }
    }
    if (platinumLocation > -270) {
      switch (name) {
        case 'Platinum':
          platinumRef.current.classList.remove(styles.secondPosition);
          break;
        case 'Gold':
          return;
        case 'Silver':
          platinumRef.current.classList.add(styles.thirdPosition);
          break;
        default:
      }
    }
    if (platinumLocation < -270) {
      switch (name) {
        case 'Platinum':
          platinumRef.current.classList.remove(
            styles.secondPosition,
            styles.thirdPosition
          );
          break;
        case 'Gold':
          platinumRef.current.classList.remove(styles.thirdPosition);
          break;
        case 'Silver':
          break;
        default:
      }
    }
  };

  return (
    <div className={styles.packageContainerMobile} {...handlers}>
      <div className={styles.separationLine} />

      {packegeList.map(({ name, price, description, className }) => (
        <div
          className={className.join(' ')}
          key={name}
          ref={name === 'Platinum' ? platinumRef : silverRef}
        >
          <div className={styles.packageType}>
            <div className={styles.partShape} />
            <Icon src={shapeTopIcon} width="100%" className={styles.shapeIcon} />
            <div className={styles.textContainer}>
              <Text color="#000" weight="wb" size="s21" tag="h2" lineHeight="h26">
                {name}
              </Text>
              <div className={styles.whiteLine} />
              <Text color="#000" weight="wb" size="s21" tag="h2" lineHeight="h26">
                ${price}
              </Text>
            </div>
          </div>
          <div className={styles.descriptionContainer}>
            {description.map(({ title, text }) => (
              <div className={styles.description} key={title}>
                <Text color="#000" weight="wb" size="s14" tag="h2" lineHeight="h18">
                  {title}
                </Text>
                <Text color="#000" weight="wr" size="s16" tag="p" lineHeight="h18">
                  {text}
                </Text>
              </div>
            ))}
          </div>
          <Text
            color="#837F7D"
            weight="wк"
            size="s12"
            tag="з"
            lineHeight="h16"
            className={styles.bottomText}
          >
            If you choose to purchase a roof replacement, this payment will be applied
            toward your roof cost.
          </Text>
          <Button
            size="larger"
            handleClick={() => choosePackage([name, price])}
            className={styles.buttonStarted}
          >
            Get Started!
          </Button>
        </div>
      ))}
      <div className={styles.packegeNameContainer}>
        {packegeList.map(({ name }) => (
          <Text
            color="#837F7D"
            weight="wr"
            size="s17"
            tag="h2"
            lineHeight="h19"
            className={name === 'Gold' ? styles.centerName : styles.packageName}
            onClick={() => handlePackageNameClick(name)}
            key={name}
          >
            {name}
          </Text>
        ))}
      </div>
    </div>
  );
};
DronPackageMobile.propTypes = {
  currentTabIndex: PropTypes.number.isRequired,
  handleGoNextStep: PropTypes.func,
  setCurrentTabIndex: PropTypes.func.isRequired,
  setDronePackage: PropTypes.func.isRequired,
};
export default DronPackageMobile;

DronPackageMobile.defaultProps = {
  handleGoNextStep: () => {},
};
