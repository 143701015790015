import gql from 'graphql-tag';

export const CREATE_WARRANTY_MUTATION = gql`
  mutation CREATE_WARRANTY_MUTATION($data: WarrantyData!) {
    createWarranty(data: $data) {
      pgId
      description
      status
      roofInstallDate
      caseNumber
      subject
    }
  }
`;
