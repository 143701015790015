import { useEffect, useState } from 'react';

const defaultSettings = {
  enableHighAccuracy: true,
  timeout: Infinity,
  maximumAge: 0,
};

const useGeoposition = () => {
  const [coords, setCoords] = useState(null);

  const onChange = ({ coords: _coords }) => {
    setCoords({
      lat: _coords.latitude,
      lng: _coords.longitude,
    });
  };

  useEffect(() => {
    if (!navigator?.geolocation) {
      return;
    }
    navigator.geolocation.getCurrentPosition(onChange, () => {}, defaultSettings);
  }, []);

  return { coords, setCoords };
};

export default useGeoposition;
