import React from 'react';
import PropTypes from 'prop-types';
import Text from 'components/shared/Text';
import Spinner from 'components/shared/Spinner';
import Attachment from 'containers/DownloadAttachmentContainer';
import documentStyles from '../../style.module.css';

function ServiceDoc({ load, files }) {
  return (
    <>
      <Text
        color="#000"
        weight="wb"
        size="s16"
        tag="h2"
        lineHeight="h18"
        className={documentStyles.title}
      >
        Drone Report Documents
      </Text>
      {files?.length ? (
        <div className={documentStyles.documentFormContainer}>
          {files?.map((file) => {
            return (
              <div
                className={documentStyles.documentWrapper}
                key={file.contentDocumentId}
              >
                <Attachment {...file} />
              </div>
            );
          })}
        </div>
      ) : null}
      {!files?.length && !load ? (
        <Text
          color="#000"
          weight="wr"
          size="s16"
          tag="h2"
          lineHeight="h18"
          className={documentStyles.title}
        >
          No files yet
        </Text>
      ) : null}
      {load ? <Spinner /> : null}
    </>
  );
}

ServiceDoc.propTypes = {
  load: PropTypes.bool.isRequired,
  files: PropTypes.array,
};

ServiceDoc.defaultProps = {
  files: [],
};

export default ServiceDoc;
