import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Field from 'components/shared/Field';
import Checkbox from 'components/shared/CheckBox';
import Text from 'components/shared/Text';
import { FormRow, FormColumn } from 'components/shared/Form';
import AppConfigContext from 'context/AppConfigContext';
import styles from '../../../style.module.css';

function MortgageCompanyForm({
  values = [],
  handleChange,
  errors = [],
  touched = [],
  options = {},
  isDisabled,
  isLoadingOptions,
}) {
  const appConfig = useContext(AppConfigContext);
  return (
    <div>
      <FormColumn>
        <Text color="#000" size="s14" weight="wm">
          Do you have a mortgage loan on this property?
        </Text>
        <div className={styles.projectFormColumn}>
          <div className={styles.projectFormCheckbox}>
            <Checkbox
              disabled={isDisabled}
              text="Yes"
              name="isIncludeMortgageCompany"
              checked={values.isIncludeMortgageCompany === true}
              isError={
                touched?.isIncludeMortgageCompany &&
                Boolean(errors?.isIncludeMortgageCompany)
              }
              onChange={() =>
                handleChange({
                  target: { name: 'isIncludeMortgageCompany', value: true },
                })
              }
            />
          </div>
          <div>
            <Checkbox
              text="No"
              disabled={isDisabled}
              name="isIncludeMortgageCompany"
              checked={values.isIncludeMortgageCompany === false}
              isError={
                touched?.isIncludeMortgageCompany &&
                Boolean(errors?.isIncludeMortgageCompany)
              }
              onChange={async () => {
                await handleChange({
                  target: { name: 'isIncludeMortgageCompany', value: false },
                });

                handleChange({
                  target: { name: 'mortgageCompanyId', value: '' },
                });
                handleChange({
                  target: { name: 'loanNumber', value: '' },
                });
              }}
            />
          </div>
        </div>
      </FormColumn>
      {values.isIncludeMortgageCompany && (
        <FormRow>
          <FormColumn>
            <Field
              name="mortgageCompanyId"
              type="select"
              label="Mortgage Company"
              isFullValue
              isDisabled={isDisabled}
              isLoading={isLoadingOptions}
              options={options.mortgageCompanies}
              value={values.mortgageCompanyId}
              onChange={({ target }) => {
                handleChange({
                  target: { name: 'mortgageCompanyId', value: target.value?.value },
                });
                if (target.value?.secondValue)
                  handleChange({
                    target: { name: 'loanNumber', value: target.value.secondValue },
                  });
              }}
              isError={touched?.mortgageCompanyId && Boolean(errors?.mortgageCompanyId)}
            />
          </FormColumn>
          <FormColumn>
            <Field
              name="loanNumber"
              type="text"
              label="Loan Number"
              isDisabled={isDisabled}
              value={values.loanNumber}
              onChange={handleChange}
              isError={touched?.loanNumber && Boolean(errors?.loanNumber)}
            />
          </FormColumn>
        </FormRow>
      )}
      {appConfig?.mortgageCompanyHint && (
        <FormColumn>
          <Text color="#000" weight="wm" size="s14">
            {appConfig.mortgageCompanyHint}
          </Text>
        </FormColumn>
      )}
    </div>
  );
}

MortgageCompanyForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object,
  touched: PropTypes.object,
  options: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isLoadingOptions: PropTypes.bool,
};

MortgageCompanyForm.defaultProps = {
  errors: {},
  touched: {},
  isLoadingOptions: false,
};

export default MortgageCompanyForm;
