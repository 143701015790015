import React, { useState, useContext } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  CONFIRM_SECOND_STAGE,
  HOA_APPROVAL_ACKNOWLEDGMENT,
} from 'graphql/mutations/projects';
import { DOWNLOAD_FILES_DESCRIPTION } from 'graphql/queries/file';
import { updateRoofProjectList } from 'graphql/cache/project';
import { damageAssessmentValidationSchema } from 'validation';
import roofProjectAttachmentTypes from 'enums/roofProjectAttachmentTypes';
import DamageAssessmentStep from 'components/Projects/RoofProjects/DamageAssessmentStep';
import AcknowledgmentModal from 'components/shared/modals/AcknowledgmentModal';
import useModal from 'services/hooks/useIsShowComponent';
import useLoadAttachments from 'services/hooks/loadAttachments';
import Spinner from 'components/shared/Spinner';
import AppConfigContext from 'context/AppConfigContext';
import { Footer as FooterButtons } from 'components/Projects/RoofProjects/Footer';
import MobileContext from 'context/MobileContext';
import moment from 'moment';

const modalText = `If HOA approval is required, please acknowledge that you have received it and 
uploaded the proper documents. If HOA approval is required and you have not received approval and have not uploaded the documents, the work cannot proceed.`;
const maxCountInsuranceDocuments = 5;

const inspectionStatuses = {
  pending: 'Inspection Pending',
  reinspectionPending: 'Reinspection Date Pending',
  deniedReinspection: 'Denied - Request for Reinspection',
};

const DamageAssessmentContainer = ({
  handleClose,
  handleNextStep,
  project,
  isConfirmStage,
  ...props
}) => {
  const isMobile = useContext(MobileContext);
  const appConfig = useContext(AppConfigContext);

  const isReinspectionDate =
    project.adjusterStatus === inspectionStatuses.reinspectionPending;

  const isDisabled = project.isSubmittedPlanning;

  const { isShow, handleClick: handleClickModal } = useModal();
  const { isShow: isShowSubmitModal, handleClick: handleClickSubmitModal } = useModal();

  const [
    hoaApprovalAcknowledgment,
    { loading: isLoadingApprovalAcknowledgment },
  ] = useMutation(HOA_APPROVAL_ACKNOWLEDGMENT);

  const [confirmSecondStageProject, { loading }] = useMutation(CONFIRM_SECOND_STAGE);
  const [handleChangeFieldStage] = useMutation(CONFIRM_SECOND_STAGE);

  const { data: serviceAttachments } = useLoadAttachments({
    opportunityId: project.pgId,
    documentCategory: 'Service',
  });

  const validationSchema = damageAssessmentValidationSchema({
    filesHOApproval: 5,
    maxCountInsuranceDocuments,
    isReinspectionDate,
  });

  const {
    data: { downloadFilesDescription } = { downloadFilesDescription: [] },
    loading: loadingAttachments,
  } = useQuery(DOWNLOAD_FILES_DESCRIPTION, {
    variables: {
      opportunitySfId: project.sfId,
    },
  });

  const handleCancel = () => {
    handleClose();
  };

  const handleApprovalAcknowledgment = async () => {
    hoaApprovalAcknowledgment({
      variables: {
        projectId: project.pgId,
      },
      update: () => {
        handleClickModal();
      },
    });
  };

  if (loading || loadingAttachments)
    return (
      <div style={{ position: 'absolute', width: '90%' }}>
        <Spinner />
      </div>
    );

  const initialValues = {
    filesHOApproval: downloadFilesDescription?.filter(
      (file) => file?.description === roofProjectAttachmentTypes.hoaFile
    ),

    insuranceAttachments: downloadFilesDescription?.filter(
      (file) => file?.description === roofProjectAttachmentTypes.insuranceFile
    ),
    hoaApproval: project.hoaApproval || 'N/A',
    productId: project.replacementShingleId,
    insuranceInspectionDate: project.adjusterMeetingDate
      ? project.adjusterMeetingDate
      : null,
    insuranceInspectionTime: project.adjusterMeetingDate
      ? moment(project.adjusterMeetingDate).toDate()
      : null,
    reinspectionDate: project.reinspectionDate,
  };

  const handleSubmit = async ({
    filesHOApproval,
    insuranceAttachments,
    insuranceInspectionDate,
    insuranceInspectionTime,
    ...stepValues
  }) => {
    const insuranceInspectionDateTime = moment(insuranceInspectionDate)
      .set('hours', insuranceInspectionTime.getHours())
      .set('minutes', insuranceInspectionTime.getMinutes());

    confirmSecondStageProject({
      variables: {
        data: {
          ...stepValues,
          insuranceInspectionDate: insuranceInspectionDateTime,
        },
        isSubmitted: true,
        projectId: project.pgId,
      },
      update: (cache, { data: { sendOpportunityPlaning } }) => {
        updateRoofProjectList({
          projectId: project.pgId,
          data: sendOpportunityPlaning,
        });
        handleClickSubmitModal();
      },
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {(formikProps) => {
        const handleChange = (event) => {
          formikProps.handleChange(event);
          handleChangeFieldStage({
            variables: {
              data: {
                [event.target.name]: event.target.value,
              },
              projectId: project.pgId,
            },
          });
        };

        const handleChangeDate = (event) => {
          formikProps.handleChange(event);
        };

        const handleChangeTime = (event) => {
          formikProps.handleChange(event);
        };
        const saveDateTime = () => {
          const selectedDate = formikProps.values.insuranceInspectionDate;

          const selectedTime = formikProps.values.insuranceInspectionTime;

          const insuranceInspectionDateTime = moment(selectedDate)
            .set('hours', selectedTime.getHours())
            .set('minutes', selectedTime.getMinutes());

          handleChangeFieldStage({
            variables: {
              data: { insuranceInspectionDate: insuranceInspectionDateTime },
              projectId: project.pgId,
            },
          });
        };

        const isDisabledSubmit =
          isDisabled || !validationSchema.isValidSync(formikProps.values);

        return (
          <>
            <div>
              <fieldset disabled={isDisabled}>
                <DamageAssessmentStep
                  {...formikProps}
                  {...props}
                  project={project}
                  handleChange={handleChange}
                  handleChangeDate={handleChangeDate}
                  handleChangeTime={handleChangeTime}
                  saveDateTime={saveDateTime}
                  handleChangeFiles={formikProps.handleChange}
                  isDisabled={isDisabled}
                  inspectionDocuments={serviceAttachments?.downloadFilesName}
                  isCloseCollapses={isMobile && isConfirmStage}
                  isReinspectionDate={isReinspectionDate}
                  handleChangeIsApproved={(value) => {
                    if (value === 'No') return handleClickModal();

                    return handleChange({
                      target: { name: 'hoaApproval', value },
                    });
                  }}
                  maxCountInsuranceDocuments={maxCountInsuranceDocuments}
                  isPaid={!project.deductibleAmount || project.deductiblePaid}
                />
              </fieldset>

              <fieldset disabled={loading}>
                <FooterButtons
                  handleCancel={handleCancel}
                  handleNextStep={handleNextStep}
                  submitButtonText="Submit"
                  handleSubmit={formikProps.handleSubmit}
                  isHideNext={!isConfirmStage}
                  isHideSubmit
                  isDisabledSubmit={isDisabledSubmit}
                />
              </fieldset>
            </div>

            <fieldset disabled={isLoadingApprovalAcknowledgment}>
              <AcknowledgmentModal
                handleClick={() => {
                  handleChange({ target: { name: 'hoaApproval', value: 'No' } });
                  handleApprovalAcknowledgment();
                }}
                handleClose={handleClickModal}
                isShow={isShow}
                description={modalText}
                isSmallSize
              />
            </fieldset>

            <AcknowledgmentModal
              handleClick={handleClickSubmitModal}
              handleClose={handleClickSubmitModal}
              description={appConfig.planningSubmitBodyText}
              buttonText={appConfig.planningSubmitButtonText}
              isShow={isShowSubmitModal}
              title=""
            />
          </>
        );
      }}
    </Formik>
  );
};

DamageAssessmentContainer.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleNextStep: PropTypes.func.isRequired,
  project: PropTypes.object,
  isConfirmStage: PropTypes.bool,
};

DamageAssessmentContainer.defaultProps = {
  project: {},
  isConfirmStage: false,
};

export default DamageAssessmentContainer;
