import React, { useState, useEffect, useContext } from 'react';
import { Formik } from 'formik';
import { useLazyQuery } from '@apollo/react-hooks';
import { PRODUCT_SELECTION_OPTIONS } from 'graphql/queries/projects';
import PropTypes from 'prop-types';
import AppConfigContext from 'context/AppConfigContext';
import AcknowledgmentModal from 'components/shared/modals/AcknowledgmentModal';
import productValidationSchema from 'validation/damageAssesment/product.validation';
import ProductSection from 'components/Projects/RoofProjects/DamageAssessmentStep/ProductSection';
import ReadProduct from 'components/Projects/RoofProjects/DamageAssessmentStep/ProductSection/ReadProduct';
import { CenterButton as Button } from 'components/Projects/RoofProjects/Footer';
import useModal from 'services/hooks/useIsShowComponent';
import roofTypes from 'enums/roofTypes';
import ContactUsButtons from 'components/Projects/RoofProjects/DamageAssessmentStep/ProductSection/ContactUsButtons';
import ReactGa from 'react-ga';

const productTypes = [
  {
    label: 'Shingle',
    value: '3-Tab',
  },
  // {
  //   label: 'Shingle',
  //   value: 'Architectural',
  // },
  {
    label: 'Tile',
    value: 'Tile',
  },
  {
    label: 'Metal',
    value: 'Metal',
  },
];

const ProductSelectionContainer = ({
  values: productValues,
  isDisabled,
  handleCancel: handleCloseForm,
  projectId,
  handleChange,
  productId,
  ...props
}) => {
  const appConfig = useContext(AppConfigContext);

  const [getOptions, { data, loading: isLoadingOptions }] = useLazyQuery(
    PRODUCT_SELECTION_OPTIONS
  );
  const [isCreateProductSelection, setIsCreateProductSelection] = useState(!productId);
  const { isShow: isOpenModal, handleClick } = useModal();
  const [selectedRoofType, setSelectedRoofType] = useState(null);

  const filteredProductOptions = data?.productOptions?.stoneMetalPanelTypes.filter(
    ({ offered }) => offered
  );

  const options = { stoneMetalPanelTypes: filteredProductOptions, productTypes };

  useEffect(() => {
    if (productValues.productType && isCreateProductSelection) {
      getOptions({
        variables: {
          productType: productValues.productType,
        },
      });
    }
  }, []);

  const handleSubmit = async ({ __typename, ...values }) => {
    ReactGa.event({
      category: "Roof Project",
      action: "Pressed the Confirm the selection button from the Project Planning tab",
      label: "Confirm the selection"
    });
    handleChange(values.stoneMetalPanelType.value);
    setIsCreateProductSelection(false);
  };

  const handleModifyProductSelection = () => {
    getOptions({
      variables: {
        productType: productValues.productType,
      },
    });
    handleChange(undefined);
    setIsCreateProductSelection(true);
  };

  const handleCloseModal = () => {
    handleClick();
    setSelectedRoofType(null);
  };

  return (
    <>
      <Formik
        initialValues={{
          productType: productValues.productType,
          stoneMetalPanelType: productValues.stoneMetalPanelType,
        }}
        validationSchema={productValidationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => {
          if (isCreateProductSelection)
            return (
              <div>
                <ProductSection
                  {...formikProps}
                  {...props}
                  options={options}
                  isDisabled={isDisabled}
                  isLoadingOptions={isLoadingOptions}
                  handleShowProductDetails={() => {
                    window.open(appConfig.linkProductDetailsFile, '_blank');
                  }}
                  handleChangeRoofType={(event) => {
                    const selectedProductType = event.target.value;
                    if (
                      productValues?.productType &&
                      productValues.productType !== selectedProductType
                    ) {
                      setSelectedRoofType(selectedProductType);
                      return handleClick();
                    }

                    getOptions({
                      variables: {
                        productType: selectedProductType,
                      },
                    });
                    return formikProps.setValues({
                      productType: selectedProductType,
                    });
                  }}
                />
                <Button handleClick={formikProps.handleSubmit} isDisabled={isDisabled}>
                  Confirm the selection
                </Button>
              </div>
            );

          return (
            <ReadProduct
              values={{
                productType: formikProps.values.productType,
                stoneMetalPanelType: formikProps.values.stoneMetalPanelType?.label,
              }}
              handleModify={handleModifyProductSelection}
              isDisabled={isDisabled}
            />
          );
        }}
      </Formik>
      <AcknowledgmentModal
        title="Product Selection"
        description={`It looks like your existing roof type is "${roofTypes[productValues?.productType]
          }" and you just 
       selected "${roofTypes[selectedRoofType]
          }" as the product type. If you are interested in an upgrade, 
       please contact us.`}
        handleClick={handleCloseModal}
        handleClose={handleCloseModal}
        isShow={isOpenModal}
        footer={<ContactUsButtons />}
      />
    </>
  );
};

ProductSelectionContainer.propTypes = {
  values: PropTypes.object,
  isDisabled: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func,
  projectId: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  productId: PropTypes.string,
};

ProductSelectionContainer.defaultProps = {
  values: {},
  handleCancel: () => { },
  productId: '',
};

export default ProductSelectionContainer;
