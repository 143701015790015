import gql from 'graphql-tag';
import { Warranty } from 'graphql/fragments';

export const GET_WARRANTIES = gql`
  query getWarranties {
    getWarranties {
      ...Warranty
    }
  }
  ${Warranty.fragments.Warranty}
`;
