import React, { useState, useContext } from 'react';
import AccountDetails from 'components/MyAccount/AccountDetails';
import ChatTranscripts from 'components/MyAccount/ChatTranscripts';
import Tabs from 'components/shared/Tabs/Desktop';
import MobileTabs from 'components/shared/Tabs/Mobile';
import MobileContext from 'context/MobileContext';
import LayoutWithSidebar from 'layouts/LayoutWithSidebar';
import withLoadProjects from 'services/HOCs/withLoadProjects';
import { useQuery } from '@apollo/react-hooks';
import { GET_CURRENT_USER } from 'graphql/cache';
import styles from '../CreateWarrantyPage/style.module.css';
import SettingsPage from '../../components/MyAccount/Settings';

const MyAccountPage = () => {
  const isMobile = useContext(MobileContext);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const { data } = useQuery(GET_CURRENT_USER);

  const tabList = [
    {
      title: 'Account Details',
      children: (
        <div className={styles.projectWrapper}>
          <AccountDetails user={data?.user} />
        </div>
      ),
    },
    {
      title: 'Settings',
      children: (
        <div className={styles.projectWrapper}>
          <SettingsPage />
        </div>
      ),
    },
    // {
    //   title: 'Chat Transcripts',
    //   children: (
    //     <div className={styles.projectWrapper}>
    //       <ChatTranscripts user={data?.user} />
    //     </div>
    //   ),
    // },
  ];

  return (
    <LayoutWithSidebar>
      {isMobile ? (
        <MobileTabs
          tabList={tabList}
          currentTabIndex={currentTabIndex}
          handleClick={setCurrentTabIndex}
        />
      ) : (
        <Tabs
          tabList={tabList}
          currentTabIndex={currentTabIndex}
          handleClick={setCurrentTabIndex}
        />
      )}
    </LayoutWithSidebar>
  );
};

MyAccountPage.propTypes = {};

MyAccountPage.defaultProps = {};

export default withLoadProjects(MyAccountPage);
