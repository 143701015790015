import gql from 'graphql-tag';
import { Projects } from 'graphql/fragments';

export const MY_PROJECTS = gql`
  query myProjects {
    roofProjects {
      ...FullRoofProject
    }
    droneReports {
      pgId
      status
      price
      dronePackage
      scheduledDate
      sfId
      paid
    }
  }
  ${Projects.fragments.FullRoofProject}
`;

export const GET_ROOF_PROJECTS_LIST = gql`
  query myProjects {
    roofProjects {
      ...InsuranceClaimStage
    }
  }
  ${Projects.fragments.InsuranceClaimStage}
`;

export const ROOF_PROJECT_OPTIONS = gql`
  query RoofProjectOptions {
    insuranceCompanies {
      label
      value
      secondValue
    }
    mortgageCompanies {
      label
      value
      secondValue
    }
    roofTypes {
      label
      value
    }
  }
`;

export const PRODUCT_SELECTION_OPTIONS = gql`
  query ProductSelectionOptions($productType: String!) {
    productOptions(productType: $productType) {
      stoneMetalPanelTypes {
        value
        label
        secondValue
        offered
      }
    }
  }
`;

export const ROOF_PROJECT_MESSAGES = gql`
  query ROOF_PROJECT_MESSAGES($parentId: String!) {
    messages(parentId: $parentId, type: "opportunity") {
      sfId
      type
      title
      createdDate
      body
    }
  }
`;
