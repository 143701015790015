import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import arrowDownIcon from 'assets/icons/arrowDown.svg';
import Icon from 'components/shared/IconSVG';
import selectStyles from './selectStyles';
import styles from './style.module.css';

const DropdownIndicator = ({ selectProps }) => {
  const { menuIsOpen, isDisabled } = selectProps;

  if (isDisabled) return null;

  if (menuIsOpen)
    return (
      <Icon
        src={arrowDownIcon}
        width="12px"
        height="auto"
        className={[
          styles.selectIconDown,
          isDisabled ? styles.selectIconDisable : '',
        ].join(' ')}
      />
    );
  return (
    <Icon
      src={arrowDownIcon}
      width="12px"
      height="auto"
      className={[styles.selectIconUp, isDisabled ? styles.selectIconDisable : ''].join(
        ' '
      )}
    />
  );
};
DropdownIndicator.propTypes = {
  selectProps: PropTypes.object.isRequired,
};

const ReactSelect = ({
  options,
  value,
  name,
  defaultValue,
  icon,
  width = 'auto',
  height = '40px',
  onChange,
  placeholder,
  isDisabled,
  invalid,
  isSearchable = true,
  isFullValue,
  components,
  ...props
}) => {
  const getValue = () => {
    const dataFromOptions = options.filter((option) => {
      if (value instanceof Array) {
        return value.includes(option.value);
      }
      return option.value === value;
    });
    if (dataFromOptions.length !== 0) return dataFromOptions;
    if (typeof defaultValue === 'number') return options[defaultValue];
    return null;
  };

  const _handleChange = (event) => {
    const target = { name, value: isFullValue ? event : event?.value };
    if (props.isMulti) target.value = event?.map((ev) => (isFullValue ? ev : ev.value));
    onChange({ target });
  };

  return (
    <Select
      {...props}
      styles={{
        ...selectStyles,
        container: (base, state) => ({
          ...base,
          borderRadius: '2px',
          width: width || 'auto',
          height: height || '40px',
          border: state.isFocused
            ? '1px solid #aaabad'
            : invalid
            ? '1px solid #cf4b4b'
            : '1px solid #D8DADF',
          '&:focus': {
            outline: 'none',
          },
          '&:active': {
            outline: 'none',
          },
        }),
      }}
      components={{
        ...components,
        DropdownIndicator: icon || DropdownIndicator,
        IndicatorSeparator: () => null,
      }}
      options={options}
      value={getValue()}
      name={name}
      onChange={_handleChange}
      style={{ width, height }}
      placeholder={placeholder}
      isDisabled={isDisabled}
      isSearchable={isSearchable}
    />
  );
};

ReactSelect.propTypes = {
  defaultValue: PropTypes.string,
  height: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  components: PropTypes.node,
  invalid: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
  value: PropTypes.string,
  width: PropTypes.string,
  isSearchable: PropTypes.bool,
  isFullValue: PropTypes.bool,
};
ReactSelect.defaultProps = {
  defaultValue: '',
  components: null,
  height: '40px',
  icon: null,
  invalid: false,
  isDisabled: false,
  isMulti: false,
  name: 'select',
  onChange: () => {},
  options: [],
  placeholder: '',
  value: '',
  width: 'auto',
  isSearchable: true,
  isFullValue: false,
};
export default ReactSelect;
