import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import MobileContext from 'context/MobileContext';
import WrapperBlock from 'components/shared/WrapperBlock';
import Text from 'components/shared/Text';
import { createSecureNumber } from 'utils/createSecureNumber';
import styles from './styles.module.css';

function MortgageCompany({ mortgageCompanyData, isEmpty }) {
  const isMobile = useContext(MobileContext);
  const detailTitle = ['Mortgage Company Name', 'Loan Number'];
  const description = [
    mortgageCompanyData?.mortgageCompanyName || '',
    createSecureNumber(mortgageCompanyData?.loanNumber) || '',
  ];

  return (
    <div className={`${isEmpty && styles.isEmpty}`}>
      <WrapperBlock title="Mortgage Company">
        {detailTitle.map((title, index) => (
          <div className={styles.detailTitleContainer} key={title + index}>
            <Text
              tag="h2"
              size="s14"
              weight="wr"
              lineHeight="h13"
              color="#686868"
              className={styles.detailTitle}
            >
              {title}
            </Text>
            <Text
              tag="h2"
              size={isMobile ? 's13' : 's14'}
              weight={isMobile ? 'wb' : 'wr'}
              lineHeight="h13"
              color="000000"
              className={styles.description}
            >
              {description[index]}
            </Text>
          </div>
        ))}
      </WrapperBlock>
    </div>
  );
}

MortgageCompany.propTypes = {
  mortgageCompanyData: PropTypes.object.isRequired,
  isEmpty: PropTypes.bool,
};

MortgageCompany.defaultProps = {
  isEmpty: false,
};

export default MortgageCompany;
