import React from 'react';
import PropTypes from 'prop-types';
import Field from 'components/shared/Field';
import Checkbox from 'components/shared/CheckBox';
import Text from 'components/shared/Text';
import { FormRow as Row, FormColumn as Col } from 'components/shared/Form';
import Link from 'components/shared/Link';
import useSecondValue from 'services/hooks/useSecondValueSelect';
import styles from '../../../style.module.css';

function InsuranceCompanyForm({
  values = [],
  handleChange,
  errors = [],
  touched = [],
  options = {},
  isDisabled,
  isLoadingOptions,
  handleChangeInsuranceCompany,
}) {
  const { secondValue: insuranceCompanySite, handleChangeSecondValue } = useSecondValue(
    options.insuranceCompanies,
    values.insuranceCompanyId
  );

  return (
    <div>
      <Row>
        <Col>
          <Field
            name="insuranceCompanyId"
            label="Insurance Company"
            type="select"
            isLoading={isLoadingOptions}
            isDisabled={isDisabled}
            isFullValue
            options={options.insuranceCompanies}
            value={values.insuranceCompanyId}
            onChange={(event) => {
              handleChangeSecondValue(event.target.value.secondValue);
              handleChangeInsuranceCompany(event);
            }}
            isError={touched?.insuranceCompanyId && Boolean(errors?.insuranceCompanyId)}
          />
        </Col>
        <Col>
          <Field
            name="policyNumber"
            label="Policy Number"
            type="text"
            isDisabled={isDisabled}
            value={values.policyNumber}
            onChange={handleChange}
            isError={touched?.policyNumber && Boolean(errors?.policyNumber)}
          />
        </Col>
      </Row>
      {values.isRequiredOtherInsurance && (
        <Row>
          <Col>
            <Field
              name="otherInsurance"
              label="Please enter your Insurance Company Name"
              type="text"
              isDisabled={isDisabled}
              value={values.otherInsurance}
              onChange={handleChange}
              isError={touched?.otherInsurance && Boolean(errors?.otherInsurance)}
            />
          </Col>
          {isDisabled && !values.deductibleAmount ? null : (
            <Col>
              <Field
                type="number"
                label="What is your deductible amount?"
                name="deductibleAmount"
                min={0}
                value={values.deductibleAmount}
                onChange={handleChange}
                isError={touched.deductibleAmount && Boolean(errors.deductibleAmount)}
              />
            </Col>
          )}
        </Row>
      )}

      {(isDisabled && !values.deductibleAmount) ||
      values.isRequiredOtherInsurance ? null : (
        <Row>
          <Col>
            <Field
              type="number"
              label="What is your deductible amount?"
              name="deductibleAmount"
              min={0}
              value={values.deductibleAmount}
              onChange={handleChange}
              isError={touched.deductibleAmount && Boolean(errors.deductibleAmount)}
            />
          </Col>
        </Row>
      )}

      <Row>
        <Col>
          <Field
            name="claimNumber"
            type="text"
            label="Claim Number"
            isDisabled={isDisabled}
            value={values.claimNumber}
            onChange={handleChange}
            isError={touched?.claimNumber && Boolean(errors?.claimNumber)}
          />
        </Col>
        <Col>
          <div className={styles.projectFormContainer}>
            <Text color="#000" size="s13" weight="wm">
              Has your claim already been approved for full roof replacement?
            </Text>
            <div className={styles.projectFormColumn}>
              <div className={styles.projectFormCheckbox}>
                <Checkbox
                  disabled={isDisabled}
                  text="Yes"
                  name="isInsuranceApprovedClaim"
                  checked={values.isInsuranceApprovedClaim === true}
                  isError={
                    touched?.isInsuranceApprovedClaim &&
                    Boolean(errors?.isInsuranceApprovedClaim)
                  }
                  onChange={() =>
                    handleChange({
                      target: { name: 'isInsuranceApprovedClaim', value: true },
                    })
                  }
                />
              </div>
              <div>
                <Checkbox
                  disabled={isDisabled}
                  text="No"
                  name="isInsuranceApprovedClaim"
                  checked={values.isInsuranceApprovedClaim === false}
                  isError={
                    touched?.isInsuranceApprovedClaim &&
                    Boolean(errors?.isInsuranceApprovedClaim)
                  }
                  onChange={() =>
                    handleChange({
                      target: { name: 'isInsuranceApprovedClaim', value: false },
                    })
                  }
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {!isDisabled && (
        <Col>
          {insuranceCompanySite ? (
            <Text color="#000" size="s14" weight="wm">
              Policy Number and Claim Number are required to generate a contract with
              Roofclaim.
              <br />
              If you have not filed a claim yet, please follow the{' '}
              <Link
                href={insuranceCompanySite}
                isUnderlined
                target="_blank"
                bold
                size="s13"
              >
                link here to do it online
              </Link>{' '}
              or call your Insurance Company. Once done, come back here to enter the
              required policy and claim information.
            </Text>
          ) : (
            <Text color="#000" size="s14" weight="wm">
              Policy Number and Claim Number are required to generate a contract with
              Roofclaim.
              <br />
              If you have not filed a claim yet, please call your Insurance Company to do
              so. Once done, come back here to enter the required policy and claim
              information.
            </Text>
          )}
        </Col>
      )}
    </div>
  );
}

InsuranceCompanyForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  isLoadingOptions: PropTypes.bool,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object,
  touched: PropTypes.object,
  options: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  handleChangeInsuranceCompany: PropTypes.func.isRequired,
};

InsuranceCompanyForm.defaultProps = {
  errors: {},
  touched: {},
  isLoadingOptions: false,
};

export default InsuranceCompanyForm;
