import PropTypes from 'prop-types';
import React from 'react';
import styles from './style.module.css';

const Popup = ({ children, header }) => {
  return (
    <div className={styles.formAuth}>
      {header ? (
        <header className={styles.formAuthHeader}>{header}</header>
      ) : (
        <div className={styles.formAuthHeaderLine} />
      )}
      <div className={styles.formAuthBody}>{children}</div>
    </div>
  );
};

export default Popup;

Popup.propTypes = {
  children: PropTypes.node,
  header: PropTypes.node,
};

Popup.defaultProps = {
  children: null,
  header: null,
};
