import { roofProjectStages } from 'enums/roofProjectStatus';

export default (roofProjectList = []) => {
  let isHasOpenedProject = false;
  let index = 0;
  while (index < roofProjectList.length) {
    if (!roofProjectStages[roofProjectList[index].stageName]?.isClosed) {
      isHasOpenedProject = true;
      break;
    }
    index++;
  }
  return isHasOpenedProject;
};
