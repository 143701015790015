import React from 'react';
import PropTypes from 'prop-types';

import RepairPlanningForm from '../RepairPlanningForm';

const RepairPlanningStep = ({ children, caseStatus }) => {
  return (
    <>
      <RepairPlanningForm caseStatus={caseStatus}>{children}</RepairPlanningForm>
    </>
  );
};

export default RepairPlanningStep;

RepairPlanningStep.propTypes = {
  caseStatus: PropTypes.object,
  children: PropTypes.node,
};

RepairPlanningStep.defaultProps = {
  caseStatus: null,
  children: null,
};
