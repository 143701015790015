import { string, object } from 'yup';
import { emailRegex } from '../emailRegex';

export default object().shape({
  fullName: string().required(),
  email: string()
    .matches(emailRegex)
    .max(80, 'email should be a maximum of 80 characters long')
    .required('email is required')
    .trim(),

  phoneNumber: string()
    .required()
    .trim()
    .matches(/^\d{10,10}$/, {
      message: 'Please enter valid phone number',
      excludeEmptyString: true,
    }),
  subject: string().required(),
  message: string().required(),
});
