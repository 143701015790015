import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useHistory } from 'react-router-dom';
import Icon from 'components/shared/IconSVG';
import { menuIcon, nofitIcon, accountIcon, closeIcon } from 'assets/icons';
import TabletContext from 'context/TabletContext';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import Text from 'components/shared/Text';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { GET_USER_NOTIFICATIONS } from 'graphql/queries/notifications';
import { getSocket } from 'utils/socket';
import { READ_NOTIFICATIONS } from 'graphql/mutations/notifications';
import useVisibleComponent from 'services/hooks/useComponentVisible';
import styles from './styles.module.css';
import NotificationsModal from '../modals/NotificationsModal';
import AccountMenuModal from '../modals/AccountMenuModal';
import notificationStatuses from '../../../enums/notificationStatuses';
import logoRoof from 'assets/logos/RoofClaim-Transparent-logo.webp';

const Header = ({ setShowSidebar, showSidebar }) => {
  const isTablet = useContext(TabletContext);
  const [hasNewNotifications, setHasNewNotifications] = useState(false);
  const {
    ref: notificationRef,
    isComponentVisible: notificationPopupVisible,
    handleClick: setNotificationPopupVisible,
  } = useVisibleComponent(false);

  const {
    ref: accountIconRef,
    isComponentVisible: accountMenuPopupVisible,
    handleClick: setAccountMenuPopupVisible,
  } = useVisibleComponent(false);

  const history = useHistory();

  const { data, client } = useQuery(GET_USER_NOTIFICATIONS);
  const [mutate] = useMutation(READ_NOTIFICATIONS);

  const unreadNotifications = data?.notifications?.reduce((acc, curr) => {
    if (curr.status === notificationStatuses.notRead) acc.push(curr.id);
    return acc;
  }, []);

  useEffect(() => {
    const socketConnection = getSocket();
    socketConnection.on('notification', (incomingNotifications) => {
      setHasNewNotifications(true);
      const { notifications: existedNotifications } = client.readQuery({
        query: GET_USER_NOTIFICATIONS,
      });
      const updatedNotifications = uniqBy(
        orderBy(
          [...existedNotifications, incomingNotifications],
          ['createdAt', 'status'],
          ['desc', 'asc']
        ),
        'id'
      );
      client.writeQuery({
        query: GET_USER_NOTIFICATIONS,
        data: { notifications: updatedNotifications },
      });
    });
  }, []);

  useEffect(() => {
    const handleReadNotifications = async () => {
      if (unreadNotifications?.length) {
        await mutate({
          variables: { notificationsIds: unreadNotifications },
          update: (proxy, { data: { readNotifications: notifications } }) => {
            proxy.writeQuery({
              query: GET_USER_NOTIFICATIONS,
              data: { notifications },
            });
          },
        });
      }
    };

    if (notificationPopupVisible) handleReadNotifications();
  }, [notificationPopupVisible, data]);

  useEffect(() => {
    if (unreadNotifications?.length) setHasNewNotifications(true);
  }, [data]);

  return (
    <div className={styles.container}>
      <div className={styles.menuContainer}>
        {isTablet && !showSidebar && (
          <Icon
            src={menuIcon}
            height="20px"
            width="20px"
            className={styles.menuIcon}
            handleClick={() => {
              setShowSidebar(!showSidebar);
            }}
          />
        )}
        {isTablet && showSidebar && (
          <Icon
            src={closeIcon}
            height="22px"
            width="22px"
            className={styles.closeIcon}
            handleClick={() => {
              setShowSidebar(!showSidebar);
            }}
          />
        )}
        <NavLink exact to="/">
          <Icon src={logoRoof} height="40px" className={styles.logo} />
          {/* <Text className={styles.siteName}>RoofClaim</Text> */}
        </NavLink>
      </div>

      <div className={styles.iconContainer}>
        <div ref={notificationRef}>
          <div className={styles.iconWrapper}>
            <Icon
              src={nofitIcon}
              height="20px"
              width="20px"
              className={styles.notificationIcon}
              handleClick={
                // !showSidebar
                //   ?
                () => {
                  setNotificationPopupVisible();
                  setHasNewNotifications(false);
                  setShowSidebar(false);
                }
                // : () => {}
              }
            />
            {hasNewNotifications ? <div className={styles.newNotificationBadge} /> : null}
          </div>

          <NotificationsModal
            notifications={data?.notifications}
            isShow={notificationPopupVisible}
          />
        </div>

        <div ref={accountIconRef}>
          <Icon
            src={accountIcon}
            height="20px"
            width="20px"
            className={
              history.location.pathname === '/my-account'
                ? styles.accountIconActive
                : styles.accountIcon
            }
            handleClick={
              // !showSidebar
              // ?
              () => {
                setAccountMenuPopupVisible();
                setShowSidebar(false);
              }
              // : () => {}
            }
          />
          <AccountMenuModal
            isShow={accountMenuPopupVisible}
            handleClose={setAccountMenuPopupVisible}
          />
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  setShowSidebar: PropTypes.func.isRequired,
  showSidebar: PropTypes.bool.isRequired,
};

export default Header;
