export default {
  menu: (styles, state) => ({
    ...styles,
    backgroundColor: `white`,
    borderRadius: '2px',
    boxShadow: 'none',
    border: '1px solid #c5c5c7',
  }),
  control: (styles, state) => ({
    ...styles,
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    overflow: 'auto',
    maxHeight: 400,
    borderRadius: '2px',
    color: state.isDisabled ? 'rgb(138, 145, 152)' : styles.color,
    backgroundColor: state.isDisabled ? '#F0F0F0' : 'unset',
    border: state.isFocused ? 0 : 0,
    boxShadow: state.isFocused ? 0 : 0,
    '&:hover': {
      border: state.isFocused ? 0 : 0,
    },
  }),
  option: (styles, { isSelected }) => {
    return {
      ...styles,
      fontFamily: "'Futura Book'",
      fontSize: '14px',
      lineHeight: '18px',
      cursor: 'pointer',
      color: isSelected ? `#ffffff` : '#000000',
      backgroundColor: isSelected ? `#ff4e00` : '#fff',
      ':hover': {
        ...styles[':hover'],
        fontWeight: '600',
        backgroundColor: isSelected ? `#ff4e00` : '#efefef',
      },
    };
  },
  singleValue: (styles, state) => ({
    ...styles,
    width: '100%',
    color: state.isDisabled ? 'rgb(138, 145, 152)' : '#000',
    margin: '0px',
    paddingLeft: '0 5px',
    fontFamily: "Futura Book', sans-serif",
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '19px',
  }),
  input: (styles) => ({
    ...styles,
    margin: '0',
    padding: '0',
    paddingLeft: '0',
    input: {
      opacity: '1 !important',
    },
    color: `#000000`,
    fontSize: '14px',
    fontFamily: "Futura Book', sans-serif",
    lineHeight: '19px',
  }),

  placeholder: (styles) => ({
    ...styles,
    color: `#bfbebe`,
    fontFamily: "Futura Book', sans-serif",
    lineHeight: '19px',
    fontSize: '16px',
  }),
  noOptionsMessage: (styles) => ({
    ...styles,
    fontSize: '14px',
    fontFamily: "Futura Book', sans-serif",
    lineHeight: '19px',
    color: '#bfbebe',
  }),
  menuList: (styles) => ({
    ...styles,
    paddingBottom: 0,
    paddingTop: 0,
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: '2px 10px',
  }),
};
