import React from 'react';
import PropTypes from 'prop-types';
import Text from 'components/shared/Text';
import { formatDate } from 'utils/dateFormatters';
import styles from '../../../style.module.css';

function PaidContent({ balance, signedDate }) {
  return (
    <div>
      <div className={styles.textWrapper}>
        <Text color="#686868" size="s14" weight="wr">
          Deductible amount
        </Text>
        <div className={styles.rowText}>
          <Text color="#000000" size="s14" weight="wr">
            $ {Intl.NumberFormat().format(balance)}
          </Text>
        </div>
      </div>

      {signedDate && (
        <div className={styles.textWrapper}>
          <Text color="#686868" size="s16" weight="wr">
            Payment date
          </Text>
          <div className={styles.rowText}>
            <Text color="#000000" size="s14" weight="wb">
              {formatDate(signedDate)}
            </Text>
          </div>
        </div>
      )}
    </div>
  );
}

PaidContent.propTypes = {
  balance: PropTypes.number,
  signedDate: PropTypes.string,
};

PaidContent.defaultProps = {
  balance: 0,
  signedDate: '',
};

export default PaidContent;
