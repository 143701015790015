import React from 'react';
import SignUpForm from 'containers/SignUpContainer';
import styles from './style.module.css';
import MobileSignedOutHeader from '../../layouts/Mobile/MobileSignedOutHeader';

const SignUpPage = () => {
  return (
    <MobileSignedOutHeader>
      <div className={styles.contentPage}>
        <SignUpForm />
      </div>
    </MobileSignedOutHeader>
  );
};

SignUpPage.propTypes = {};

export default SignUpPage;
