import React from 'react';
import PropTypes from 'prop-types';
import Text from 'components/shared/Text';
import Icon from 'components/shared/IconSVG';
import { droneIcon, roofIcon, shapeRightIcon, rightIcon } from 'assets/icons';
import ProjectTypes from 'enums/projectTypes';
import getStageRoofProject from 'utils/stageRoofProject';
import styles from './styles.module.css';
import { formatDate } from '../../../utils/dateFormatters';

const MyProject = ({ projects, history }) => {
  const handleOpenProject = (projectId, typeProject) => {
    history.push(`/my-projects/${ProjectTypes[typeProject]}s/${projectId}`);
  };
  return (
    <>
      <div className={styles.itemWrapper}>
        {projects?.map(
          ({
            dateOfInstall,
            status,
            __typename,
            scheduledDate,
            pgId,
            createdDate,
            idCounter,
            sfId,
            ...data
          }) => {
            const isDrone = __typename === 'DroneReport';
            const stage = !isDrone
              ? getStageRoofProject({ dateOfInstall, ...data })
              : null;
            const isDisable = stage?.isDisableOpen;
            return (
              <div
                className={[
                  styles.selectedPackageContainer,
                  isDisable ? '' : styles.selectedPackageContainerAvailable,
                ].join(' ')}
                key={pgId}
                onClick={
                  isDisable ? () => {} : () => handleOpenProject(pgId || sfId, __typename)
                }
              >
                <div className={styles.iconContainer}>
                  <div className={styles.shapePart} />
                  <Icon
                    src={shapeRightIcon}
                    height="98px"
                    width="78px"
                    className={styles.shapeIcon}
                  />
                  <Icon
                    src={isDrone ? droneIcon : roofIcon}
                    height="40px"
                    width="40px"
                    className={styles.droneIcon}
                  />
                </div>
                <div className={styles.descriptionContainer}>
                  <div>
                    <Text color="#000000" weight="wb" size="s14" lineHeight="h16">
                      {isDrone ? 'Drone Report' : `Roof Project`}
                    </Text>{' '}
                    {idCounter && !isDrone && (
                      <Text color="#000000" weight="wb" size="s13" lineHeight="h16">
                        (#{idCounter})
                      </Text>
                    )}
                  </div>
                  <Text
                    color="#767676"
                    weight="wr"
                    size="s14"
                    tag="p"
                    lineHeight="h14"
                    className={styles.packageType}
                  >
                    {isDrone
                      ? `Date of inspection: ${
                          scheduledDate ? formatDate(scheduledDate) : 'Not scheduled yet'
                        }`
                      : `Date of install: ${
                          dateOfInstall ? formatDate(dateOfInstall) : 'Not scheduled yet'
                        }`}
                  </Text>

                  <div className={styles.statusTypeContainer}>
                    <div className={styles.statusContainer}>
                      <div
                        className={[styles.statusCircle, styles[`status${status}`]].join(
                          ' '
                        )}
                      />
                      <Text
                        color="#000000"
                        weight="wb"
                        size="s13"
                        tag="h2"
                        lineHeight="h16"
                      >
                        {status}
                      </Text>
                    </div>

                    <Text
                      color="#000000"
                      weight="wr"
                      size="s14"
                      tag="h2"
                      lineHeight="h16"
                    >
                      {!stage?.stageName ? '' : ` - ${stage.stageName}`}
                    </Text>
                  </div>
                </div>
                <Icon
                  src={rightIcon}
                  height="18px"
                  width="18px"
                  className={styles.arrayIcon}
                />
              </div>
            );
          }
        )}
      </div>
    </>
  );
};

MyProject.propTypes = {
  projects: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
};

export default MyProject;
