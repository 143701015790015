import React from 'react';
import PropTypes from 'prop-types';
import EmptyProjectContent from 'components/Projects/EmptyContent';
import LayoutWithSidebar from 'layouts/LayoutWithSidebar';
import withLoadProjects from 'services/HOCs/withLoadProjects';
import useModal from 'services/hooks/useIsShowComponent';
import Modal from 'components/shared/modals/StartRoofProjectModal';
import ReactGa from 'react-ga';
import ProjectsContent from './ProjectContent';


const MyPropjetsPage = ({ history, data = { roofProjects: {}, droneReports: {} } }) => {
  const { isShow, handleClick: handleClickModal } = useModal();

  const handleAddProject = () => {
    ReactGa.event({
      category: "Start a New Project",
      action: "Pressed the Start a roof project button",
      label: "Start a roof project"
    });
    history.push('/my-projects/roof-project');
  };

  const handleAddDroneReport = () => {
    ReactGa.event({
      category: "Start a New Project",
      action: "Pressed the Order a drone inspection option",
      label: "Order a drone inspection"
    });
    history.push('/my-projects/order-drone');
  };

  const handleOpenProject = (projectId, typeProject) => {
    history.push(`/my-projects/${typeProject}s/${projectId}`);
  };

  const countProjects =
    (data?.roofProjects?.length || 0) + (data?.droneReports?.length || 0);

  return (
    <LayoutWithSidebar>
      {countProjects === 0 ? (
        <>
          <EmptyProjectContent
            handleAddProject={handleClickModal}
            handleAddDroneReport={handleAddDroneReport}
          />
        </>
      ) : (
        <ProjectsContent
          roofProjects={data.roofProjects}
          droneReports={data.droneReports}
          handleAddProject={handleClickModal}
          handleAddDroneReport={handleAddDroneReport}
          handleOpenProject={handleOpenProject}
        />
      )}
      <Modal
        handleClick={() => {
          handleClickModal();
          handleAddProject();
        }}
        handleClose={handleClickModal}
        isShow={isShow}
      />
    </LayoutWithSidebar>
  );
};

MyPropjetsPage.propTypes = {
  history: PropTypes.object.isRequired,
  data: PropTypes.object,
};

MyPropjetsPage.defaultProps = {
  data: null,
};

export default withLoadProjects(MyPropjetsPage);
