import { string, object, boolean } from 'yup';
import { emailRegex } from './emailRegex';

const signInValidationSchema = object().shape({
  email: string().required().matches(emailRegex).trim(),
  password: string().required().trim().min(6),
  isKeepLogged: boolean().default(false),
});

export default signInValidationSchema;
