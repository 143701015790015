import React from 'react';
import PropTypes from 'prop-types';
import EmptyPageLayout from 'layouts/EmptyPage';
import droneIcon from 'assets/icons/Drone.svg';
import roofIcon from 'assets/icons/roof.svg';
import BlockTile from 'components/shared/BlockTile';
import Text from 'components/shared/Text';
import styles from './style.module.css';

const EmptyProjectContent = ({ handleAddProject, handleAddDroneReport }) => {
  return (
    <EmptyPageLayout title="My Projects">
      <div className={styles.pageHeader}>
        <Text color="#000" size="s16">
          You have no projects yet
        </Text>
      </div>
      <div className={styles.pageBody}>
        <div className={styles.pageBodyBlock}>
          <BlockTile
            text="We leverage our years of experience communicating with insurance companies to make your process easier."
            buttonText="Start a New Project"
            handleClick={handleAddProject}
            icon={roofIcon}
          />
        </div>
        <BlockTile
          text="Order a drone report for a quick turnaround and a thorough assessment."
          buttonText="Order a Drone Report"
          handleClick={handleAddDroneReport}
          icon={droneIcon}
        />
      </div>
    </EmptyPageLayout>
  );
};

EmptyProjectContent.propTypes = {
  handleAddProject: PropTypes.func.isRequired,
  handleAddDroneReport: PropTypes.func.isRequired,
};

export default EmptyProjectContent;
