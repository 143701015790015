import { Projects } from 'graphql/fragments';

const roofProject = (launch, { id: projectId }, { cache, getCacheKey }) => {
  const id = getCacheKey({ __typename: 'Opportunity', pgId: projectId });
  const fragment = Projects.fragments.FullRoofProject;
  const project = cache.readFragment({ fragment, id });
  return project;
};

export default { resolvers: {}, Query: { roofProject } };
