import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import MobileContext from 'context/MobileContext';
import WrapperBlock from 'components/shared/WrapperBlock';
import EmailLink from 'components/shared/Link/EmailLink';
import PhoneLink from 'components/shared/Link/PhoneLink';
import phoneNumberFormatter from 'utils/phoneNumberFormatter';
import { formatDate } from 'utils/dateFormatters';
import isCustomContractor from 'utils/checkIsCustomContractor';
import styles from '../style.module.css';
import Row from '../RowText';

function ProjectDetails({
  address,
  gateCode,
  idCounter,
  contacts = [],
  insuranceCompany,
  dateOfInstall,
  policyNumber,
  claimNumber,
  contractor,
}) {
  const isMobile = useContext(MobileContext);
  return (
    <div className={styles.projectTabWrapper}>
      <div className={styles.projectFormBanner}>
        <WrapperBlock title="Information" isForm>
          <div className={styles.projectFormContent}>
            <Row name="Project" value={`#${idCounter}`} isValueBold={isMobile} />
            <Row name="Address" value={address} isValueBold={isMobile} />
            <Row name="Gate Code" value={gateCode} isValueBold={isMobile} />
          </div>

          {contacts.map(({ firstName, lastName, phone, mobilePhone }, contactIndex) => (
            <div key={contactIndex} className={styles.projectFormContent}>
              <Row
                value={`${firstName} ${lastName}`}
                name={`Contact ${contactIndex + 1}`}
                isValueBold={isMobile}
              />
              <Row
                value={phoneNumberFormatter(phone || mobilePhone)}
                isValueBold={isMobile}
              />
            </div>
          ))}

          <div className={styles.projectFormContent}>
            <Row
              name="Date Of Install"
              value={formatDate(dateOfInstall)}
              isValueBold={isMobile}
            />
          </div>

          <div className={styles.projectFormContent}>
            <Row
              name="Insurance Company"
              value={insuranceCompany}
              isValueBold={isMobile}
            />
            <Row name="Policy Number" value={policyNumber} isValueBold={isMobile} />
            <Row name="Claim Number" value={claimNumber} isValueBold={isMobile} />
          </div>

          {isCustomContractor(contractor) && (
            <div className={styles.projectFormContent}>
              <Row
                name="Assigned Contractor"
                value={`${contractor?.firstName} ${contractor?.lastName}`}
                isValueBold={isMobile}
              />
              <Row
                name="Contractor Contact"
                value={
                  <div className={styles.contractSection}>
                    <div
                      className={[
                        styles.contractSectionItem,
                        styles.contractSectionItemFirst,
                      ].join(' ')}
                    >
                      <EmailLink mailTo={contractor?.email} isBold={isMobile} />
                    </div>
                    <div
                      className={[
                        styles.contractSectionItem,
                        styles.contractSectionItemLast,
                      ].join(' ')}
                    >
                      <PhoneLink
                        phoneNumber={phoneNumberFormatter(contractor?.phone)}
                        isBold={isMobile}
                      />
                    </div>
                  </div>
                }
              />
            </div>
          )}
        </WrapperBlock>
      </div>
    </div>
  );
}

ProjectDetails.propTypes = {
  address: PropTypes.string,
  gateCode: PropTypes.string,
  contacts: PropTypes.array,
  insuranceCompany: PropTypes.string,
  policyNumber: PropTypes.string,
  claimNumber: PropTypes.string,
  dateOfInstall: PropTypes.string,
  idCounter: PropTypes.string,
  contractor: PropTypes.object,
};

ProjectDetails.defaultProps = {
  address: '',
  gateCode: '',
  contacts: [],
  insuranceCompany: '',
  policyNumber: '',
  claimNumber: '',
  idCounter: '',
  dateOfInstall: '',
  contractor: {
    email: '',
    phoneNumber: '',
    name: '',
  },
};

export default ProjectDetails;
