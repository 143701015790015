import React from 'react';
import PropTypes from 'prop-types';
import Text from 'components/shared/Text';
import UploadFileArea from 'containers/RoofProject/UploadInsuranceDocumentsContainer';
import styles from '../../../style.module.css';

function InsuranceDocumentationSection({
  maxCountDocument = 1,
  isError,
  files,
  handleChange,
  projectId,
}) {
  return (
    <div>
      <div className={styles.projectForm}>
        <Text color="#292929" size="s14">
          Please upload your Statement of Loss or Determination Letter:
        </Text>
      </div>
      <UploadFileArea
        maxCountDocument={maxCountDocument}
        isInvalid={isError}
        uploadFiles={files}
        parentId={projectId}
        handleChange={handleChange}
        buttonText="Upload file(s)"
        accept=".jpeg, .png, .jpg, .pdf"
        name="insuranceAttachments"
        type="lossSheet"
      />
    </div>
  );
}

InsuranceDocumentationSection.propTypes = {
  maxCountDocument: PropTypes.number,
  isError: PropTypes.bool,
  files: PropTypes.array,
  handleChange: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
};

InsuranceDocumentationSection.defaultProps = {
  maxCountDocument: 1,
  isError: false,
  files: [],
};

export default InsuranceDocumentationSection;
