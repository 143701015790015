import { string, object, boolean, number } from 'yup';

export default object().shape({
  insuranceCompanyId: string().required(),
  policyNumber: string().required().trim(),
  claimNumber: string().required().nullable(false),
  isInsuranceApprovedClaim: boolean().required().nullable(false),
  isIncludeMortgageCompany: boolean().required(),
  mortgageCompanyId: string()
    .nullable(true)
    .when('isIncludeMortgageCompany', {
      is: true,
      then: string().required().nullable(false),
    }),
  loanNumber: string()
    .nullable(true)
    .when('isIncludeMortgageCompany', {
      is: false,
      then: string().nullable(true).equals([null]),
    }),

  isGateCodeRequired: boolean().required(),
  gateCode: string()
    .nullable(true)
    .when('isGateCodeRequired', {
      is: true,
      then: string().required().nullable(false),
    }),
  existingShingleType: string().nullable(true),
  isRoofTarped: boolean().required(),

  isRequiredOtherInsurance: boolean().required(),
  otherInsurance: string()
    .nullable(true)
    .when('isRequiredOtherInsurance', {
      is: true,
      then: string().required().nullable(false),
    }),
  deductibleAmount: number().nullable(true).min(0),
});
