import React from 'react';
import PropTypes from 'prop-types';
import Text from 'components/shared/Text';
import roofTypes from 'enums/roofTypes';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { defaultIconProps } from 'components/shared/IconSVG';
import styles from './style.module.css';

const RowText = ({ name, children }) => {
  return (
    <div className={styles.rowTextContainer}>
      <div className={styles.rowTitle}>
        <Text color="#686868" size="s14">
          {name}:
        </Text>
      </div>
      <div className={styles.rowText}>
        <Text color="#000000" size="s14">
          {children}
        </Text>
      </div>
    </div>
  );
};

RowText.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.any,
};

RowText.defaultProps = {
  children: '',
};

function ProductSection({ values = {}, handleModify, isDisabled }) {
  return (
    <div className={[styles.container, styles.containerInfo].join(' ')}>
      <div>
        <RowText name="Product type" value>
          {roofTypes[values?.productType]}
        </RowText>
        <RowText name="Replacement product">{values?.stoneMetalPanelType}</RowText>
      </div>

      {!isDisabled && (
        <div className={styles.buttonContainer} onClick={handleModify}>
          <EditIcon
            style={{ width: '12px', height: '18px' }}
            className={defaultIconProps.className}
          />
          <div className={styles.buttonText}>
            <Text color="#000" size="s14" weight="wb">
              Modify
            </Text>
          </div>
        </div>
      )}
    </div>
  );
}

ProductSection.propTypes = {
  handleModify: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool,
};

ProductSection.defaultProps = {
  isDisabled: false,
};

export default ProductSection;
