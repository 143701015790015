import React from 'react';
import PropTypes from 'prop-types';
import LayoutWithSidebar from 'layouts/LayoutWithSidebar';
import withLoadProjects from 'services/HOCs/withLoadProjects';
import useModal from 'services/hooks/useIsShowComponent';
import Modal from 'components/shared/modals/StartRoofProjectModal';
import ReferralsContent from './ReferralsContent';

const MyReferralsPage = ({ history, data = { roofProjects: {} } }) => {
    const { isShow, handleClick: handleClickModal } = useModal();

    const handleAddProject = () => {
    };

    const handleOpenProject = (referralId, typeProject) => {
    };

    const countProjects = (data?.roofProjects?.length || 0);

    return (
        <LayoutWithSidebar>
            <ReferralsContent
                roofProjects={data.roofProjects}
                handleAddProject={handleClickModal}
                handleOpenProject={handleOpenProject}
            />
            <Modal
                handleClick={() => {
                    handleClickModal();
                    handleAddProject();
                }}
                handleClose={handleClickModal}
                isShow={isShow}
            />
        </LayoutWithSidebar>
    );
};

MyReferralsPage.propTypes = {
    history: PropTypes.object.isRequired,
    data: PropTypes.object,
};

MyReferralsPage.defaultProps = {
    data: null,
};

export default withLoadProjects(MyReferralsPage);
