import { roofProjectStages as stages } from 'enums/roofProjectStatus';
import moment from 'moment';

const getCurrentStage = (
  {
    stageName,
    contractSignedDate,
    materialDeliveryDate,
    dateOfInstall,
    installationStatus,
    adjusterStatus
  },
  isNewProject
) => {
  const currentStage = stages[stageName?.replace(' ', '')];
  const currentStageIndex = !isNewProject ? currentStage?.tabIndex : 0;

  if (currentStage?.isDraft) return stages.Draft;

  if (currentStage?.isClosed) return currentStage;

  if (!currentStage?.isDraft && !contractSignedDate) return stages.Measurements;

  if (currentStageIndex === stages.Measurements.tabIndex && !!contractSignedDate)
    return stages.Planning;

  const daysDiff = moment(dateOfInstall).diff(moment(), 'days');
  if (daysDiff > 0 && !installationStatus) return stages.InstallPrep;

  if (adjusterStatus === 'Denied - Disputed') return stages.Planning;

  return currentStage;
};

export default getCurrentStage;
