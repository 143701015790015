import React from 'react';
import PropTypes from 'prop-types';
import PageLayout from 'layouts/EmptyPage';
import { useMutation } from '@apollo/react-hooks';
import { removeRoofProject } from 'graphql/cache/project';
import { REMOVE_DRAFTED_ROOF_PROJECT } from 'graphql/mutations/projects';
import Tabs from 'pages/MyReferralsTabs/Tabs';
import Container from 'containers/CreateNewReferel/CreateNewReferelContainer';

const ReferralsContent = ({
    roofProjects = [],
    handleOpenProject,
}) => {
    const [removeDraftedRoofProject, { loading }] = useMutation(
        REMOVE_DRAFTED_ROOF_PROJECT
    );

    const handleDeleteRoofProject = (projectId) => {
        removeDraftedRoofProject({
            variables: { projectId },
            update: () => {
                removeRoofProject(projectId);
            },
        });
    };

    const RoofProjects = [
        {
            pgId: "pgId1",
            nameOfReferred: "Referral-1",
            stage: {
                tabIndex: 5,
                stageName: "Completed",
                status: "Completed",
                isDisableOpen: true,
            },
            address: "Los Angelos",
            createdDate: "2021-02-09T00:04:22.000Z",
            status: "Cancelled",
        },
        {
            pgId: "pgId2",
            nameOfReferred: "Referral-2",
            stage: {
                tabIndex: 4,
                stageName: "onGoing",
                status: "onGoing",
                isDisableOpen: true,
            },
            address: "Los Angelos",
            createdDate: "2021-02-09T00:04:22.000Z",
            status: "onGoing",
        },
    ];

    return (
        <PageLayout
            title="My Referrals"
            rightComponent={<Container triggerText="Create New Referrals" />}
            topMobileRightComponent
        >
            <Tabs
                roofProjects={RoofProjects}
                handleOpenProject={handleOpenProject}
                handleDelete={handleDeleteRoofProject}
                loading={loading}
            />
        </PageLayout>
    );
};

ReferralsContent.propTypes = {
    roofProjects: PropTypes.array,
    droneReports: PropTypes.array,
    handleAddProject: PropTypes.func.isRequired,
    handleAddDroneReport: PropTypes.func.isRequired,
    handleOpenProject: PropTypes.func.isRequired,
};


ReferralsContent.defaultProps = {
    droneReports: [],
    roofProjects: [],
};

export default ReferralsContent;
