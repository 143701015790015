import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.css';

/*
Weight:
   wb – Bold
   wr - Regular
   wm – Medium

Size:
   s12 – 12px
   s13 – 13px
   s15 - 15px
   s14 - 14px
   s16 – 16px
   s17 – 17px
   s18 - 18px
   s19 – 19px
   s20 - 20px
   s21 – 21px
   s22 – 22px
   s23 – 23px
   s24 – 24px
   s25 – 25px
   s26 – 26px
   s27 – 27px
   s28 - 28px
   s29 – 29px
   s30 – 30px
   s31 – 31px
   s32 – 32px

Line Height:
   h10 – 10px
   h14 - 14px
   h16 – 16px
   h18 – 18px
   h20 – 20px
   h21 – 21px
   h22 – 22px
   h25 – 25px
   h26 – 26px
   h27 – 27px
   h28 – 28px
   h29 – 29px
   h30 – 30px
   h35 – 35px
   h36 – 36px
   h37 – 37px
 */

const Text = ({ children, className, weight, size, lineHeight, tag, color, ...props }) => {
  const classNames = `${styles.default} ${styles[weight] || ''} ${styles[size] || ''} ${
    styles[lineHeight] || ''
  } ${className || ''}`;

  switch (tag) {
    case 'h2':
      return (
        <h2 style={{ color }} className={classNames} {...props}>
          {children}
        </h2>
      );
    case 'p':
      return (
        <p style={{ color }} className={classNames} {...props}>
          {children}
        </p>
      );
    default:
      return (
        <span style={{ color }} className={classNames} {...props}>
          {children}
        </span>
      );
  }
};

Text.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  weight: PropTypes.string,
  size: PropTypes.string,
  lineHeight: PropTypes.string,
  tag: PropTypes.string,
  color: PropTypes.string,
};

Text.defaultProps = {
  children: '',
  weight: '',
  size: '',
  lineHeight: '',
  tag: '',
  className: '',
  color: '#FFFFFF',
};

export default Text;
