import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Note from 'components/shared/NoteText';
import AppConfigContext from 'context/AppConfigContext';

function ProjectPlanningInformation() {
  const config = useContext(AppConfigContext);

  return (
    <Note isNoteDescription smallSize projectPlaningInfo>
      {config.planningSubmitBodyText}
    </Note>
  );
}

ProjectPlanningInformation.propTypes = {};

ProjectPlanningInformation.defaultProps = {};

export default ProjectPlanningInformation;
