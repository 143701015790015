import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import WrapperBlock from 'components/shared/WrapperBlock';
import Text from 'components/shared/Text';
import Attachment from 'containers/DownloadAttachmentContainer';
import NoteText from 'components/shared/NoteText';
import { Footer as FooterButtonDesck } from 'components/Projects/RoofProjects/Footer';
import styles from './styles.module.css';
import { formatDate } from '../../../utils/dateFormatters';

function DroneReportStep({ droneReport, files, isCompleted, handleGoPreviousStep }) {
  const history = useHistory();
  return (
    <>
      <div className={styles.wrapper}>
        <WrapperBlock
          title="Drone Report"
          rightComponent={
            isCompleted && (
              <>
                {files?.map((file) => (
                  <Attachment {...file} key={file.contentDocumentId} />
                ))}
              </>
            )
          }
          className={styles.droneReportRightBlock}
        >
          <div
            className={[
              isCompleted ? styles.infoContainer : styles.infoWrapper,
              styles.droneReportContent,
            ].join(' ')}
          >
            <div className={styles.titleContainer}>
              <Text color="#686868" weight="wr" size="s14" className={styles.dateInfo}>
                Drone Inspection Date
              </Text>
              <Text color="#686868" weight="wr" size="s14" className={styles.statusTitle}>
                Order Status
              </Text>
            </div>
            <div className={styles.descriptionContainer}>
              <Text
                color="#000"
                weight="wb"
                size="s12"
                lineHeight="h12"
                tag="p"
                className={styles.dateInfo}
              >
                {formatDate(droneReport.scheduledDate) ||
                  'Has not yet been set or removed'}
              </Text>
              <div className={styles.statusContainer}>
                <div
                  className={
                    droneReport.status === 'Schedule'
                      ? styles.statusSchedule
                      : isCompleted
                      ? styles.statusCompleted
                      : styles.statusNew
                  }
                />

                <Text
                  color="#000"
                  weight="wb"
                  size="s12"
                  className={isCompleted ? styles.statusTextFinished : styles.statusText}
                >
                  {droneReport.status}
                </Text>
              </div>
            </div>
          </div>

          <div className={styles.droneReportNote}>
            <NoteText isNoteDescription>
              {isCompleted
                ? `Your drone report is now available to download and has been sent to your email address. In case of damage to your roof, you can start a new repair project.`
                : 'Thank you! Your order has been completed. You will receive the report via email, or you can access it through the app within 72 hours. In the event of inclement weather, there may be a delay. You will not need to be home, although if you have scheduled a drone inspection, it will need to be rescheduled to a later date'}
            </NoteText>
          </div>
        </WrapperBlock>
      </div>
      <FooterButtonDesck
        handleCancel={handleGoPreviousStep}
        // submitButtonText="Start a New Project"
        handleSubmit={() => history.push('/my-projects/roof-project')}
        isHideSubmit={!isCompleted}
        isHideNext
      />
    </>
  );
}

DroneReportStep.propTypes = {
  droneReport: PropTypes.object.isRequired,
  handleGoPreviousStep: PropTypes.func.isRequired,
  handleGoNextStep: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired,
  isCompleted: PropTypes.bool,
  isAvailableNext: PropTypes.bool,
  isHideNext: PropTypes.bool,
};

DroneReportStep.defaultProps = {
  isCompleted: false,
  isAvailableNext: false,
  isHideNext: false,
};

export default DroneReportStep;
