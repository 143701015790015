import React from 'react';
import PropTypes from 'prop-types';
import DroneIcon from 'assets/icons/Drone.svg';
import Text from 'components/shared/Text';
import Icon from 'components/shared/IconSVG';
import styles from './style.module.css';
import LinkPDF from '../LinkPDF';

const ProjectCard = ({
  status,
  scheduledDate,
  handleClick = () => {},
  downloadLink,
  downloadFileName,
}) => {
  const iconSize = '40px';
  return (
    <div
      className={[styles.projectCardWrapper, styles.projectCardWrapperAvailable].join(
        ' '
      )}
      onClick={handleClick}
    >
      <div className={styles.projectCardBody}>
        <div className={styles.projectCardHeader}>
          <div className={styles.leftPart}>
            <Icon
              src={DroneIcon}
              height={iconSize}
              width={iconSize}
              className={styles.projectCardIcon}
            />
          </div>
          <div className={styles.projectCardLeftContent}>
            <div className={styles.projectCardTitle}>
              <Text color="#000" weight="wb" size="s16">
                Drone Report
              </Text>
            </div>
            <div className={styles.projectCardText}>
              <Text color="#767676" weight="wm" size="s14" tag="p" lineHeight="h20">
                Drone inspection date: {scheduledDate || 'Not scheduled yet'}
              </Text>
            </div>
            <div className={[styles.status].join(' ')}>
              <span
                className={[
                  styles.indicator,
                  styles[`${status?.toLowerCase()}Indicator`],
                ].join(' ')}
              />
              <Text color="#000" weight="wm" size="s14" tag="p">
                {status}
              </Text>
            </div>
          </div>
        </div>
        {downloadLink && (
          <div className={styles.projectCardFooter}>
            <LinkPDF isDownload href={downloadLink} fileName={downloadFileName}>
              Drone Report
            </LinkPDF>
          </div>
        )}
      </div>
    </div>
  );
};

ProjectCard.propTypes = {
  status: PropTypes.string.isRequired,
  scheduledDate: PropTypes.string,
  handleClick: PropTypes.func,
  downloadLink: PropTypes.string,
  downloadFileName: PropTypes.string,
};

ProjectCard.defaultProps = {
  handleClick: () => {},
  downloadLink: '',
  downloadFileName: '',
  scheduledDate: '',
};

export default ProjectCard;
