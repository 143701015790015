import gql from 'graphql-tag';

export const Drone = {
  fragments: {
    FullDrone: gql`
      fragment FullDrone on DroneReport {
        pgId
        status
        price
        dronePackage
        scheduledDate
        sfId
        paid
      }
    `,
  },
};
