import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import MobileContext from 'context/MobileContext';
import { useQuery } from '@apollo/react-hooks';
import { GET_ROOF_COMPANIES } from 'graphql/queries/companies';
import Spinner from 'components/shared/Spinner';
import WithError from 'services/HOCs/withError';
import InsuranceCompany from './InsuranceCompany';
import MortgageCompany from './MortgageCompany';
import Contacts from './Contacts';

import styles from './styles.module.css';

function AccountDetails({ user, forWarrantyPage }) {
  const { loading, data, error } = useQuery(GET_ROOF_COMPANIES);
  const isMobile = useContext(MobileContext);
  const isEmpty = data?.getRoofCompanies
    ? !Object.values(data.getRoofCompanies).find((el) => el)
    : true;

  return (
    <div className={styles.pageWrapper}>
      {!loading ? (
        <WithError isShow={!!error}>
          <Contacts user={user} />

          <div className={isEmpty && !isMobile ? styles.emptyBlock : null}>
            {!forWarrantyPage && data?.getRoofCompanies ? (
              <InsuranceCompany
                insuranceCompanyData={data?.getRoofCompanies}
                isEmpty={isEmpty}
              />
            ) : null}
            {!forWarrantyPage && data?.getRoofCompanies ? (
              <MortgageCompany
                mortgageCompanyData={data?.getRoofCompanies}
                isEmpty={isEmpty && !isMobile}
              />
            ) : null}
          </div>
        </WithError>
      ) : (
        <Spinner />
      )}
    </div>
  );
}

AccountDetails.propTypes = {
  forWarrantyPage: PropTypes.bool,
  user: PropTypes.object,
};

AccountDetails.defaultProps = {
  forWarrantyPage: false,
  user: null,
};

export default AccountDetails;
