import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import Spinner from 'components/shared/Spinner';
import LayoutWithSidebar from 'layouts/LayoutWithSidebar';
import { GET_WARRANTIES } from '../../graphql/queries/warranty';

import WithError from './withError';

export default (WrappedComponent) => {
  return (props) => {
    const { loading, data, error } = useQuery(GET_WARRANTIES);

    if (loading)
      return (
        <LayoutWithSidebar loading={loading}>
          <Spinner />
        </LayoutWithSidebar>
      );

    return (
      <WithError isShow={!!error}>
        <WrappedComponent {...props} data={data} />
      </WithError>
    );
  };
};
