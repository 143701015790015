import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/shared/Button';
import Text from 'components/shared/Text';
import TextArea from 'components/shared/TextArea';
import MobileContext from 'context/MobileContext';
import styles from './style.module.css';
import RatingScale from './RatingScale';

const ReviewForm = ({ handleClick }) => {
  const defaultRating = '';

  const isMobile = useContext(MobileContext);
  const [reviewText, setReviewText] = useState('');
  const [reviewRating, setReviewRating] = useState(defaultRating);

  return (
    <div className={styles.modalBody}>
      <div>
        <div className={styles.modalContentDescription}>
          <Text
            size={isMobile ? 's16' : 's19'}
            color="#000000"
            weight="wb"
            tag="p"
            lineHeight="h29"
          >
            Are you sastified with the job done?
          </Text>

          <div className={styles.modalContentScale}>
            <RatingScale handleChange={setReviewRating} defaultValue={defaultRating} />
          </div>
        </div>

        {isMobile && (
          <Text color="#000" size="s16" lineHeight="h29">
            Leave a comment (optional):
          </Text>
        )}
        <div className={styles.modalReviewForm}>
          <TextArea
            placeholder={isMobile ? '' : 'Leave a comment (optional):'}
            handleChange={({ target: { value } }) => setReviewText(value)}
            value={reviewText}
          />
        </div>
      </div>
      <div
        className={[styles.modalFooterButton, styles.modalFooterButtonWrapper].join(' ')}
      >
        <Button
          size="medium"
          className={styles.modalSubmitButton}
          disabled={!reviewRating}
          handleClick={() => handleClick({ text: reviewText, rating: reviewRating })}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

ReviewForm.propTypes = {
  handleClick: PropTypes.func.isRequired,
};

export default ReviewForm;
