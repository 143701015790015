import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import styles from './style.module.css';

const StatusTabsComponent = ({
  tabList,
  currentTabIndex,
  relative,
  activeTabIndex,
  handleSelectTab = () => {},
  isClickable,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentTabIndex]);

  const activeIndex = activeTabIndex || currentTabIndex;

  return (
    <Tabs
      className={styles.tabsPlanning}
      selectedIndex={currentTabIndex}
      onSelect={(index) => (index <= activeIndex ? handleSelectTab(index) : () => {})}
    >
      <TabList className={styles.tabListPlanning}>
        {tabList.map(({ title: tabTitle, isClosed }, index) => {
          if (!tabTitle) return null;

          return (
            <Tab
              key={`${index}tab`}
              className={[
                styles.tabPlanning,
                index <= activeIndex
                  ? styles.finishedTabPlanning
                  : styles.defaultTabPlaning,
                index <= activeIndex && isClickable ? styles.tabPlanningClickable : '',
              ].join(' ')}
              selectedClassName={!isClosed ? styles.selectedTabPlanning : ''}
              disabledClassName={styles.disabledTabPlaning}
              disabled={index > activeIndex}
            >
              {tabTitle}
            </Tab>
          );
        })}
      </TabList>

      {tabList.map(({ children }, index) => (
        <TabPanel
          key={`${index}tabPanel`}
          className={styles.tabPanelPlanning}
          selectedClassName={[
            styles.selectedTabPanelPlanning,
            relative ? styles.selectedTabPanelPlanningRelative : '',
          ].join(' ')}
        >
          {children}
        </TabPanel>
      ))}
    </Tabs>
  );
};

StatusTabsComponent.propTypes = {
  tabList: PropTypes.array.isRequired,
  currentTabIndex: PropTypes.number.isRequired,
  activeTabIndex: PropTypes.number,
  relative: PropTypes.bool,
  handleSelectTab: PropTypes.func,
  isClickable: PropTypes.bool,
};

StatusTabsComponent.defaultProps = {
  activeTabIndex: null,
  relative: false,
  handleSelectTab: () => {},
  isClickable: false,
};

export default StatusTabsComponent;
