import React from 'react';
import PropTypes from 'prop-types';
import phoneNumberFormatWithCode from 'utils/phoneNumberFormatter';
import RowText from '../../../RowText';
import styles from './style.module.css';

function ContactForm({ values = {}, isMobile }) {
  return (
    <div className={styles.contactInfoWrapper}>
      <div className={styles.contactInfoRow}>
        <RowText
          value={[values.firstName, values.lastName].join(' ')}
          name="Full Name"
          isValueBold={isMobile}
        />
      </div>
      <div className={styles.contactInfoRow}>
        <RowText value={values.email} name="Email Address" isValueBold={isMobile} />
      </div>
      <div className={styles.contactInfoRow}>
        <RowText
          value={values.alternativeEmail}
          name="Alternative Email Address"
          isValueBold={isMobile}
        />
      </div>
      <div className={styles.contactInfoRow}>
        <RowText
          value={phoneNumberFormatWithCode(values.phone)}
          name="Phone Number"
          isValueBold={isMobile}
        />
      </div>
      <div className={styles.contactInfoRow}>
        <RowText
          value={phoneNumberFormatWithCode(values.mobilePhone)}
          name="Mobile Number"
          isValueBold={isMobile}
        />
      </div>
    </div>
  );
}

ContactForm.propTypes = {
  values: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
};

ContactForm.defaultProps = {
  isMobile: false,
};

export default ContactForm;
