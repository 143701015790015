import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import MobileContext from 'context/MobileContext';
import WrapperBlock from 'components/shared/WrapperBlock';
import Text from 'components/shared/Text';
import { createSecureNumber } from 'utils/createSecureNumber';
import styles from './styles.module.css';

function InsuranceCompany({ insuranceCompanyData, isEmpty }) {
  const isMobile = useContext(MobileContext);

  const detailTitle = [
    'Insurance Company Name',
    'Insurance Company Email',
    'Policy Number',
  ];
  const insuranceCompanyName =
    insuranceCompanyData?.otherInsurance || insuranceCompanyData?.insuranceCompanyName;

  const description = [
    insuranceCompanyName || '',
    insuranceCompanyData?.insuranceCompanyEmail || '',
    createSecureNumber(insuranceCompanyData?.policyNumber) || '',
  ];

  return (
    <div className={`${styles.wrapper} ${isEmpty && styles.isEmpty}`}>
      <WrapperBlock title="Insurance Company">
        {detailTitle.map((title, index) => (
          <div className={styles.detailTitleContainer} key={description[index]}>
            <Text
              size="s14"
              weight="wr"
              lineHeight="h13"
              color="#686868"
              className={styles.detailTitle}
            >
              {title}
            </Text>
            <Text
              size={isMobile ? 's13' : 's14'}
              weight={isMobile ? 'wb' : 'wr'}
              lineHeight="h13"
              color="000000"
              className={styles.description}
            >
              {description[index]}
            </Text>
          </div>
        ))}
      </WrapperBlock>
    </div>
  );
}

InsuranceCompany.propTypes = {
  insuranceCompanyData: PropTypes.object.isRequired,
  isEmpty: PropTypes.bool,
};

InsuranceCompany.defaultProps = {
  isEmpty: false,
};

export default InsuranceCompany;
