import moment from 'moment';

export const formatDate = (dateString) => {
  if (!dateString) return '';
  const parsed = moment(dateString);
  if (parsed.isValid()) return parsed.format('MM/DD/YYYY');
  return '';
};

export const formatDateTime = (dateString) => {
  if (!dateString) return '';
  const parsed = moment(dateString);
  if (parsed.isValid()) return parsed.format('MM/DD/YYYY hh:mm');
  return '';
};
