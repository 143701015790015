import client from 'graphql/client';
import { MY_PROJECTS, GET_ROOF_PROJECTS_LIST } from 'graphql/queries/projects';
import { DOWNLOAD_FILES_DESCRIPTION } from 'graphql/queries/file';

export const getRoofProjects = () => {
  const { roofProjects } = client.cache.readQuery({
    query: GET_ROOF_PROJECTS_LIST,
  });
  return roofProjects;
};

const updateProject = (projectId, roofProject) => {
  const { roofProjects } = client.cache.readQuery({
    query: MY_PROJECTS,
  });
  const currentProject = roofProjects.find(({ pgId }) => pgId === projectId);
  const updatedProjectList = roofProjects.map((project) =>
    project.pgId === projectId ? { ...currentProject, ...roofProject } : project
  );
  client.cache.writeQuery({
    query: MY_PROJECTS,
    data: {
      roofProjects: updatedProjectList,
    },
  });
};

export const updateRoofProjectList = ({ data, projectId }) => {
  if (projectId) return updateProject(projectId, data);
  const { roofProjects } = client.cache.readQuery({ query: MY_PROJECTS });

  return client.cache.writeQuery({
    query: MY_PROJECTS,
    data: { roofProjects: [...roofProjects, data] },
  });
};

export const removeRoofProject = (projectId) => {
  const { roofProjects } = client.cache.readQuery({ query: MY_PROJECTS });
  const newRoofProjectList = roofProjects.filter(({ pgId }) => pgId !== projectId);

  return client.cache.writeQuery({
    query: MY_PROJECTS,
    data: { roofProjects: newRoofProjectList },
  });
};

export const updateFieldRoofProject = ({ projectId, fieldName, fieldValue }) => {
  updateProject(projectId, {
    [fieldName]: fieldValue,
  });
};

export const updateFilesRoofProject = ({ projectId, files }) => {
  const { downloadFilesDescription } = client.cache.readQuery({
    query: DOWNLOAD_FILES_DESCRIPTION,
    variables: {
      opportunitySfId: projectId,
    },
  });
  const newFiles = [files, downloadFilesDescription].flat();

  client.cache.writeQuery({
    query: DOWNLOAD_FILES_DESCRIPTION,
    variables: {
      opportunitySfId: projectId,
    },
    data: {
      downloadFilesDescription: newFiles,
    },
  });
};

export const deleteFileRoofProject = ({ parentId, fileId }) => {
  const { downloadFilesDescription } = client.cache.readQuery({
    query: DOWNLOAD_FILES_DESCRIPTION,
    variables: {
      opportunitySfId: parentId,
    },
  });
  const newFiles = downloadFilesDescription.filter(
    ({ contentDocumentId }) => contentDocumentId !== fileId
  );

  client.cache.writeQuery({
    query: DOWNLOAD_FILES_DESCRIPTION,
    variables: {
      opportunitySfId: parentId,
    },
    data: {
      downloadFilesDescription: newFiles,
    },
  });
};
