import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import MobileContext from 'context/MobileContext';
import Text from 'components/shared/Text';
import styles from './style.module.css';

const Row = ({ name, value, isValueBold }) => {
  const isMobile = useContext(MobileContext);

  if (!value) return null;
  return (
    <div className={styles.rowContainer}>
      <div className={styles.rowTitle}>
        <Text color={isMobile ? '#000000' : '#686868'} size="s14" lineHeight="h20">
          {name}
        </Text>
      </div>

      <div className={styles.rowText}>
        {typeof value === 'string' ? (
          <Text
            color="#000000"
            size={isValueBold ? 's13' : 's14'}
            lineHeight="h20"
            weight={isValueBold ? 'wb' : 'wr'}
          >
            {value}
          </Text>
        ) : (
          value
        )}
      </div>
    </div>
  );
};

Row.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isValueBold: PropTypes.bool,
};

Row.defaultProps = {
  value: '',
  name: '',
  isValueBold: false,
};

export default Row;
