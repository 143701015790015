import React from 'react';
import PropTypes from 'prop-types';
import Text from 'components/shared/Text';
import styles from './style.module.css';

const FirstStageModal = ({ handleConfirm, handleReject, isConfirm }) => {
  return (
    <>
      <div>
        <div className={styles.modalContentDescription}>
          <Text size="s16" color="#000000" weight="wm" lineHeight="h21">
            The contractor marked the installation status as "completed". Please confirm
            that the installation has been finished.
          </Text>
        </div>
        <div className={styles.modalButtons}>
          <button
            onClick={handleConfirm}
            type="button"
            id="btn-confirm-install-finished"
            className={[
              styles.modalButton,
              isConfirm === true ? styles.modalButtonActive : styles.modalButtonPrimary,
            ].join(' ')}
          >
            Yes
          </button>
          <button
            type="button"
            onClick={handleReject}
            className={[
              styles.modalButton,
              isConfirm === false ? styles.modalButtonActive : styles.modalButtonPrimary,
            ].join(' ')}
          >
            No
          </button>
        </div>
      </div>
    </>
  );
};

FirstStageModal.propTypes = {
  handleConfirm: PropTypes.func.isRequired,
  isConfirm: PropTypes.bool.isRequired,
  handleReject: PropTypes.func.isRequired,
};

FirstStageModal.defaultProps = {};

export default FirstStageModal;
