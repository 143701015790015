import React from 'react';
import PropTypes from 'prop-types';
import DroneReport from 'components/DroneReport';
import LayoutWithSidebar from 'layouts/LayoutWithSidebar';

const DroneReportPage = ({ droneReport, ...props }) => {
  return (
    <LayoutWithSidebar>
      <DroneReport droneReport={droneReport} {...props} />
    </LayoutWithSidebar>
  );
};

DroneReportPage.propTypes = {
  droneReport: PropTypes.object,
};

DroneReportPage.defaultProps = {
  droneReport: {},
};
export default DroneReportPage;
